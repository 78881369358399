import styled from "styled-components";
import { device } from "../../util/device";

export const ResultsLargeCard = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	width: 95%;
	margin-left: 2.5%;
	margin-bottom: 20px;
	min-height: 1000px;

	h2 {
		text-align: center;
		margin-top: 10px;
		font-size: 40px;
		font-weight: bold;
	}
`;

export const DataGraphs = styled.div`
	background-color: #eeeeee;
	padding-top: 15px;
	border-radius: 10px;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;

		div {
			display: flow;
		}

		h2 {
			display: flow;
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
		flex-wrap: wrap;

		div {
			display: block;
		}

		h2 {
			display: none;
			font-size: 20px;
		}
	}
`;

export const GradeCircle = styled.div`
	background: var(--croi-green);
	border-radius: 50%;
	color: white;
	width: 130px;
	height: 130px;
	text-align: center;
	padding-top: 5%;
	font-size: 80px;
	margin-left: auto;
	margin-right: auto;
`;

export const ResultsTableStyle = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 15px;
	}

	@media only screen and ${device.tablet} {
		width: 100%;
		padding: 25px;
		border-collapse: separate;
		border-spacing: 0 15px;
	}
`;

export const DataEntry = styled.div`
	@media only screen and ${device.mobileM} {
		background-color: #eeeeee;
		border-radius: 10px;
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		justify-content: space-between;
	}

	@media only screen and ${device.tablet} {
		background-color: #eeeeee;
		border-radius: 10px;
		padding-top: 10px;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;
		justify-content: space-between;
	}
`;

export const DataScore = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;

		h3 {
			font-weight: bolder;
			text-align: left;
			font-size: 35px;
			margin-left: 20px;
		}

		h5 {
			text-align: center;
			font-weight: bold;
			margin-top: 10px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 12%;

		h3 {
			font-weight: bolder;
			text-align: center;
			font-size: 35px;
			margin-left: 0;
		}

		h5 {
			text-align: center;
			font-weight: bold;
			margin-top: 10px;
		}
	}
`;

export const DataLargeInfo = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;

		h3 {
			color: var(--croi-green);
			font-weight: bold;
			text-align: center;
		}
	}

	@media only screen and ${device.tablet} {
		width: 60%;

		h3 {
			color: var(--croi-green);
			font-weight: bold;
			text-align: left;
		}
	}
`;

export const DataRemaining = styled.div`
	@media only screen and ${device.mobileM} {
		height: 95%;
		width: 100%;
		border-left: 3px solid lightgrey;
		padding-left: 8%;

		li {
			list-style: none;
			padding-left: 15px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 95%;
		width: 25%;
		border-left: 3px solid lightgrey;
		padding-left: 0;
		margin-top: 16px;

		li {
			list-style: none;
			padding-left: 15px;
		}
	}
`;

export const ResultCircle = styled.div`
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	color: white;
	font-weight: bold;

	@media only screen and ${device.mobileM} {
		margin-top: -20px;
	}
	@media only screen and ${device.tablet} {
		margin-top: 0;
	}
`;

export const LeftSide = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
	}
`;

export const LeftSideLeft = styled.div`
	@media only screen and ${device.mobileM} {
		padding-left: 15px;
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		padding-left: 0;
		width: 40%;
	}
`;

export const LeftSideRight = styled.div`
	width: 100%;

	@media only screen and ${device.mobileM} {
		p {
			padding-left: 15px;
			display: flex;
			flex-direction: column;
		}

		a {
			display: flex;
			flex-direction: row;
			padding-left: 15px;
		}

		b {
			height: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		p {
			padding-left: 0;
			display: flex;
			flex-direction: row;
		}

		a {
			display: flex;
			flex-direction: row;
			padding-left: 0;
		}
	}
`;
