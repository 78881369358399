import React, { useState } from "react";
import {
	AllFilters,
	CheckboxBox,
	FilterBox,
	FilterCheckboxes,
	FilterInputs,
	FilterSection,
	MatchMeButton,
	ScholarshipEditButton,
	ScholarshipHeader,
	ScholarshipMessage,
	ScholarshipTable
} from "./ScholarshipMatch.styles";
import { Scholarship } from "../../types/Scholarship.types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../services/QueryClient.services";
import { fetchMatchData, fetchScholarshipList, updateScholarship } from "../../services/Scholarship.service";
import { auth } from "../../firebase";
import { StateList } from "../../util/stateList";
import { ScholarshipFosList } from "../../util/scholarshipFosList";
import { Snack } from "../../atoms/SnackBarAlert";
import { currencyFormatter } from "../../util/currencyFormatter";
import CroiCheckbox from "../../atoms/CroiCheckbox";
import SettingsWindow from "../../components/SettingsWindow/SettingsWindow";
import { User } from "../../types/User.types";
import MobileScholarshipTab from "../../components/MobileScholarshipTab/MobileScholarshipTab";

export default function ScholarshipMatch(props: { scholarships: Scholarship[]; user: User }) {
	const [matchList, setMatchList] = useState<Scholarship[]>([]);
	const [savedList, setSavedList] = useState<string[]>(props.scholarships.map((e) => e.id));
	const [disability, setDisability] = useState("");
	const [ethnicity, setEthnicity] = useState("");
	const [religion, setReligion] = useState("");
	const [gender, setGender] = useState("");
	const [state, setState] = useState("");
	const [military, setMilitary] = useState(false);
	const [essay, setEssay] = useState(false);
	const [collegeRestrict, setRestrict] = useState(false);
	const [fos, setFOS] = useState("");
	const [snackMsg, setMsg] = useState("");
	const [upgradeWindow, setWindow] = useState(false);
	const [mobileCollapse, setCollapse] = useState(true);

	const getScholarships = useQuery<Scholarship[], Error>({
		queryKey: ["scholarships"],
		queryFn: () => fetchScholarshipList(),
		refetchInterval: false
	});

	const addScholarships = useMutation({
		mutationKey: ["scholarships"],
		mutationFn: (body: any) => updateScholarship(body),
		onSuccess: () => {
			return queryClient.invalidateQueries(["scholarships"]);
		}
	});

	const matchGetter = useMutation({
		mutationKey: ["Match"],
		mutationFn: (body: any) => fetchMatchData(body),
		onSuccess: (data) => {
			setMsg(`Successfully Fetched ${data.length} Scholarships!`);
		}
	});

	const matcher = async () => {
		if (props.user.paymentType === "Free") {
			setWindow(true);
			return;
		}

		setMsg("Fetching Scholarships...");

		const body = {
			state: state.length > 0 ? state : undefined,
			gender: gender.length > 0 ? gender : undefined,
			ethnicity: ethnicity.length > 0 ? ethnicity : undefined,
			religion: religion.length > 0 ? religion : undefined,
			disability: disability.length > 0 ? disability : undefined,
			military: military,
			fos: fos.length > 0 ? fos : undefined,
			collegeRestrict: collegeRestrict,
			essay: essay
		};

		const data = await matchGetter.mutateAsync(body);

		setMatchList(data);
	};

	const disabilityCheck = (disabilities: boolean[]) => {
		let isDisability = false;
		if (disabilities[0]) {
			isDisability = true;
		}
		if (disabilities[1]) {
			isDisability = true;
		}
		if (disabilities[2]) {
			isDisability = true;
		}
		if (disabilities[3]) {
			isDisability = true;
		}

		return isDisability;
	};

	const disabilityReturn = (disabilities: boolean[]) => {
		const disabilitiesReturned: string[] = [];
		if (disabilities[0]) {
			disabilitiesReturned.push("Hearing");
		}
		if (disabilities[1]) {
			disabilitiesReturned.push("Learning");
		}
		if (disabilities[2]) {
			disabilitiesReturned.push("Physical");
		}
		if (disabilities[3]) {
			disabilitiesReturned.push("Visual");
		}

		return disabilitiesReturned;
	};

	const removeScholarship = (scholarshipID: string) => {
		setSavedList(savedList.filter((id) => id !== scholarshipID));
		addScholarships.mutate({ uid: auth.currentUser?.uid, list: savedList });
	};

	const saveScholarship = (scholarshipID: string) => {
		if (!savedList.includes(scholarshipID)) {
			savedList.push(scholarshipID);
			addScholarships.mutate({ uid: auth.currentUser?.uid, list: savedList });
		}
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			{upgradeWindow ? (
				<SettingsWindow type={"UPGRADE"} setWindow={setWindow} user={props.user} setMsg={setMsg} />
			) : (
				<></>
			)}
			<ScholarshipHeader onClick={() => setCollapse(!mobileCollapse)}>
				<h4>
					Filters {window.outerWidth > 512 ? <></> : <span>{!mobileCollapse ? <>&#9650;</> : <>&#9660;</>}</span>}
				</h4>
			</ScholarshipHeader>
			{mobileCollapse && window.outerWidth <= 512 ? (
				<></>
			) : (
				<>
					<ScholarshipMessage>
						Use these filters to narrow down scholarship options.
						<br />
						Selecting a filter will display only scholarships that match those criteria. To view more options, try
						reducing the number of filters applied.
					</ScholarshipMessage>
					<FilterSection>
						<AllFilters>
							<FilterInputs>
								<FilterBox>
									<h6>State of Residence</h6>
									<select defaultValue={""} onChange={(e) => setState(e.target.value)}>
										<option value={""}>N/A</option>
										{StateList.map((state) => (
											<option key={state.state} value={state.abbreviation}>
												{state.state}
											</option>
										))}
									</select>
								</FilterBox>
								<FilterBox>
									<h6>Field of Study</h6>
									<select defaultValue={""} onChange={(e) => setFOS(e.target.value)}>
										<option value={""}>Undecided</option>
										{ScholarshipFosList.map((fos) => (
											<option key={fos}>{fos}</option>
										))}
									</select>
								</FilterBox>
								<FilterBox>
									<h6>Religious Affiliation</h6>
									<select defaultValue={""} onChange={(e) => setReligion(e.target.value)}>
										<option value={""}>N/A</option>
										<option>Baha'i faith</option>
										<option>Baptist</option>
										<option>Christian</option>
										<option>Eastern Orthodox</option>
										<option>Episcopalian</option>
										<option>Jewish</option>
										<option>Lutheran</option>
										<option>Methodist</option>
										<option>Muslim faith</option>
										<option>Presbyterian</option>
										<option>Protestant</option>
										<option>Roman Catholic</option>
										<option>Unitarian Universalist</option>
									</select>
								</FilterBox>
								<FilterBox>
									<h6>Gender</h6>
									<select defaultValue={""} onChange={(e) => setGender(e.target.value)}>
										<option value={""}>N/A</option>
										<option>Male</option>
										<option>Female</option>
										<option>Other</option>
									</select>
								</FilterBox>
								<FilterBox>
									<h6>Ethnicity</h6>
									<select defaultValue={""} onChange={(e) => setEthnicity(e.target.value)}>
										<option value={""}>N/A</option>
										<option>Black</option>
										<option>Hispanic</option>
										<option>Asian</option>
										<option>Native American</option>
										<option>White</option>
									</select>
								</FilterBox>
								<FilterBox>
									<h6>Disability</h6>
									<select defaultValue={""} onChange={(e) => setDisability(e.target.value)}>
										<option value={""}>N/A</option>
										<option>Hearing</option>
										<option>Learning</option>
										<option>Physical</option>
										<option>Visual</option>
									</select>
								</FilterBox>
							</FilterInputs>
							<FilterCheckboxes>
								<CheckboxBox>
									<h6>Military Affiliation</h6>
									<CroiCheckbox setter={setMilitary} value={military} size={30} />
								</CheckboxBox>
								<CheckboxBox>
									<h6>Requires Essay</h6>
									<CroiCheckbox setter={setEssay} value={essay} size={30} />
								</CheckboxBox>
								<CheckboxBox>
									<h6>Specific to College List</h6>
									<CroiCheckbox setter={setRestrict} value={collegeRestrict} size={30} />
								</CheckboxBox>
							</FilterCheckboxes>
						</AllFilters>

						<MatchMeButton onClick={matcher}>
							MATCH ME&nbsp;
							{props.user.paymentType === "Free" ? (
								<img
									src={"/images/CroiPremium.svg"}
									alt={"Croi Premium Logo"}
									style={{
										width: "14%"
									}}
								/>
							) : (
								<></>
							)}
						</MatchMeButton>
					</FilterSection>
				</>
			)}

			<ScholarshipHeader>
				<h4>Saved Scholarships</h4>
				<i>
					This section displays all your saved scholarships, enabling quick access for further review or application.
				</i>
			</ScholarshipHeader>
			<ScholarshipTable>
				<thead>
					<tr>
						<th style={{ width: "auto" }}>Scholarship</th>
						{window.outerWidth > 512 ? <th style={{ width: "auto" }}>Field of Study</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Gender</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Ethnicity</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Disability</th> : <></>}
						<th style={{ width: "100px" }}>Amount</th>
						{window.outerWidth > 512 ? <th style={{ width: "80px" }}>Link</th> : <></>}
						{window.outerWidth > 512 ? (
							<th style={{ width: "80px" }}>Remove?</th>
						) : (
							<th style={{ width: "80px" }}>Details</th>
						)}
					</tr>
				</thead>
				<tbody>
					{getScholarships.data && getScholarships.data.length > 0 ? (
						getScholarships.data.map((scholarship) =>
							window.outerWidth > 512 ? (
								<tr key={`Normal ${scholarship.id}`}>
									<td>{scholarship.name}</td>
									<td>{scholarship.fos.length > 0 ? scholarship.fos : "N/A"}</td>

									<td>{scholarship.gender}</td>

									<td>{scholarship.ethnicities.length > 0 ? scholarship.ethnicities : "N/A"}</td>

									<td>
										{disabilityCheck([
											scholarship.disabilityHearing,
											scholarship.disabilityLearning,
											scholarship.disabilityPhysical,
											scholarship.disabilityVisual
										])
											? disabilityReturn([
													scholarship.disabilityHearing,
													scholarship.disabilityLearning,
													scholarship.disabilityPhysical,
													scholarship.disabilityVisual
											  ])
											: "N/A"}
									</td>
									<td>{scholarship.amount ? currencyFormatter.format(scholarship.amount) : "Variable"}</td>
									<td>
										<ScholarshipEditButton onClick={() => window.open(scholarship.url)}>Link</ScholarshipEditButton>
									</td>
									<td>
										<ScholarshipEditButton onClick={() => removeScholarship(scholarship.id)}>
											Remove
										</ScholarshipEditButton>
									</td>
								</tr>
							) : (
								<MobileScholarshipTab
									key={scholarship.id + "saved"}
									scholarship={scholarship}
									saved={true}
									click={removeScholarship}
								/>
							)
						)
					) : (
						<tr>
							<td colSpan={window.outerWidth > 512 ? 8 : 3} className={"tableMessage"}>
								<i>No Scholarships Saved Yet!</i>
							</td>
						</tr>
					)}
				</tbody>
			</ScholarshipTable>

			<ScholarshipHeader>
				<h4>Match Results</h4>
				<i>
					Here you'll find scholarships that best match your criteria. Adjust your filters and select "MATCH ME" to
					personalize your results.
				</i>
			</ScholarshipHeader>
			<ScholarshipTable>
				<thead>
					<tr>
						<th style={{ width: "auto" }}>Scholarship</th>
						{window.outerWidth > 512 ? <th style={{ width: "auto" }}>Field of Study</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Gender</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Ethnicity</th> : <></>}
						{window.outerWidth > 512 ? <th style={{ width: "100px" }}>Disability</th> : <></>}
						<th style={{ width: "100px" }}>Amount</th>
						{window.outerWidth > 512 ? <th style={{ width: "80px" }}>Link</th> : <></>}
						{window.outerWidth > 512 ? (
							<th style={{ width: "80px" }}>Save?</th>
						) : (
							<th style={{ width: "80px" }}>Details</th>
						)}
					</tr>
				</thead>
				<tbody>
					{matchList.map((scholarship) =>
						window.outerWidth > 512 ? (
							<tr key={`Non-Mobile ${scholarship.id}`}>
								<td>{scholarship.name}</td>
								<td>{scholarship.fos.length > 0 ? scholarship.fos : "N/A"}</td>
								<td>{scholarship.gender}</td>
								<td>{scholarship.ethnicities.length > 0 ? scholarship.ethnicities : "N/A"}</td>
								<td>
									{disabilityCheck([
										scholarship.disabilityHearing,
										scholarship.disabilityLearning,
										scholarship.disabilityPhysical,
										scholarship.disabilityVisual
									])
										? disabilityReturn([
												scholarship.disabilityHearing,
												scholarship.disabilityLearning,
												scholarship.disabilityPhysical,
												scholarship.disabilityVisual
										  ])
										: "N/A"}
								</td>
								<td>{scholarship.amount ? currencyFormatter.format(scholarship.amount) : "Variable"}</td>
								<td>
									<ScholarshipEditButton onClick={() => window.open(scholarship.url)}>Link</ScholarshipEditButton>
								</td>
								<td>
									<ScholarshipEditButton onClick={() => saveScholarship(scholarship.id)}>Save</ScholarshipEditButton>
								</td>
							</tr>
						) : (
							<MobileScholarshipTab
								key={scholarship.id + "results"}
								scholarship={scholarship}
								saved={false}
								click={saveScholarship}
							/>
						)
					)}
				</tbody>
			</ScholarshipTable>
		</>
	);
}
