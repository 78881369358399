import styled from "styled-components";
import { device } from "../../util/device";

export const LoanChart = styled.table`
	@media only screen and ${device.mobileM} {
		border: gray solid 5px;
		width: 95%;
	}

	@media only screen and ${device.tablet} {
		border: gray solid 5px;
		width: 96%;
	}
`;

export const LoanRow = styled.tr`
	display: flex;
	justify-content: space-evenly;
	vertical-align: middle;

	th {
		background: lightgray;
		width: 10%;
		height: 50px;
		text-align: center;
		font-size: 15px;
		padding-top: 10px;
	}

	td {
		width: 10%;
		text-align: center;
		border-bottom: lightgray 2px solid;
		height: 40px;
		padding-top: 6px;
	}
`;

export const LoanChangeButton = styled.button`
	height: 25px;
	width: 25px;
	font-weight: bold;
`;

export const ResetButton = styled.button`
	border-radius: 20px;
	background: var(--croi-green);
	width: 100px;
	height: 40px;
`;

export const InfoBox = styled.div`
	background: var(--croi-green);
	color: white;
	font-size: 50px;
	text-align: center;
	width: 96%;
	margin-top: 2%;
	margin-left: auto;
	margin-right: auto;

	b {
		font-weight: 900;
	}
`;

export const LoanGraph = styled.div`
	display: block;
	margin-top: 30px;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		width: 100%;
		margin-left: 0;
	}

	@media only screen and ${device.tablet} {
		width: 70%;
		margin-left: auto;
	}
`;

export const ModalStyle = styled.div`
	/* Chrome, Safari, Edge, Opera */

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input {
		width: 350px;
		height: 40px;
		margin-top: 10px;
		border: 1px solid black;
		border-radius: 5px;
		padding-left: 10px;
	}

	div {
		display: flex;
	}

	p {
		width: 120px;
		padding-top: 15px;
		margin-left: 15px;
	}

	select {
		height: 40px;
		margin-top: 5px;
	}
`;

export const SLTOptions = styled.div`
	display: block;
	background: white;
	width: 300px;
	transform: translate(-85px, 20px);
	border: black 2px solid;
	position: relative;
	padding: 3px;
	z-index: 1;

	:hover {
		background: #3dd5f3;
	}
`;

export const SLTButton = styled.button`
	background: var(--croi-green);
	width: 120px;
	height: 40px;
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 10px;
	text-align: center;
	display: flow;

	:hover {
		background: lightgray;
	}
`;

export const ApplyButton = styled.button`
	border-radius: 15px;
	background: var(--croi-green);
	width: 100px;
	height: 40px;
	color: white;
`;

export const LenderImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	height: 125px;
	width: 150px;
	border-radius: 20%;
	margin: 20px auto 30px;
`;

interface ImageProps {
	imageUrl: string;
}

export const LenderSection = styled.div`
	justify-content: center;
	display: flex;
	margin-top: 20px;
	flex-direction: column;
	text-align: center;
	padding-top: 20px;

	hr {
		opacity: 100%;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		border-top: 10px solid var(--croi-green);
	}

	h2 {
		font-weight: bold;
		padding-top: 20px;
	}
`;

export const FederalSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const PrivateSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const FedLenderCard = styled.div`
	@media only screen and ${device.mobileM} {
		border-radius: 10px;
		background: lightgray;
		width: 225px;
		text-align: center;
		height: 290px;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 15px;
		margin-top: 20px;

		h4 {
			height: 50px;
		}
	}

	@media only screen and ${device.tablet} {
		border-radius: 10px;
		background: lightgray;
		width: 225px;
		text-align: center;
		height: 290px;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 15px;
		margin-top: 0;

		h4 {
			height: 50px;
		}
	}
`;

export const PrivLenderCard = styled.div`
	@media only screen and ${device.mobileM} {
		border-radius: 10px;
		background: lightgray;
		text-align: center;
		height: 290px;
		margin: 20px;
		width: 225px;
		padding-top: 15px;

		h4 {
			height: 50px;
		}
	}

	@media only screen and ${device.tablet} {
		border-radius: 10px;
		background: lightgray;
		text-align: center;
		height: 290px;
		margin: 20px;
		width: 225px;
		padding-top: 15px;

		h4 {
			height: 50px;
		}
	}
`;

export const MobileAddButton = styled.button`
	display: block;
	background: none;
	margin: 10px auto;
	text-decoration: underline;
	font-size: 20px;
`;
