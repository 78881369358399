import styled from "styled-components";
import { device } from "../../util/device";

export const TopLanding = styled.div`
	@media only screen and ${device.mobileM} {
		margin: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		margin: 30px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;

export const TopTextLanding = styled.div`
	p {
		margin-top: 30px;
		color: #2a3631;
	}

	ul {
		margin-top: 20px;
	}

	li {
		list-style-type: none;
		display: flex;
		padding-left: 0;
		margin-left: -25px;

		div {
			border-radius: 50%;
			background-color: var(--croi-green);
			text-align: center;
			color: white;
			margin-right: 5px;
		}
	}

	span {
		background-color: #f6f8a6;
	}

	h2 {
		font-weight: 900;
	}

	h5 {
		font-weight: 540;
	}

	button {
		color: white;
		background-color: var(--croi-green);
		border-radius: 10px;
		width: 95%;
		height: 50px;
		font-weight: bold;
		font-size: 20px;
		margin-bottom: 17px;
	}

	@media only screen and ${device.mobileM} {
		width: 85vw;
		margin-left: 0;
		text-align: center;

		li {
			font-size: 18px;

			div {
				margin-top: 0;
				width: 25px;
				height: 25px;
				line-height: 28px;
			}
		}
	}

	@media only screen and ${device.tablet} {
		width: 55vw;
		margin-left: 50px;
		text-align: left;
		li {
			font-size: 30px;

			div {
				width: 30px;
				height: 30px;
				margin-top: 5px;
				line-height: 32px;
			}
		}

		h2 {
			font-size: 65px;
		}

		h5 {
			font-size: 30px;
		}

		p {
			font-size: 25px;
		}
	}
`;

export const ScheduleMeetingButton = styled.button`
	border: solid var(--croi-green);
	background-color: white;
	border-radius: 10px;
	height: 40px;
	width: 220px;
	font-size: 15px;

	a {
		color: black;
		font-family: Aleo, serif;
	}
`;

export const VideoEmbed = styled.div`
	border: var(--croi-green) 8px solid;
	border-radius: 15px;
	margin-top: -12px;
	padding-top: 0;

	iframe {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	@media only screen and ${device.mobileM} {
		width: 90vw;
		height: 26.2em;
	}

	@media only screen and ${device.tablet} {
		margin-left: 50px;
		width: 45vw;
		height: 26.2em;
	}
`;

interface ImageProps {
	imageUrl: string;
}

export const AMSection = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		margin-bottom: 60px;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
`;

export const AMCards = styled.div`
	background: #e9fdf5;
	border-radius: 10px;
	text-align: center;
	color: black;

	@media only screen and ${device.mobileM} {
		width: 250px;
		height: 200px;
		padding-top: 15px;
		margin-top: 30px;
	}

	@media only screen and ${device.tablet} {
		width: 250px;
		height: 200px;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 15px;
	}
`;

export const AMImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: contain;
	transform: scale(0.65);
	height: 110px;
	width: 110px;
	margin-left: auto;
	margin-right: auto;
`;

export const FiveStepSection = styled.div`
	width: 80%;
	margin: 30px auto 20px;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
`;

export const FiveStepCard = styled.div`
	border-radius: 10px;
	background: #e9fdf5;
	width: 200px;
	text-align: center;
	height: 250px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 15px;
	color: black;

	@media only screen and ${device.mobileM} {
		margin-bottom: 30px;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: column;
	}
`;

export const ImageBackground = styled.div`
	background: var(--croi-green);
	height: 90px;
	width: 90px;
	margin: 40px auto 30px;
	border-radius: 10px;
`;

export const FiveStepNumber = styled.div`
	background-color: var(--croi-green);
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	text-align: center;
	font-size: 115%;
	margin-left: auto;
	margin-right: auto;
	font-weight: bold;

	@media only screen and ${device.mobileM} {
		padding-top: 3%;
	}

	@media only screen and ${device.tablet} {
		padding-top: 3%;
	}
`;

export const StatSection = styled.div`
	background: #e9fdf5;
	margin-bottom: 20px;
	margin-top: 10px;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		height: 300px;
	}
`;

export const StatCard = styled.div`
	background: #e9fdf5;
	margin-bottom: 20px;

	h1 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		text-align: center;
		margin-top: 20px;
		width: 80%;

		h1 {
			font-size: 40px;
		}

		p {
			margin-top: 5px;
			font-size: 15px;
		}
	}

	@media only screen and ${device.tablet} {
		justify-content: center;
		margin-top: 50px;
		text-align: center;
		width: 25%;

		h1 {
			font-size: 4vw;
		}

		p {
			margin-top: 40px;
			font-size: 20px;
		}
	}
`;

export const GraphSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 10px auto 20px;
`;

export const HeaderGraphSection = styled.div`
	display: flex;
	flex-direction: row;
`;

export const RowGraphSection = styled.div`
	display: flex;
	flex-direction: row;
`;

export const DescriptionSectionHeading = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 150px;
	padding: 15px;

	@media only screen and ${device.mobileM} {
		width: 30%;
		height: 120px;
	}

	@media only screen and ${device.tablet} {
		width: 20%;
		height: 150px;
	}
`;

export const CroiCheckSectionHeading = styled.div`
	background: #e9fdf5;
	border-left: var(--croi-green) solid 4px;
	border-right: var(--croi-green) solid 4px;
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;

	@media only screen and ${device.mobileM} {
		height: 120px;
		width: 30%;

		img {
			height: 70px;
			width: 90px;
			margin-top: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 150px;
		width: 40%;

		img {
			height: 100px;
			width: 120px;
			margin-top: 20px;
		}
	}
`;

export const WithoutCroiSectionHeading = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	@media only screen and ${device.mobileM} {
		height: 120px;

		img {
			height: 50px;
			width: 50px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 5px;
		}

		a {
			font-weight: 900;
			font-size: 15px;
			padding-top: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 150px;

		img {
			height: 60px;
			width: 60px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 5px;
		}

		a {
			font-weight: 900;
			padding-top: 5px;
			font-size: 25px;
		}
	}
`;

export const DescriptionSection = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		width: 30%;
		font-size: 10px;
	}

	@media only screen and ${device.tablet} {
		font-size: 20px;
		width: 20%;
	}
`;

export const CroiCheckSection = styled.div`
	background: #e9fdf5;
	border-left: var(--croi-green) solid 4px;
	border-right: var(--croi-green) solid 4px;
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		width: 30%;

		img {
			height: 30px;
			width: 30px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 40%;

		img {
			height: 40px;
			width: 40px;
		}
	}
`;

export const WithoutCroiSection = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	width: 40%;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		a {
			width: 80%;
			font-size: 10px;
		}

		img {
			height: 30px;
			width: 30px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		a {
			padding: 5px;
			width: 80%;
			font-size: 20px;
		}

		img {
			height: 40px;
			width: 40px;
		}
	}
`;

export const AccountBanner = styled.div`
	background-color: var(--croi-green);
	color: white;
	width: 100vw;
	display: flex;
	justify-content: center;
	padding-top: 1.5%;
	font-weight: bold;

	h1 {
		text-align: center;
	}

	button {
		color: var(--croi-green);
		background-color: white;
		border-radius: 10px;
		padding: 10px;
		height: 60px;
		font-weight: bold;
		font-size: 20px;
		margin-top: -5px;
		width: 154px;
	}

	@media only screen and ${device.mobileM} {
		flex-direction: column;
		height: 200px;

		button {
			margin-right: auto;
			margin-left: auto;
		}
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
		height: 100px;

		button {
			margin-left: 10px;
			margin-right: 0;
		}
	}
`;
