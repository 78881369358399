import styled from "styled-components";
import { device } from "../../util/device";

export const UserToolsMaster = styled.main`
	@media only screen and ${device.mobileM} {
		width: 100vw;
		margin-left: 0;
	}

	@media only screen and ${device.tablet} {
		width: 83vw;
		margin-left: 17vw;
	}
`;

export const SidebarContainer = styled.div`
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	font-family: Aleo, serif;
	background-color: #eeeeee;
	border-radius: 16px;
	box-shadow: 5px 5px 5px lightgrey;
	height: 100vh;
	z-index: 50;

	@media only screen and ${device.mobileM} {
		width: 75vw;
	}

	@media only screen and ${device.tablet} {
		width: 15vw;
	}
`;

export const Logo = styled.div`
	display: flex;
	padding: 5px;
	justify-content: center;
	flex-direction: row;
	text-decoration: none;
	color: var(--croi-green);
	font-weight: 700;
	font-size: 3rem;
	margin-top: 15px;
	cursor: pointer;

	img {
		width: 49px;
	}
`;

export const Option = styled.div`
	width: 98%;
	padding: 10px;
	margin-left: 2%;
	border-radius: 10px 0 0 10px;
	font-weight: 700;
	cursor: pointer;
	font-size: 1.2rem;

	img {
		width: 9%;
		margin-right: 10%;
	}

	b {
		background-color: var(--croi-green);
		color: white;
		padding: 5px;
		border-radius: 10px;
		margin-left: 47px;
		font-size: 15px;
	}

	&.selected {
		background-color: #3ad698;

		b {
			background-color: white;
			color: var(--croi-green);
		}
	}

	&.dropdown {
		background: #d0d0d0;
	}

	@media only screen and ${device.mobileM} {
		ul {
			list-style: none;
			width: 100%;
			padding: 0;
			margin-bottom: 0;
		}

		li {
			padding-top: 6%;
			padding-left: 6%;
			border-radius: 10px 0 0 10px;
			overflow-x: hidden;
			overflow-y: hidden;

			&.selected {
				background: var(--croi-green);
			}
		}
	}

	@media only screen and ${device.tablet} {
		ul {
			list-style: none;
			margin-left: 20%;
			width: 84%;
			padding: 0;
			margin-bottom: 0;
		}

		li {
			padding-top: 3%;
			padding-left: 10%;
			border-radius: 10px 0 0 10px;
			height: 40px;

			&.selected {
				background: var(--croi-green);
			}
		}
	}
`;

export const DropButton = styled.img`
	@media only screen and ${device.mobileM} {
		margin-left: 4%;
		width: 5px;
		height: 5px;
	}

	@media only screen and ${device.tablet} {
		margin-left: 20%;
		width: 10px;
		height: 10px;
	}
`;

export const Sponsorship = styled.a`
	border-top: 2px solid lightgrey;
	text-align: center;
	position: absolute;
	bottom: 0;
	color: #52121b;
	text-decoration: none;

	:hover {
		color: #52121b;
	}

	img {
		display: block;
		margin-right: auto;
		margin-left: auto;
		width: 75%;
	}
`;

export const FreeMessage = styled.div`
	position: absolute;
	bottom: 25px;
	font-weight: bold;
	font-size: 24px;
	width: 100%;
	border-radius: 0 0 10px 0;
	text-align: center;
	padding-top: 10px;

	button {
		background-color: var(--croi-green);
		color: white;
		font-weight: bold;
		padding: 5px;
		border-radius: 10px;
		font-size: 22px;
		width: 95%;
	}
`;

export const NavBarContainer = styled.div`
	margin-top: 15px;
	font-family: aleo, serif;

	h2 {
		font-weight: bold;
		font-size: 2rem;
	}

	@media only screen and ${device.mobileM} {
		width: 100vw;
		margin-left: 0;

		h2 {
			margin-left: 20%;
		}
	}

	@media only screen and ${device.tablet} {
		width: 83vw;
		margin-left: 17vw;

		h2 {
			margin-left: 0;
		}
	}
`;

export const ProgressBar = styled.div`
	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
		border-bottom: black solid 2px;
		padding-right: 10vw;
		height: 40px;

		.arrow {
			width: 30px;
			height: 30px;
			margin-top: 5px;
		}
	}
`;

export const ProfileButton = styled.button`
	background: transparent url("/images/ProfileIcon.png") center no-repeat;
	background-size: contain;
	margin-right: 2vw;
	border: 3px solid var(--croi-green);
	border-radius: 50%;

	@media only screen and ${device.mobileM} {
		margin-top: -12px;
		width: 45px;
		height: 45px;
	}

	@media only screen and ${device.tablet} {
		margin-top: 0;
		width: 75px;
		height: 75px;
	}
`;

export const CustomerService = styled.div`
	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: block;
	}

	margin-right: 20px;
	margin-top: 10px;
	text-align: center;

	b {
		text-decoration: underline;
	}

	a {
		text-decoration: none;
		color: black;
	}
`;

export const ProfileOptions = styled.div`
	display: block;
	background: white;
	width: 150px;
	padding: 5px;
	transform: translate(-100px, 50px);
	border: black 2px solid;
	position: relative;
	z-index: 1;

	:hover {
		background: #3dd5f3;
	}
`;

export const Hamburger = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 30px;
	height: 30px;
	z-index: 51;
	position: fixed;
	left: 5px;
	top: 5px;

	div {
		height: 4px;
		width: 100%;
		background-color: #333;
		border-radius: 5px;
		transition: all 0.3s ease;
	}
`;

export const ProgressButton = styled.button`
	background-color: white;
	color: black;
	font-weight: 900;
	font-size: 1.5em;
	width: 150px;
	display: flex;
	padding-top: 2px;

	p {
		margin-top: 1px;
		margin-left: 5px;
		margin-right: 5px;
		width: 33px;
		height: 33px;
		border-radius: 50%;
		color: white;
		background-color: var(--croi-green);
	}

	&.selected {
		background-color: var(--croi-green);
		border-radius: 15px 15px 0 0;
		color: white;

		p {
			background-color: white;
			color: var(--croi-green);
		}
	}
`;

export const MobileMessage = styled.div`
	position: absolute;
	width: 90vw;
	height: 85vh;
	z-index: 1000;
	background-color: lightgray;
	left: 5vw;
	top: 2vh;

	p {
		padding: 10px;
		text-align: center;
		font-size: 25px;
		margin-top: 15%;
	}

	button {
		background-color: var(--croi-green);
		color: white;
		width: 75%;
		font-size: 25px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		border-radius: 15px;
	}
`;
