import {
	AdminColumn,
	BackButton,
	CollegeBlock,
	DecisionScores,
	LearnBlock,
	LoanBlock,
	LoanList,
	PreferenceScores,
	UserBlock,
	ViewLargeContainer
} from "./AdminView.styles";
import { useQuery } from "@tanstack/react-query";
import { LearnWorldsUserProgressResponse, ProgressPerSectionUnit } from "../../types/LearnWorlds.types";
import { fetchStudentProgress } from "../../services/Learn.services";
import { fetchStudent } from "../../services/User.services";
import { User } from "../../types/User.types";
import { fetchStudentLoans } from "../../services/Loan.services";
import { fetchStudentColleges } from "../../services/College.services";
import { loan } from "../../types/Loan.types";
import { College } from "../../types/College.types";
import { ScaleLoader } from "react-spinners";
import React from "react";
import CroiStatusCircle from "../../atoms/CroiStatusCircle";
import { currencyFormatter } from "../../util/currencyFormatter";

export default function AdminView(props: { uid: string; setTool: any }) {
	/*************************************** Student *******************************************/
	const getStudent = useQuery<User, Error>({
		queryKey: ["student", props.uid],
		queryFn: () => fetchStudent(props.uid),
		refetchInterval: false
	});

	const studentInfo = () => {
		if (getStudent.isError) {
			return <UserBlock>Failed to get student: {getStudent.error}</UserBlock>;
		} else if (getStudent.isLoading) {
			return (
				<UserBlock>
					<ScaleLoader color="black" loading={getStudent.isLoading} />
				</UserBlock>
			);
		} else if (getStudent.data) {
			return (
				<UserBlock>
					<h3>Student Info</h3>
					<b>
						{getStudent.data.lastName}, {getStudent.data.firstName}
					</b>
					<br />
				</UserBlock>
			);
		} else {
			return (
				<UserBlock>
					<h3>Student Info</h3>
					<b>Nothing to show...</b>
				</UserBlock>
			);
		}
	};
	/*************************************** Student *******************************************/

	/**************************************** Learn ********************************************/
	const getStudentProgress = useQuery<LearnWorldsUserProgressResponse, Error>({
		queryKey: ["studentProgress", props.uid],
		queryFn: () => fetchStudentProgress(props.uid),
		refetchInterval: false
	});

	const circleMaker = (incomplete: number, complete: number) => {
		const data = [{ value: incomplete }, { value: complete }];
		return CroiStatusCircle(data);
	};

	const checkComplete = (section: ProgressPerSectionUnit, status: "completed" | "not_completed") => {
		let num = 0;

		for (let i = 0; i < section.units.length; i++) {
			if (section.units[i].unit_status === status) {
				num++;
			}
		}

		return num;
	};

	const circleRenderers = (list: ProgressPerSectionUnit[]) =>
		list.map((p, index) => (
			<p>
				{circleMaker(checkComplete(p, "not_completed"), checkComplete(p, "completed"))}
				Section {index + 1}
			</p>
		));

	const learnInfo = () => {
		if (getStudentProgress.isError) {
			return <LearnBlock>Failed to get student learn progress: {getStudentProgress.error}</LearnBlock>;
		} else if (getStudentProgress.isLoading) {
			return (
				<LearnBlock>
					<ScaleLoader color="black" loading={getStudentProgress.isLoading} />
				</LearnBlock>
			);
		} else if (getStudentProgress.data && getStudentProgress.data.data.length > 0) {
			return (
				<LearnBlock>
					<h2>croiLearn Progress</h2>
					<br />
					<div>{circleRenderers(getStudentProgress.data.data[0].progress_per_section_unit)}</div>
				</LearnBlock>
			);
		} else {
			return (
				<LearnBlock>
					<h2>croiLearn Progress</h2>
					<br />
					<div>Nothing to show...</div>
				</LearnBlock>
			);
		}
	};
	/**************************************** Learn ********************************************/

	/*************************************** Colleges ******************************************/
	const getStudentColleges = useQuery<College[], Error>({
		queryKey: ["studentColleges", props.uid],
		queryFn: () => fetchStudentColleges(props.uid),
		refetchInterval: false
	});

	const scoreList = (list: College[]) =>
		list.map((p) => (
			<div>
				<p>
					<b>{p.name}</b>
				</p>
				<p>
					<b>Score:</b> {p.score.toFixed(2)}
				</p>
			</div>
		));

	const collegeInfo = () => {
		if (getStudentColleges.isError) {
			return <CollegeBlock>Failed to get student colleges: {getStudentColleges.error}</CollegeBlock>;
		} else if (getStudentColleges.isLoading) {
			return (
				<CollegeBlock>
					<ScaleLoader color="black" loading={getStudentColleges.isLoading} />
				</CollegeBlock>
			);
		} else if (getStudentColleges.data && getStudentColleges.data.length > 0) {
			return (
				<CollegeBlock>
					<h2>College Decision Scores</h2>
					<DecisionScores>{scoreList(getStudentColleges.data)}</DecisionScores>

					<h2 style={{ marginTop: "20px" }}>College Preferences</h2>
					<PreferenceScores>
						<p>Cost: {getStudent.data ? getStudent.data.costPref : ""}</p>
						<p>Salary: {getStudent.data ? getStudent.data.salaryPref : ""}</p>
						<p>Acceptance: {getStudent.data ? getStudent.data.acceptancePref : ""}</p>
						<p>Retention: {getStudent.data ? getStudent.data.retentionPref : ""}</p>
						<p>Graduation: {getStudent.data ? getStudent.data.gradPref : ""}</p>
					</PreferenceScores>
				</CollegeBlock>
			);
		} else {
			return (
				<CollegeBlock>
					<h2>College Decision Scores</h2>
					<div>Nothing to show...</div>
				</CollegeBlock>
			);
		}
	};
	/*************************************** Colleges ******************************************/

	/**************************************** Loans ********************************************/
	const getStudentLoans = useQuery<loan[], Error>({
		queryKey: ["studentLoans", props.uid],
		queryFn: () => fetchStudentLoans(props.uid),
		refetchInterval: false
	});

	const monthlyCalc = (loaner: loan) => {
		const interest = loaner.interest / 100 / 12;
		const months = 12 * loaner.term;
		const fixed = Math.pow(1 + interest, months);
		return (loaner.amount / (fixed - 1)) * (interest * fixed) + loaner.pay;
	};

	const totalCalc = () => {
		let total = 0;

		if (getStudentLoans.data) {
			for (let i = 0; i <= getStudentLoans.data.length - 1; i++) {
				total += monthlyCalc(getStudentLoans.data[i]) * getStudentLoans.data[i].term * 12;
			}
		}

		return total;
	};

	const mostTime = () => {
		let time = 0;

		if (getStudentLoans.data) {
			for (let i = 0; i <= getStudentLoans.data.length - 1; i++) {
				if (getStudentLoans.data[i].term > time) {
					time = getStudentLoans.data[i].term;
				}
			}
		}

		return time;
	};

	const loanMapper = () =>
		getStudentLoans.data!.map((p) => (
			<p>
				<h4>{p.name}</h4>
				<li>
					<b>Amount:</b> {currencyFormatter.format(p.amount)}
				</li>
				<li>
					<b>Interest:</b> {p.interest}%
				</li>
				<li>
					<b>Loan Term:</b> {p.term} years
				</li>
				<li>
					<b>Monthly Pre-Pay:</b> {currencyFormatter.format(p.pay)}
				</li>
			</p>
		));

	const loanInfo = () => {
		if (getStudentLoans.isError) {
			return <LoanBlock>Failed to get student loans: {getStudentLoans.error}</LoanBlock>;
		} else if (getStudentLoans.isLoading) {
			return (
				<LoanBlock>
					<ScaleLoader color="black" loading={getStudentLoans.isLoading} />
				</LoanBlock>
			);
		} else if (getStudentLoans.data && getStudentLoans.data.length > 0) {
			return (
				<LoanBlock>
					<h2>Student Loans</h2>
					<p>
						Total loans are <b>{currencyFormatter.format(totalCalc())}</b> paid over <b>{mostTime()} years</b>.
					</p>
					<LoanList>{loanMapper()}</LoanList>
				</LoanBlock>
			);
		} else {
			return (
				<LoanBlock>
					<h2>Student Loans</h2>
					<p>Nothing to show...</p>
				</LoanBlock>
			);
		}
	};
	/**************************************** Loans ********************************************/

	return (
		<>
			<BackButton onClick={() => props.setTool("Admin")}>&#8592; Go Back</BackButton>
			<ViewLargeContainer>
				<AdminColumn>
					{studentInfo()}
					{learnInfo()}
				</AdminColumn>
				{collegeInfo()}
				{loanInfo()}
			</ViewLargeContainer>
		</>
	);
}
