import { QueryClient } from "@tanstack/react-query";
//import { fetchCollegeList } from "./College";
//import { College } from "../types/College";
// import {FinancialAward} from "../types/FinancialAward";

/*
async function preFetcher(queryClient: QueryClient) {
	await queryClient.prefetchQuery<College[]>({ queryKey: ["colleges"], queryFn: () => fetchCollegeList() });
	// await queryClient.prefetchQuery<FinancialAward[]>({queryKey:['financialAward'], queryFn: () => fetchFinancialAwardList()});
}
*/
export const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 3,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			staleTime: twentyFourHoursInMs
		}
	}
});
