import styled from "styled-components";
import { device } from "../../util/device";

export const ScheduleMeetingButton = styled.button`
	border: 5px solid var(--croi-green);
	background-color: white;
	border-radius: 15px;
	height: 80px;
	width: 90%;
	color: var(--croi-green);
	font-weight: 700;

	@media only screen and ${device.mobileM} {
		margin-bottom: 20px;
		font-size: 18px;
		margin-top: 20px;
	}

	@media only screen and ${device.tablet} {
		font-size: 2.2vw;
		margin-top: 40px;
	}
`;

export const SignupSection = styled.div`
	background-color: var(--croi-green);
	display: flex;
	justify-content: space-evenly;
	height: 400px;
	border-radius: 15px;

	div {
		margin-top: 18px;
		background-color: white;
		height: 92%;
		width: 92%;
		text-align: center;
		border-radius: 15px;
	}

	p {
		height: 75%;
		margin-left: 5%;
		color: var(--croi-green);
		border-bottom: var(--croi-green) 5px solid;
		width: 90%;
	}

	@media only screen and ${device.mobileM} {
		width: 95vw;
		margin-top: 30px;

		p {
			font-size: 7vw;
		}
	}

	@media only screen and ${device.tablet} {
		width: 35vw;
		margin-top: 0;

		p {
			font-size: 3.2vw;
		}
	}
`;

export const SignupButton = styled.button`
	border: 2px var(--croi-green) solid;
	background-color: white;
	color: var(--croi-green);
	font-size: 20px;
	width: 150px;
	height: 50px;
`;

export const OfferingSection = styled.div`
	border-top: var(--croi-green) 15px solid;
	border-bottom: var(--croi-green) 7.5px solid;
	text-align: center;
	margin-top: 50px;
	width: 90vw;
	margin-left: 5%;
	padding-top: 2%;
	padding-bottom: 2%;
`;

export const FlipSection = styled.div`
	margin-bottom: 10px;

	@media only screen and ${device.mobileL} {
		display: flow;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: center;
	}
`;

export const Offering = styled.div`
	border: var(--croi-green) 5px solid;
	border-radius: 5px;
	height: 300px;

	h4 {
		padding-top: 10%;
		font-weight: 900;
	}

	p {
		padding: 2%;
	}

	:hover {
		cursor: pointer;
	}

	@media only screen and ${device.mobileM} {
		margin-bottom: 20px;

		p {
			font-size: 3.8vw;
		}
	}

	@media only screen and ${device.mobileL} {
		width: 90vw;
		margin-bottom: 20px;

		p {
			font-size: 3.6vw;
		}
	}

	@media only screen and ${device.tablet} {
		font-size: 1.5vw;
		width: 25vw;
		margin-bottom: 0;

		p {
			font-size: 1.21vw;
		}

		h4 {
			font-size: 2.4vw;
		}
	}

	@media only screen and ${device.laptop} {
		font-size: 1.3vw;

		p {
			font-size: 1.07vw;
		}

		h4 {
			font-size: 2.2vw;
		}
	}

	@media only screen and ${device.laptopL} {
		font-size: 1.1vw;

		p {
			font-size: 0.92vw;
		}

		h4 {
			font-size: 2vw;
		}
	}
`;

export const LandImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 9%;
	background-size: contain;

	@media only screen and ${device.mobileM} {
		height: 35.3vw;
		width: 30vw;
	}

	@media only screen and ${device.tablet} {
		height: 5.3vw;
		width: 7vw;
	}
`;

interface ImageProps {
	imageUrl: string;
}

export const NumberCircle = styled.circle`
	background-color: var(--croi-green);
	position: absolute;
	color: white;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	text-align: center;
	font-size: 200%;
	margin-top: -80px;

	@media only screen and ${device.mobileM} {
		padding-top: 3%;
	}

	@media only screen and ${device.tablet} {
		padding-top: 0.6%;
	}
`;

export const BenefitSection = styled.div`
	background: #e9fdf5;
	text-align: center;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		margin-top: 50px;
		width: 80%;
		height: 650px;
		padding: 30px;
		text-align: center;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

		h2 {
			font-weight: 900;
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: column;
		text-align: center;
		margin-bottom: 20px;
		margin-top: 50px;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		height: 350px;
		padding: 30px;

		h2 {
			font-weight: 900;
			font-size: 2.5vw;
			margin-bottom: 10px;
		}
	}
`;

export const BenefitCardsSection = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
	}
`;

export const BenefitCard = styled.div`
	@media only screen and ${device.mobileM} {
		margin-top: 10px;
		width: 90%;
		justify-content: center;
		text-align: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-left: 5%;

		img {
			height: 70px;
			width: 70px;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}

	@media only screen and ${device.tablet} {
		margin-bottom: 20px;
		margin-top: 50px;
		width: 30%;
		justify-content: center;
		text-align: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-left: 2%;

		img {
			height: 100px;
			width: 100px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}
	}
`;

export const TopLanding = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		margin: 30px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;

export const TopTextLanding = styled.div`
	p {
		margin-top: 30px;
		color: #2a3631;
	}

	@media only screen and ${device.mobileM} {
		width: 85vw;
		margin-left: 20px;
	}

	@media only screen and ${device.tablet} {
		width: 55vw;
		margin-left: 50px;

		h2 {
			font-size: 65px;
			font-weight: 900;
		}

		h5 {
			font-size: 30px;
			font-weight: 540;
		}

		p {
			font-size: 25px;
		}
	}
`;

export const VideoEmbed = styled.div`
	border: var(--croi-green) 8px solid;
	border-radius: 15px;

	iframe {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	@media only screen and ${device.mobileM} {
		width: 90vw;
		height: 30em;
	}

	@media only screen and ${device.tablet} {
		margin-left: 50px;
		width: 45vw;
		height: 30em;
	}
`;

interface ImageProps {
	imageUrl: string;
}

export const AMSection = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		margin-bottom: 60px;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin-top: 30px;
		margin-bottom: 20px;
	}
`;

export const AMCards = styled.div`
	background: #e9fdf5;
	border-radius: 10px;
	text-align: center;
	color: black;

	@media only screen and ${device.mobileM} {
		width: 250px;
		height: 200px;
		padding-top: 15px;
		margin-top: 30px;
	}

	@media only screen and ${device.tablet} {
		width: 250px;
		height: 200px;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 15px;
	}
`;

export const AMImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	transform: scale(0.65);
	height: 114px;
	width: 104px;
	margin-left: auto;
	margin-right: auto;
`;

export const FiveStepSection = styled.div`
	margin-bottom: 20px;
	margin-top: 30px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
`;

export const FiveStepCard = styled.div`
	border-radius: 10px;
	background: #e9fdf5;
	width: 200px;
	text-align: center;
	height: 250px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 15px;
	color: black;

	@media only screen and ${device.mobileM} {
		margin-bottom: 30px;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: column;
	}
`;

export const ImageBackground = styled.div`
	background: var(--croi-green);
	height: 90px;
	width: 90px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 40px;
	margin-bottom: 30px;
	border-radius: 10px;
`;

export const FiveStepNumber = styled.div`
	background-color: var(--croi-green);
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	text-align: center;
	font-size: 115%;
	margin-left: auto;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		padding-top: 3%;
	}

	@media only screen and ${device.tablet} {
		padding-top: 2%;
	}
`;

export const StatSection = styled.div`
	background: #e9fdf5;
	margin-bottom: 20px;
	margin-top: 10px;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		height: 300px;
	}
`;

export const StatCard = styled.div`
	background: #e9fdf5;
	margin-bottom: 20px;

	h1 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		text-align: center;
		margin-top: 20px;
		width: 80%;

		h1 {
			font-size: 40px;
		}

		p {
			margin-top: 5px;
			font-size: 15px;
		}
	}

	@media only screen and ${device.tablet} {
		justify-content: center;
		margin-top: 50px;
		text-align: center;
		width: 25%;

		h1 {
			font-size: 4vw;
		}

		p {
			margin-top: 40px;
			font-size: 20px;
		}
	}
`;

export const GraphSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	margin-top: 10px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
`;

export const HeaderGraphSection = styled.div`
	display: flex;
	flex-direction: row;
`;

export const RowGraphSection = styled.div`
	display: flex;
	flex-direction: row;
`;

export const DescriptionSectionHeading = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 150px;
	padding: 15px;

	@media only screen and ${device.mobileM} {
		width: 30%;
		height: 120px;
	}

	@media only screen and ${device.tablet} {
		width: 20%;
		height: 150px;
	}
`;

export const CroiCheckSectionHeading = styled.div`
	background: #e9fdf5;
	border-left: var(--croi-green) solid 4px;
	border-right: var(--croi-green) solid 4px;
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;

	@media only screen and ${device.mobileM} {
		height: 120px;
		width: 30%;

		img {
			height: 70px;
			width: 90px;
			margin-top: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 150px;
		width: 40%;

		img {
			height: 100px;
			width: 120px;
			margin-top: 20px;
		}
	}
`;

export const WithoutCroiSectionHeading = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	width: 40%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	@media only screen and ${device.mobileM} {
		height: 120px;

		img {
			height: 50px;
			width: 50px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 5px;
		}

		a {
			font-weight: 900;
			font-size: 15px;
			padding-top: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 150px;

		img {
			height: 60px;
			width: 60px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 5px;
		}

		a {
			font-weight: 900;
			padding-top: 5px;
			font-size: 25px;
		}
	}
`;

export const DescriptionSection = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		width: 30%;
		font-size: 10px;
	}

	@media only screen and ${device.tablet} {
		font-size: 20px;
		width: 20%;
	}
`;

export const CroiCheckSection = styled.div`
	background: #e9fdf5;
	border-left: var(--croi-green) solid 4px;
	border-right: var(--croi-green) solid 4px;
	border-bottom: var(--croi-green) solid 4px;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		width: 30%;

		img {
			height: 30px;
			width: 30px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 40%;

		img {
			height: 40px;
			width: 40px;
		}
	}
`;

export const WithoutCroiSection = styled.div`
	border-bottom: var(--croi-green) solid 4px;
	width: 40%;
	justify-content: center;
	text-align: center;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media only screen and ${device.mobileM} {
		a {
			width: 80%;
			font-size: 10px;
		}

		img {
			height: 30px;
			width: 30px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		a {
			padding: 5px;
			width: 80%;
			font-size: 20px;
		}

		img {
			height: 40px;
			width: 40px;
			margin-top: 25px;
		}
	}
`;

export const CreateAccountSection = styled.div`
	background: var(--croi-green);
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin-bottom: 20px;
	margin-top: 70px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	border-radius: 15px;
	align-items: center;
	text-align: center;

	@media only screen and ${device.mobileM} {
		height: 150px;

		h1 {
			color: white;
			width: 50%;
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		height: 200px;

		h1 {
			color: white;
			width: 50%;
			font-size: 3.5vw;
		}
	}
`;

export const CreateAccountButton = styled.button`
	border: solid var(--croi-green);
	background-color: white;
	border-radius: 10px;
	height: 60px;
	width: 220px;
	font-size: 15px;
	color: var(--croi-green);
	margin-right: 5px;

	@media only screen and ${device.mobileM} {
		width: 150px;
		height: 60px;
	}

	@media only screen and ${device.tablet} {
		height: 60px;
		width: 220px;
	}
`;
