import styled from "styled-components";
import { device } from "../../util/device";

export const CriteriaContainer = styled.div`
	@media only screen and ${device.mobileM} {
		text-align: center;
		margin-top: 20px;

		h2 {
			height: 40px;
			font-weight: bold;
		}
	}

	@media only screen and ${device.tablet} {
		text-align: center;
		margin-top: 20px;

		h2 {
			height: 40px;
			font-weight: bold;
		}
	}
`;

export const CriteriaBox = styled.div`
	border-radius: 10px;

	@media only screen and ${device.mobileM} {
		background-color: #f0f0f0;
		width: 95%;
		margin-left: 3%;
	}

	@media only screen and ${device.tablet} {
		background-color: #f0f0f0;
		width: 33vw;
		margin-left: 0;
	}
`;

export const Criteria = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 5px;
		height: 100px;
		justify-content: center;
		margin-left: 5%;
		padding-bottom: 5%;

		p {
			font-size: 18px;
			width: 70%;
			text-align: left;
			padding-right: 0;
		}

		select {
			width: 80%;
			height: 30px;
			text-align: left;
			border: black solid 1px;
			border-radius: 5px;
			margin-right: 10%;
			padding-left: 10px;
		}

		.select-container {
			width: 80%;
			height: 30px;
			border-radius: 5px;
			margin-right: 10%;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 100%;
		padding-top: 5px;
		margin-left: 0;
		padding-bottom: 0;
		height: 50px;

		p {
			font-size: 18px;
			padding-right: 15px;
			width: 40%;
			text-align: right;
		}

		select {
			width: 50%;
			height: 30px;
			text-align: left;
			border: black solid 1px;
			border-radius: 5px;
			margin-right: 10%;
			padding-left: 10px;
		}

		.select-container {
			width: 50%;
			height: 30px;
			border-radius: 5px;
			margin-right: 10%;
		}
	}
`;
