import React from "react";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import { College } from "../types/College.types";

function getLongestSchoolName(schools: College[]) {
	let longest = 0;
	schools.forEach((school) => {
		if (school.name.length > longest) {
			longest = school.name.length;
		}
	});
	return longest;
}

export const CroiBarChartMoney = ({ data, x, y, varName }: BarChartProps) => {
	return (
		<div>
			<BarChart
				width={window.innerWidth >= 800 ? 700 : 290}
				height={250}
				data={data}
				margin={{ bottom: getLongestSchoolName(data) * 2 }}>
				<XAxis
					dataKey={x}
					width={0}
					angle={-15}
					padding={{
						left: (() => {
							const l = data[0].name.length;
							return 2 * l;
						})()
					}}
					textAnchor="end"
					tick={{ fontSize: 12 }}
					interval={0}
					stroke="#000"></XAxis>
				<YAxis domain={[0, "auto"]} tick={{ fontSize: 12 }} label={{ fill: "black" }} stroke="#000" />
				<Tooltip content={<CustomTooltipMoney varName={varName} />} />

				<Bar dataKey={y} fill={"var(--main-teal)"} />
			</BarChart>
		</div>
	);
};

export const CroiBarChartPercent = ({ data, x, y, varName }: BarChartProps) => {
	return (
		<div>
			<BarChart
				width={window.innerWidth >= 800 ? 700 : 290}
				height={250}
				data={data}
				margin={{ bottom: getLongestSchoolName(data) * 2 }}>
				<XAxis
					dataKey={x}
					width={0}
					angle={-15}
					padding={{
						left: (() => {
							const l = data[0].name.length;
							return 2 * l;
						})()
					}}
					textAnchor="end"
					tick={{ fontSize: 12 }}
					interval={0}
					stroke="#000"></XAxis>
				<YAxis domain={[0, "auto"]} tick={{ fontSize: 12 }} label={{ fill: "black" }} stroke="#000" />
				<Tooltip content={<CustomTooltipPercent varName={varName} />} />

				<Bar dataKey={y} fill={"var(--main-teal)"} />
			</BarChart>
		</div>
	);
};

const CustomTooltipMoney = ({ active, payload, varName }: any) => {
	// console.log(payload);
	if (active && payload && payload.length) {
		return (
			<div
				style={{
					backgroundColor: "white",
					border: "solid 1px white",
					padding: "10px",
					lineHeight: "-10px"
				}}>
				<div>
					{varName}: ${payload[0].payload[payload[0].dataKey]}
				</div>
			</div>
		);
	}

	return null;
};

const CustomTooltipPercent = ({ active, payload, varName }: any) => {
	// console.log(payload);
	if (active && payload && payload.length) {
		return (
			<div
				style={{
					backgroundColor: "white",
					border: "solid 1px white",
					padding: "10px",
					lineHeight: "-10px"
				}}>
				<div>
					{varName}: {payload[0].payload[payload[0].dataKey]}%
				</div>
			</div>
		);
	}

	return null;
};

type BarChartProps = {
	data: College[];
	x: string;
	y: string;
	varName: string;
};
