import { mutator } from "./Mutator.services";

export function createFinancialAward(body: any) {
	return mutator("/awards", "POST", body);
}

export function updateFinancialAward(body: any) {
	return mutator("/awards", "PUT", body);
}

export function deleteFinancialAward(body: any) {
	return mutator("/awards", "DELETE", body);
}
