import React from "react";
import { LeftSide, PrefList, TopWrapper } from "./CollegeDecisionTool.styles";
import PreferenceList from "../../components/CollegeDecisionModules/Preferences";
import CollegeList from "../../components/CollegeDecisionModules/CollegeList";
import Results from "../../components/CollegeDecisionModules/Results";
import ColorKey from "../../components/CollegeDecisionModules/ColorKey";
import MyCriteria from "../../components/CollegeDecisionModules/MyCriteria";
import { College } from "../../types/College.types";
import { User } from "../../types/User.types";

export default function CollegeDecisionTool(props: { colleges: College[]; user: User }) {
	return (
		<>
			<TopWrapper>
				<LeftSide>
					<PrefList>
						<CollegeList />
					</PrefList>

					<MyCriteria user={props.user} />
				</LeftSide>
				<PreferenceList />
			</TopWrapper>
			<div style={{ marginTop: "100px" }}>
				<hr
					style={{
						border: "solid 5px #3AD698",
						width: "90%",
						marginLeft: "auto",
						marginRight: "auto",
						opacity: "100%"
					}}
				/>
				<h2 style={{ textAlign: "center", fontWeight: "bold" }}>My College Rankings</h2>
				<ColorKey />
				<Results colleges={props.colleges} />
			</div>
		</>
	);
}
