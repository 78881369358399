import React from "react";
import { DataGraphs, ResultsLargeCard } from "./Results.styles";
import { CroiBarChartMoney, CroiBarChartPercent } from "../CroiBarChart";
import ResultsTable from "./ResultsTable";
import { College } from "../../types/College.types";

export default function Results(props: { colleges: College[] }) {
	return (
		<ResultsLargeCard>
			<>
				{props.colleges.length > 0 ? (
					<ResultsTable colleges={props.colleges} />
				) : (
					<p style={{ textAlign: "center", paddingBottom: "10px" }}>No Current Rankings</p>
				)}

				<h2>Data Breakdown</h2>
				{props.colleges.length ? (
					<DataGraphs>
						<h2>For better results, view on computer</h2>
						<div>
							<h4>Cost</h4>
							<CroiBarChartMoney data={props.colleges} y={"cost"} x={"name"} varName={"Cost Per Year"} />
						</div>

						<div>
							<h4>Salary After Graduation</h4>
							<CroiBarChartMoney data={props.colleges} y={"earlyPay"} x={"name"} varName={"Salary"} />
						</div>

						<div>
							<h4>Admission Rate</h4>
							<CroiBarChartPercent data={props.colleges} y={"acceptanceRate"} x={"name"} varName={"Admission Rate"} />
						</div>

						<div>
							<h4>Retention Rate</h4>
							<CroiBarChartPercent data={props.colleges} y={"retentionRate"} x={"name"} varName={"Retention Rate"} />
						</div>

						<div>
							<h4>Completion Rate</h4>
							<CroiBarChartPercent
								data={props.colleges}
								y={"graduationRate" as "Completion Rate"}
								x={"name"}
								varName={"Completion Rate"}
							/>
						</div>

						<div>
							<h4>Job Matriculation Rate</h4>
							<CroiBarChartPercent data={props.colleges} y={"jobSuccessRate"} x={"name"} varName={"Job Success Rate"} />
						</div>
					</DataGraphs>
				) : (
					<p>No Data</p>
				)}
			</>
		</ResultsLargeCard>
	);
}
