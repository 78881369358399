import styled from "styled-components";
import { device } from "../../util/device";

export const Circle = styled.circle`
	@media only screen and ${device.mobileM} {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin-top: 5px;
		text-align: center;
		justify-content: center;
		padding-top: 6%;
		color: white;
	}

	@media only screen and ${device.tablet} {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin-top: 5px;
		text-align: center;
		justify-content: center;
		padding-top: 12%;
		color: white;
	}
`;

export const SmallCard = styled.div`
	background-color: #eeeeee;
	width: 90%;
	margin: 0 5% 20px;
	padding: 10px 4%;
	border-radius: 10px;

	h2 {
		font-weight: bold;
		padding-top: 14px;
	}

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 80px;
	}
`;

export const Section = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		height: 100%;

		h4 {
			padding-top: 16px;
			padding-left: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		height: 100%;

		h4 {
			padding-top: 16px;
			padding-left: 5px;
		}
	}
`;
