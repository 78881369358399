import React, { useState } from "react";
import { Card, Form, Modal, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoginCardWrapper, LoginWrapper } from "./Login.styles";
import { SubmitButton } from "../../styles/SubmitButton.styles";
import NavBar from "../Navbar/Navbar";
import { Snack } from "../../atoms/SnackBarAlert";
import { supabase } from "../../supabase";

export default function Login() {
	const [emailRef, setEmail] = useState<string>("");
	const [passwordRef, setPassword] = useState<string>("");
	const navigate = useNavigate();
	const [authing, setAuthing] = useState(false);
	const [loginError, setLoginError] = useState("");
	const [show, setShow] = useState(false);
	const openResetModal = () => setShow(true);
	const handleClose = () => setShow(false);
	const [errMessage, setErrMessage] = useState("");
	const [resetEmail, setResetEmail] = useState("");
	const [snackMsg, setMsg] = useState("");
	const [reset, setReset] = useState(false);
	const [newPassword, setNewPassword] = useState("");

	window.onload = () => {
		if (window.location.href.includes("success")) {
			setMsg("Successfully created account! Please sign in to continue.");
		}
		if (window.location.search.includes("reset")) {
			setReset(true);
			setMsg("Please enter a new password for your account.");
		}
		if (window.location.search.includes("unauthorized")) {
			setMsg("Unauthorized, please sign in.");
		}
	};

	const logIn = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setAuthing(true);
		supabase.auth
			.signInWithPassword({
				email: emailRef,
				password: passwordRef
			})
			.then((userCredential) => {
				const user = userCredential;
				if (user.error) {
					throw user.error;
				}
				setAuthing(false);
				navigate("/userTools");
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode + errorMessage);
				setLoginError("We had trouble logging you in, please provide valid credentials");
				setAuthing(false);
			});
	};

	const resetPass = async (email: string) => {
		supabase.auth
			.resetPasswordForEmail(email, {
				redirectTo: `${window.location.origin}/login?reset`
			})
			.then(() => {
				handleClose();
				setMsg("Password Email Sent Successfully!");
			})
			.catch((error) => {
				const errorMessage = error.message;
				setErrMessage(errorMessage);
			});
	};

	const resetHandler = () => {
		if (passwordRef !== newPassword) {
			setMsg("Password Mismatch!");
			return;
		}

		supabase.auth
			.updateUser({ password: newPassword })
			.then(() => {
				navigate("/userTools");
				setMsg("Changed password successfully! Please log in with your new password.");
				setReset(false);
			})
			.catch((err) => {
				setMsg("Error: " + err);
			});
	};

	return (
		<>
			<NavBar />
			<Snack msg={snackMsg} setMsg={setMsg} />
			<LoginWrapper>
				<Row className=" d-flex align-items-center justify-content-center">
					<div
						style={{
							maxWidth: "400px"
						}}>
						<LoginCardWrapper>
							<Card>
								<Card.Img variant="top" src="images/croi logo no words.png" />
								<Card.Body>
									<h4
										className="text-center mb-4"
										style={{
											borderBottom: "1px solid #000",
											lineHeight: "0.1em"
										}}>
										<span
											style={{
												background: "var(--white)",
												padding: "0 10px",
												fontFamily: "Montserrat"
											}}>
											Login
										</span>
									</h4>
									<Form>
										{reset ? (
											<></>
										) : (
											<Form.Group>
												<Form.Label>Email </Form.Label>
												<Form.Control
													type="email"
													onChange={(event) => setEmail(event.target.value)}
													value={emailRef}
													required
												/>
											</Form.Group>
										)}
										<Form.Group>
											<Form.Label>{reset ? "New" : ""} Password</Form.Label>
											<Form.Control
												type="password"
												onChange={(event) => setPassword(event.target.value)}
												value={passwordRef}
												required
											/>
										</Form.Group>
										{reset ? (
											<Form.Group>
												<Form.Label>Confirm Password</Form.Label>
												<Form.Control
													type="password"
													onChange={(event) => setNewPassword(event.target.value)}
													value={newPassword}
													required
												/>
											</Form.Group>
										) : (
											<></>
										)}
										{!reset ? (
											<div
												style={{
													display: "flex",
													justifyContent: "end",
													marginTop: "10px"
												}}>
												<p
													onClick={() => openResetModal()}
													style={{
														textDecorationLine: "underline",
														fontSize: "12px",
														alignSelf: "end",
														cursor: "pointer"
													}}>
													Forgot Password?
												</p>
											</div>
										) : (
											<></>
										)}
										{loginError && <p style={{ color: "red" }}>{loginError}</p>}
										{reset ? (
											<SubmitButton onClick={() => resetHandler()}>Change Password</SubmitButton>
										) : (
											<SubmitButton disabled={authing} onClick={(e) => logIn(e)}>
												Login
											</SubmitButton>
										)}
									</Form>
								</Card.Body>
								<div className="w-100 text-center mb-2">
									Don't have an account? <Link to="/signup">Sign-Up</Link>
								</div>
							</Card>
						</LoginCardWrapper>
					</div>
				</Row>

				<Modal style={{ fontFamily: "sitka-small, serif" }} show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Reset Password</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<input
							style={{ width: "100%", border: "1px solid black" }}
							placeholder="Enter Email"
							type="email"
							onChange={(e) => {
								setResetEmail(e.target.value);
							}}
						/>
					</Modal.Body>
					<Modal.Footer>
						{errMessage && errMessage}
						<SubmitButton onClick={() => resetPass(resetEmail)}>Submit</SubmitButton>
					</Modal.Footer>
				</Modal>
			</LoginWrapper>
		</>
	);
}
