import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../App.css";
import "../index.css";
import AuthRoute from "./AuthRoute";
import Landing from "../pages/Landing/Landing";
import Login from "./Login/Login";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Blog from "../pages/Blog/Blog";
import GlobalStyle from "../styles/GlobalStyles";
import { QueryClientProvider } from "@tanstack/react-query";
import PageNotFound from "../pages/PageNotFound";
import { queryClient } from "../services/QueryClient.services";
import LoginFromLW from "./Login/LoginFromLW";
import UserTools from "../pages/UserTools/UserTools";
import Counselors from "../pages/Counselors/Counselors";
import CreateAccount from "../pages/CreateAccount/CreateAccount";
import Pricing from "../pages/Pricing/Pricing";

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<GlobalStyle />
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<PageNotFound />} />
					<Route path="/" element={<Landing />} />
					<Route path="/login" element={<Login />} />
					<Route path="/loginLW" element={<LoginFromLW />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/about" element={<About />} />
					<Route path="/blog" element={<Blog />} />
					<Route path="/counselors" element={<Counselors />} />
					<Route path="/signup" element={<CreateAccount />} />
					<Route path="/pricing" element={<Pricing />} />
					<Route
						path={"/userTools"}
						element={
							<AuthRoute>
								<UserTools />
							</AuthRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
