import styled from "styled-components";
import { device } from "../../util/device";

export const DashMaster = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: space-evenly;
	}
`;

export const DashColumn = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
	}
`;

export const ProfileBlock = styled.div`
	@media only screen and ${device.mobileM} {
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		p {
			font-size: 20px;
		}

		u {
			color: gray;
			text-underline: gray;
			cursor: pointer;
		}
	}

	@media only screen and ${device.tablet} {
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		p {
			margin: 0;
			font-size: 20px;
		}

		u {
			margin: 0;
			color: gray;
			text-underline: gray;
			cursor: pointer;
		}
	}
`;

export const ProfileImageCircle = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 225px;
	height: 225px;
	margin-bottom: 15px;
	border-radius: 50%;
	border: var(--croi-green) 5px solid;
	margin-right: auto;
	margin-left: auto;
`;

export const DashNext = styled.div`
	@media only screen and ${device.mobileM} {
		margin-top: 15px;
		text-align: center;
		padding-bottom: 10px;

		h3 {
			font-weight: bolder;
			text-align: left;
		}
	}

	@media only screen and ${device.tablet} {
		margin-top: 15px;

		h3 {
			font-weight: bolder;
			text-align: left;
		}
	}
`;

export const LearnCourse = styled.div`
	background-color: #eeeeee;
	border-radius: 15px;
	margin-bottom: 15px;
	padding: 10px;

	:hover {
		cursor: pointer;
	}

	h4 {
		font-weight: bold;
	}

	p {
		text-align: center;
		color: gray;
		margin: 0;
		font-weight: bold;
	}
`;

export const LearnProgressBar = styled.div`
	background-color: #e4e4e4;
	border-radius: 15px;
	width: 100%;
	height: 25px;

	.progress {
		height: 23px;
		border-radius: 15px;
		background-color: var(--croi-green);
	}
`;

interface ImageProps {
	imageUrl: string;
}
