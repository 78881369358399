import styled from "styled-components";
import { device } from "../../util/device";

export const SettingsWindowBackdrop = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: lightgrey;
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	opacity: 50%;
`;

export const SettingsWindowBox = styled.div`
	width: 70%;
	z-index: 102;
	position: fixed;
	top: 20%;
	left: 20%;
	border: var(--croi-green) 10px solid;
	border-radius: 10px;
	background-color: white;

	h1 {
		font-weight: bold;
		text-align: center;
	}

	p {
		display: flex;
		justify-content: center;

		@media only screen and ${device.mobileM} {
			flex-direction: column;
		}

		@media only screen and ${device.tablet} {
			flex-direction: row;
		}
	}

	label {
		width: 200px;
		text-align: right;
	}

	input,
	select {
		width: 200px;
		border: 1px solid var(--croi-green);
		margin-left: 20px;
		border-radius: 5px;
		padding-left: 5px;
		background-color: white;
		height: 28px;
	}
`;

export const PricingRow = styled.div`
	width: 80%;
	margin-left: 10%;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		justify-content: space-evenly;
		margin-top: 2%;
		display: flex;
		flex-direction: row;
	}
`;

export const PricingBox = styled.div`
	height: auto;
	background-color: white;
	text-align: center;

	h1 {
		padding-top: 1%;
		color: black;
	}

	@media only screen and ${device.mobileM} {
		h1 {
			font-size: 8vw;
		}
	}

	@media only screen and ${device.tablet} {
		h1 {
			font-size: 4vw;
		}
	}
`;

export const PricingCard = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and ${device.mobileM} {
		margin-bottom: 30px;
		width: 80%;
	}

	@media only screen and ${device.tablet} {
		width: 30%;
	}
`;

export const PricingBoxLarge = styled.div`
	background-color: #e9fdf5;
	border-radius: 10px;
	text-align: center;

	h3 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		h3 {
			color: black;
			padding-top: 2%;
			font-size: 30px;
		}

		h4 {
			color: #6d7471;
			padding-top: 5%;
			font-size: 20px;
		}

		p {
			color: black;
			font-size: 15px;
		}
	}

	@media only screen and ${device.tablet} {
		h3 {
			color: black;
			padding-top: 2%;
			font-size: 2.25vw;
		}

		h4 {
			color: #6d7471;
			padding-top: 5%;
			font-size: 1.75vw;
		}

		p {
			color: black;
			font-size: 1vw;
		}
	}
`;

export const UpgradeButton = styled.button`
	text-align: center;
	background-color: var(--croi-green);
	color: white;
	margin-bottom: 5%;
	margin-top: 10%;
	border-radius: 8px;
	width: 80%;

	@media only screen and ${device.mobileM} {
		height: 40px;
		font-size: 20px;

		a {
			color: white;
		}
	}

	@media only screen and ${device.tablet} {
		height: 40px;
		font-size: 20px;

		a {
			color: white;
		}
	}
`;

export const SmallBanner = styled.div`
	background-color: var(--croi-green);
	color: white;
	height: 35px;
	text-align: center;
	border-radius: 10px;
	font-size: 25px;
	padding-top: 1px;
`;

export const CloseXButton = styled.button`
	width: 30px;
	height: 30px;
	background: var(--croi-green);
	color: white;
	font-weight: bold;
	margin-top: 1%;
	margin-left: 95%;
`;
