import { fetcher } from "./Fetcher.services";
import { College, fetchUniqueReturn } from "../types/College.types";
import { mutator } from "./Mutator.services";
import { fetcherBody } from "./FetcherBody.services";
import { MatchData } from "../types/MatchData.types";

export function fetchCollegeList() {
	return fetcher<College[]>("/colleges", "GET");
}

export function fetchUniqueCollege(iped: string) {
	return fetcher<fetchUniqueReturn>(`/colleges/unique/${iped}`, "GET");
}

export function createCollege(body: any) {
	return mutator("/colleges", "POST", body);
}

export function updateCollege(body: any) {
	return mutator("/colleges", "PUT", body);
}

export function deleteCollege(body: any) {
	return mutator("/colleges", "DELETE", body);
}

export function fetchStudentColleges(uid: string) {
	return fetcher<College[]>(`/colleges/student/${uid}`, "GET");
}

export function fetchMatchData(body: any) {
	return fetcherBody<MatchData>("/colleges/match", "POST", body);
}
