import { Scholarship } from "../../types/Scholarship.types";
import { useState } from "react";
import { currencyFormatter } from "../../util/currencyFormatter";
import { DetailColumn, DetailSection, ViewButton, ActionButton } from "./MobileScholarshipTab.styles";

export default function MobileScholarshipTab(props: { scholarship: Scholarship; saved: boolean; click: any }) {
	const [details, setDetails] = useState(false);

	const disabilityCheck = (disabilities: boolean[]) => {
		let isDisability = false;
		if (disabilities[0]) {
			isDisability = true;
		}
		if (disabilities[1]) {
			isDisability = true;
		}
		if (disabilities[2]) {
			isDisability = true;
		}
		if (disabilities[3]) {
			isDisability = true;
		}

		return isDisability;
	};

	const disabilityReturn = (disabilities: boolean[]) => {
		const disabilitiesReturned: string[] = [];
		if (disabilities[0]) {
			disabilitiesReturned.push("Hearing");
		}
		if (disabilities[1]) {
			disabilitiesReturned.push("Learning");
		}
		if (disabilities[2]) {
			disabilitiesReturned.push("Physical");
		}
		if (disabilities[3]) {
			disabilitiesReturned.push("Visual");
		}

		return disabilitiesReturned;
	};

	return (
		<>
			<tr>
				<td>{props.scholarship.name}</td>
				<td>{props.scholarship.amount ? currencyFormatter.format(props.scholarship.amount) : "Variable"}</td>
				<td>
					<ViewButton onClick={() => setDetails(!details)}>{details ? <>&#9650;</> : <>&#9660;</>}</ViewButton>
				</td>
			</tr>
			{details ? (
				<tr>
					<td colSpan={3} style={{ borderBottom: "solid 2px gray" }}>
						<DetailSection>
							<DetailColumn style={{ textAlign: "right" }}>
								<b>Field of Study =</b>
								<br />
								<b>Gender =</b>
								<br />
								<b>Ethnicity =</b>
								<br />
								<b>Disability =</b>
							</DetailColumn>
							<DetailColumn>
								{props.scholarship.fos.length > 0 ? props.scholarship.fos : "N/A"}
								<br />
								{props.scholarship.gender ? props.scholarship.gender : "N/A"}
								<br />
								{props.scholarship.ethnicities.length > 0 ? props.scholarship.ethnicities : "N/A"}
								<br />
								{disabilityCheck([
									props.scholarship.disabilityHearing,
									props.scholarship.disabilityLearning,
									props.scholarship.disabilityPhysical,
									props.scholarship.disabilityVisual
								])
									? disabilityReturn([
											props.scholarship.disabilityHearing,
											props.scholarship.disabilityLearning,
											props.scholarship.disabilityPhysical,
											props.scholarship.disabilityVisual
									  ])
									: "N/A"}
							</DetailColumn>
						</DetailSection>
						<ActionButton
							style={{ backgroundColor: "var(--croi-green)", color: "white" }}
							onClick={() => window.open(props.scholarship.url)}>
							Link
						</ActionButton>
						<br />
						<ActionButton onClick={() => props.click(props.scholarship.id)}>
							{props.saved ? "Remove" : "Add"}
						</ActionButton>
					</td>
				</tr>
			) : (
				<></>
			)}
		</>
	);
}
