import { fetcherBody } from "./FetcherBody.services";
import { mutator } from "./Mutator.services";
import { fetcher } from "./Fetcher.services";

export function createStripeUser(body: any) {
	return mutator("/payments/create", "POST", body);
}

export function paymentCheckout(body: any) {
	return fetcherBody<string>("payments/checkout", "POST", body);
}

export function fetchSubscriptionDetails() {
	return fetcher<any>("payments/subscription", "GET");
}
