import React from "react";
import { Pie, PieChart, Sector } from "recharts";
import {
	BannerSection,
	CardSection,
	CroiHowToButton,
	Filler,
	ImageSection,
	LearnBanner,
	LearnCard,
	LearnNavImage,
	LiteracyButton,
	ProgressBar,
	SmallLearnNavImage,
	TitleSection
} from "./LearnNav.styles";
import { LearnWorldsUserProgressResponse, ProgressPerSectionUnit } from "../../types/LearnWorlds.types";
import { useMutation } from "@tanstack/react-query";
import { fetchLWAccess } from "../../services/Learn.services";

const renderActiveShape = (props: any) => {
	const { cx, cy, innerRadius, outerRadius, fill, payload } = props;
	const { /*complete,*/ incomplete } = payload;

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} stroke="black">
				{incomplete} Lessons
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={0}
				endAngle={360}
				fill={"#F2F2F2"}
			/>
		</g>
	);
};

const LearnProgressCircle = ({ complete, incomplete }: { complete: number; incomplete: number }) => {
	const data = [
		{ name: "Incomplete", value: incomplete, complete, incomplete },
		{ name: "Complete", value: complete, complete, incomplete }
	];

	if (complete == incomplete && complete !== 0) {
		return <LearnNavImage imageUrl={"images/greenCheck.png"} />;
	} else {
		return (
			<PieChart width={165} height={165}>
				<Pie
					data={[{ value: 1 }]}
					cx={76}
					cy={76}
					outerRadius={80}
					innerRadius={80}
					stroke="var(--croi-green)"
					strokeWidth={3}
					fill="none"
					dataKey="value"
				/>
				<Pie
					data={[{ value: 1 }]}
					cx={76}
					cy={76}
					outerRadius={50}
					innerRadius={50}
					stroke="var(--croi-green)"
					strokeWidth={3}
					fill="none"
					dataKey="value"
				/>
				<Pie
					stroke={"var(--croi-green)"}
					activeIndex={0}
					activeShape={renderActiveShape}
					data={data}
					cx={76}
					cy={76}
					innerRadius={50}
					outerRadius={80}
					fill="var(--croi-green)"
					dataKey="value"
					startAngle={90}
					endAngle={450}
					cornerRadius={4}
				/>
			</PieChart>
		);
	}
};

const checkCompletion = (list: ProgressPerSectionUnit[]) => {
	let numComplete = 0;
	let numIncomplete = 0;
	if (list.length > 0) {
		for (let j = 0; j < list.length; j++) {
			for (let i = 0; i < list[j].units.length; i++) {
				if (list[j].units[i].unit_status === "completed") {
					numComplete++;
				} else {
					numIncomplete++;
				}
			}
		}
	}

	return { numComplete, numIncomplete };
};

export default function LearnNav(props: { data: LearnWorldsUserProgressResponse | undefined }) {
	const croiLearn = props.data ? props.data.data.find((a) => a.course_id == "croilearn") : undefined;
	const fafsa = props.data ? props.data.data.find((a) => a.course_id == "fafsa-step-by-step") : undefined;

	const complete0 = checkCompletion(croiLearn ? croiLearn.progress_per_section_unit : []).numComplete;
	const incomplete0 = checkCompletion(croiLearn ? croiLearn.progress_per_section_unit : []).numIncomplete;
	const complete1 = checkCompletion(fafsa ? fafsa.progress_per_section_unit : []).numComplete;
	const incomplete1 = checkCompletion(fafsa ? fafsa.progress_per_section_unit : []).numIncomplete;

	const getURL = useMutation({
		mutationKey: ["learn"],
		mutationFn: (body: any) => fetchLWAccess(body),
		onSuccess: (response) => {
			window.open(response);
		}
	});

	return (
		<>
			<BannerSection>
				<LearnBanner>
					<ImageSection>
						<SmallLearnNavImage imageUrl={"images/logo_green.svg"} />
					</ImageSection>
					<TitleSection>
						<h2>How To Use Croi</h2>
						<p>Your guide to using the Croi Platform</p>
					</TitleSection>
					<CroiHowToButton
						onClick={() =>
							window.open("https://www.youtube.com/watch?v=JeTw0ChvTFs&list=PLbQ3MWBGtj7t3Y0VYxxjuTWzS2lQnRCSk&index=1")
						}>
						Watch Video
					</CroiHowToButton>
				</LearnBanner>
			</BannerSection>
			<CardSection>
				<LearnCard>
					<h2>Financial Literacy</h2>
					<p>Learn all about money and personal finance</p>
					{complete0 ? (
						<LearnProgressCircle complete={complete0} incomplete={incomplete0} />
					) : (
						<LearnProgressCircle complete={0} incomplete={0} />
					)}
					<ProgressBar>
						<Filler style={{ width: `${croiLearn ? croiLearn.progress_rate : 0}%` }}></Filler>
					</ProgressBar>
					<p>{croiLearn ? croiLearn.progress_rate : 0}% Complete</p>
					<LiteracyButton onClick={() => getURL.mutate({ course_id: "croilearn" })}>Continue Learning</LiteracyButton>
				</LearnCard>
				<LearnCard>
					<h2>Financial Aid</h2>
					<p>Your guide to understanding financial aid</p>
					{complete1 ? (
						<LearnProgressCircle complete={complete1} incomplete={incomplete1} />
					) : (
						<LearnProgressCircle complete={0} incomplete={0} />
					)}
					<ProgressBar>
						<Filler style={{ width: `${fafsa ? fafsa.progress_rate : 0}%` }}></Filler>
					</ProgressBar>
					<p>{fafsa ? fafsa.progress_rate : 0}% Complete</p>
					<LiteracyButton onClick={() => getURL.mutate({ course_id: "fafsa-step-by-step" })}>
						Continue Learning
					</LiteracyButton>
				</LearnCard>
			</CardSection>
		</>
	);
}
