import styled from "styled-components";
import { device } from "../../util/device";

export const LargeCard = styled.div`
	background-color: #eeeeee;
	border-radius: 15px;
	margin-top: 15px;

	@media only screen and ${device.mobileM} {
		width: 85vw;
		margin-left: auto;
		margin-right: auto;
		height: 100%;
		text-align: center;

		h2 {
			font-weight: bold;
			padding-top: 15px;
		}

		h4 {
			width: 10px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 33vw;
		margin-left: 0;
		margin-right: 0;
		height: 100%;
		text-align: center;

		h2 {
			font-weight: bold;
			padding-top: 15px;
		}

		h4 {
			width: 10px;
		}
	}
`;

export const CollegeBox = styled.div`
	overflow-x: hidden;
	margin-bottom: 10px;
	border-radius: 10px;
	background-color: #dad9d9;
	text-align: left;
	margin-left: auto;
	margin-right: auto;

	h3 {
		justify-content: space-between;
		display: flex;
		margin: 5px 10px;
	}

	b {
		width: 90%;
		overflow-x: clip;
	}

	p {
		font-size: 16px;
		margin-left: 11px;
		margin-top: 0;
		margin-bottom: 5px;
	}

	select {
		border-radius: 5px;
		border: black 1px solid;
		padding: 5px;
		margin-left: 5px;
		width: 55%;
		margin-top: 0;
	}

	@media only screen and ${device.mobileM} {
		width: 90%;

		h3 {
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 85%;

		h3 {
			font-size: 25px;
		}
	}
`;

export const SearchBar = styled.div`
	@media only screen and ${device.mobileM} {
		margin-bottom: 15px;
		width: 82%;
		max-height: 200px;
		overflow: hidden;
		overflow-y: auto;
		border-radius: 5px;
		margin-left: 9.2%;
	}

	@media only screen and ${device.tablet} {
		margin-bottom: 15px;
		width: 82%;
		max-height: 200px;
		overflow: hidden;
		overflow-y: auto;
		border-radius: 5px;
		margin-left: 9.2%;
	}
`;

export const SearchInputs = styled.div`
	display: flex;
`;

export const SearchItem = styled.div`
	line-height: 50px;
	margin-top: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;
	:hover {
		background: #cccccc;
	}
`;

export const SearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;

	@media only screen and ${device.mobileM} {
		width: 66%;
	}

	@media only screen and ${device.tablet} {
		width: 27.1%;
	}
`;

export const SubmitButtonCollegeCard = styled.button`
	background-color: var(--croi-green);
	color: #fff;
	border-radius: 10px;
	width: 100px;
`;

export const FABox = styled.div`
	display: flex;
	margin-top: 5px;

	p {
		width: 85px;
	}
`;

export const CollegeListBox = styled.div`
	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		margin-top: 50px;
		margin-bottom: 50px;
		background-color: #e4e4e4;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--croi-green);
		border-radius: 100px;
	}

	height: 70%;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const CollegeDelete = styled.button`
	border-radius: 50%;
	width: 28px;
	height: 28px;
	border: black 2px solid;
	font-size: 18px;
	text-align: center;

	:hover {
		background: none;
	}
`;

export const FAEditButton = styled.button`
	background-color: var(--croi-green);
	width: 50px;
	height: 20px;
	border-radius: 5px;
	font-size: 15px;
	line-height: 5px;
	margin-left: 15px;
`;
