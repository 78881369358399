import React, { useState } from "react";
import {
	ContactBox,
	InfoColumn,
	InputSection,
	NameInputSection,
	PaymentButton,
	PriceBoxRow,
	PricingColumn
} from "./CreateAccount.styles";
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useMutation } from "@tanstack/react-query";
import { createStripeUser, paymentCheckout } from "../../services/Stripe.services";
import { useCookies } from "react-cookie";
import { Snack } from "../../atoms/SnackBarAlert";
import TermsOfService from "../../components/TermsOfServiceWindow/TermsOfService";

export default function CreateAccount() {
	const [newUser, setNewUser, deleteCookie] = useCookies(["croi-new-user"]);
	const [formData, setFormData] = useState({
		email: "",
		firstName: "",
		lastName: "",
		password: "",
		confirm: ""
	});
	const [know, setKnow] = useState("");
	const [payment, setPayment] = useState(0);
	const [snackMsg, setMsg] = useState("");
	const [tsWindow, settsWindow] = useState<"Confirm" | "None">("None");
	const [confirmed, setConfirmed] = useState(false);

	const createUser = useMutation({
		mutationKey: ["payments"],
		mutationFn: (body: any) => createStripeUser(body),
		onSuccess: () => {
			console.log("ACCOUNT CREATION SUCCESSFUL!");
			deleteCookie("croi-new-user");
			window.location.href = "/login" + "?success=true";
		},
		onError: (msg: any) => {
			setMsg(msg.message);
		}
	});

	window.onload = () => {
		if (window.location.href.includes("success=true")) {
			createUser.mutate({
				firstName: newUser["croi-new-user"].firstName,
				lastName: newUser["croi-new-user"].lastName,
				email: newUser["croi-new-user"].email,
				password: newUser["croi-new-user"].password,
				learn: newUser["croi-new-user"].learn,
				paymentType: newUser["croi-new-user"].paymentType,
				sessionID: window.location.search.slice(25)
			});
		} else if (window.location.search.includes("cancelled=true")) {
			setFormData({
				email: newUser["croi-new-user"].email,
				firstName: newUser["croi-new-user"].firstName,
				lastName: newUser["croi-new-user"].lastName,
				password: newUser["croi-new-user"].password,
				confirm: newUser["croi-new-user"].password
			});
			setKnow(newUser["croi-new-user"].learn);
			setPayment(newUser["croi-new-user"].payment);
			deleteCookie("croi-new-user");
		}
	};

	const getCheckout = useMutation({
		mutationKey: ["payments"],
		mutationFn: (body: any) => paymentCheckout(body),
		onSuccess: (res) => {
			window.location.assign(res);
		}
	});

	const getProductID = () => {
		switch (payment) {
			case 0:
				return process.env.REACT_APP_PAYMENT_FREE_KEY;
			case 35:
				return process.env.REACT_APP_PAYMENT_ONE_KEY;
			case 19:
				return process.env.REACT_APP_PAYMENT_TWELVE_KEY;
			default:
				return "";
		}
	};

	const checkout = () => {
		if (formData.password != formData.confirm) {
			setMsg("Password mismatch");
		} else if (formData.password.length < 8) {
			setMsg("Please enter a password of at least 8 characters");
		} else if (formData.firstName == "" || formData.lastName == "") {
			setMsg("Please enter your name");
		} else if (formData.email == "") {
			setMsg("Please enter your email");
		} else if (formData.password == "") {
			setMsg("Please enter a password");
		} else if (payment <= 1) {
			setMsg("Please wait as we are creating your account...");
			createUser.mutate({
				firstName: formData.firstName,
				lastName: formData.lastName,
				email: formData.email,
				password: formData.password,
				learn: know,
				paymentType: "Free",
				sessionID: window.location.search.slice(25)
			});
		} else {
			setNewUser("croi-new-user", {
				email: formData.email,
				firstName: formData.firstName,
				lastName: formData.lastName,
				password: formData.password,
				learn: know,
				paymentType: "Individual",
				payment: payment
			});
			getCheckout.mutate({
				productID: getProductID(),
				url: window.location.origin + window.location.pathname
			});
		}
	};

	const isChecked = () => {
		console.log(confirmed);
		if(!confirmed) {
			settsWindow("Confirm")
		} else {
			checkout();
		}
	}

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<NavBar />
			<TermsOfService state={tsWindow} setState={settsWindow} checked={confirmed} setChecked={setConfirmed} setMsg={setMsg} />
			<ContactBox>
				<InfoColumn>
					<h1>Create Your Account</h1>
					<p>Create an account to get started today!</p>
					<InputSection>
						<input
							placeholder="Email"
							type="text"
							value={formData.email}
							onChange={(e) => {
								setFormData({ ...formData, ["email"]: e.target.value });
							}}
						/>
						<NameInputSection>
							<input
								placeholder="First Name"
								type="text"
								value={formData.firstName}
								onChange={(e) => {
									setFormData({ ...formData, ["firstName"]: e.target.value });
								}}
							/>
							<input
								placeholder="Last Name"
								type="text"
								value={formData.lastName}
								onChange={(e) => {
									setFormData({ ...formData, ["lastName"]: e.target.value });
								}}
							/>
						</NameInputSection>
						<p style={{marginBottom: "-3px", marginLeft: "12px"}}>*Please use at least 8 characters in your password*</p>
						<input
							placeholder="Password"
							type="password"
							value={formData.password}
							onChange={(e) => {
								setFormData({ ...formData, ["password"]: e.target.value });
							}}
						/>
						<input
							placeholder="Confirm Password"
							type="password"
							value={formData.confirm}
							onChange={(e) => {
								setFormData({ ...formData, ["confirm"]: e.target.value });
							}}
						/>
						<select onChange={(e) => setKnow(e.target.value)} value={know}>
							<option value={""}>How did you hear about us?</option>
							<option>School</option>
							<option>Private Counselor</option>
							<option>Instagram</option>
							<option>A Friend</option>
							<option>Sporting Event</option>
							<option>Other</option>
						</select>
					</InputSection>
				</InfoColumn>
				<PricingColumn>
					{/*<PricingBoxes>*/}
					{/*<input type="radio" name="Payment" onClick={() => setPayment(0)} />*/}
					{/*<StackedWords>*/}
					{/*	<h4>Free - Limited Access</h4>*/}
					{/*	<p>Free access to learning modules & college match</p>*/}
					{/*	<i>(Payment required for access to ALL tools)</i>*/}
					{/*</StackedWords>*/}
					{/*</PricingBoxes>*/}
					<PriceBoxRow>
						{/*<SpecialCard onClick={() => document.getElementById("Radio35Pay")!.click()}>*/}
						{/*	<input id={"Radio35Pay"} type="radio" name="Payment" onClick={() => setPayment(35)} />*/}
						{/*	<label>1 Month</label>*/}
						{/*	<h4>$35 / Month</h4>*/}
						{/*	<p>Billed Monthly</p>*/}
						{/*	<h5>Lowest Cost</h5>*/}
						{/*</SpecialCard>*/}
						{/*<SpecialCard onClick={() => document.getElementById("Radio19Pay")!.click()}>*/}
						{/*	<input id={"Radio19Pay"} type="radio" name="Payment" onClick={() => setPayment(19)} />*/}
						{/*	<label>1 Year</label>*/}
						{/*	<h4>$19 / Month</h4>*/}
						{/*	<p>Billed Annually</p>*/}
						{/*	<i>46% Savings</i>*/}
						{/*	<h5>Best Value</h5>*/}
						{/*</SpecialCard>*/}
					</PriceBoxRow>
					<PaymentButton onClick={() => isChecked()} disabled={createUser.isLoading}>
						Continue
					</PaymentButton>
				</PricingColumn>
			</ContactBox>
			<Footer />
		</>
	);
}
