import axios, { AxiosResponse } from "axios";
import { supabase } from "../supabase";

const base = process.env.REACT_APP_USER_API_URL;

export const mutator = async (url: string, method: string, body: object) => {
	const axiosInstance = axios.create({
		baseURL: base,
		timeout: 5000
	});

	const idToken = await supabase.auth.getSession();

	return await axiosInstance({
		url: url,
		method,
		headers: { Authorization: `Bearer ${idToken.data.session ? idToken.data.session.access_token : ""}` },
		data: body
	}).then((res: AxiosResponse) => res.data);
};
