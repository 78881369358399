import React, { useState } from "react";
import {
	CalcButton,
	dotStyle,
	handleStyle,
	Importance,
	LargeCard,
	PrefList,
	railStyle,
	SmallCard,
	trackStyle
} from "./Preferences.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { changePreferences } from "../../services/User.services";
import { auth } from "../../firebase";
import preferencesData from "../../atoms/Preferences";
import { queryClient } from "../../services/QueryClient.services";
import { College } from "../../types/College.types";
import { fetchCollegeList } from "../../services/College.services";
import { Snack } from "../../atoms/SnackBarAlert";

type PreferenceType = {
	id: number;
	name: string;
	description: string;
	position: number;
};

export default function PreferenceList() {
	const [state, setState] = useState<PreferenceType[]>(preferencesData);
	const [snackMsg, setMsg] = useState("");

	const getColleges = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList()
	});

	const setPreferences = useMutation({
		mutationKey: ["preferences"],
		mutationFn: (body: any) => changePreferences(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});

	const calculateRankings = async () => {
		await setPreferences.mutateAsync({
			uid: auth.currentUser!.uid,
			costPref: state[0].position,
			salaryPref: state[1].position,
			acceptancePref: state[2].position,
			retentionPref: state[3].position,
			gradPref: state[4].position,
			successPref: 1
		});

		setMsg("Calculating Scores...");
		await queryClient.invalidateQueries(["colleges"]);
		window.scrollBy(0, 1100 - window.scrollY);
	};

	const handleSliderChange = (value: number | number[], id: number) => {
		const isValueArray = Array.isArray(value);

		if (isValueArray) {
			console.log("This is an error: ", value);
		} else {
			const newState = state.map((pref) => {
				if (pref.id === id) {
					pref.position = value;
				}
				return pref;
			});
			setState(newState);
		}
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<LargeCard>
				<h2>My Preferences</h2>
				<i>Use the slider to rank how important each factor is to you in a college decision.</i>
				<Importance>
					<p>Unimportant</p>
					<p>Important</p>
				</Importance>
				<PrefList>
					{state.map((pref) => (
						<SmallCard key={`{Pref${pref.id}}`}>
							<h2>{pref.name}</h2>
							<p>{pref.description}</p>
							<Slider
								dotStyle={dotStyle}
								styles={{ handle: handleStyle, track: trackStyle, rail: railStyle }}
								style={{ width: "90%" }}
								min={1}
								max={5}
								step={1}
								dots
								defaultValue={pref.position}
								onChange={(value) => handleSliderChange(value, pref.id)}
							/>
						</SmallCard>
					))}
				</PrefList>
				<CalcButton onClick={() => calculateRankings()} disabled={getColleges.isLoading || getColleges.isError}>
					Calculate Rankings
				</CalcButton>
			</LargeCard>
		</>
	);
}
