import styled from "styled-components";
import { device } from "../../util/device";

export const FAMaster = styled.div`
	margin-top: 15px;

	h4 {
		font-weight: bold;
		font-size: 28px;
		margin-top: 20px;
		text-align: center;
	}

	.searchBreak {
		border: 10px solid var(--croi-green);
		width: 95%;
		opacity: 100%;
	}

	@media only screen and ${device.mobileM} {
		p,
		h4 {
			text-align: center;
			width: 95%;
		}
	}

	@media only screen and ${device.tablet} {
		p,
		h4 {
			text-align: left;
		}
	}
`;

export const MatchEdit = styled.button`
	@media only screen and ${device.mobileM} {
		width: 100px;
		height: 30px;
		background-color: var(--croi-green);
		color: white;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
	}

	@media only screen and ${device.tablet} {
		width: 100px;
		height: 30px;
		background-color: var(--croi-green);
		color: white;
		border-radius: 10px;
		margin-left: 0;
		margin-right: 0;
	}
`;

export const MatchMeButton = styled.button`
	margin-right: auto;
	margin-left: auto;
	margin-top: 15px;
	display: block;

	@media only screen and ${device.mobileM} {
		background-color: var(--croi-green);
		width: 100%;
		height: 55px;
		font-size: 23px;
		color: white;
		font-weight: bold;
		border-radius: 10px;
	}

	@media only screen and ${device.tablet} {
		background-color: var(--croi-green);
		width: 320px;
		height: 55px;
		font-size: 23px;
		color: white;
		font-weight: bold;
		border-radius: 10px;
	}
`;

export const MatchBoxList = styled.div`
	width: 95%;

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
`;

export const MatchBoxColumn = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
		border: none;
	}

	@media only screen and ${device.tablet} {
		width: 33%;
		//border-right: 3px solid var(--croi-green);
	}
`;

export const NameButtonSection = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: row;

		img {
			height: 30px;
			width: 30px;
			margin-top: 10px;
			margin-left: 1%;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;

		img {
			height: 30px;
			width: 30px;
			margin-top: 10px;
			margin-left: 3%;
		}
	}
`;

export const LocationSection = styled.div`
	width: 100%;

	i {
		margin-top: 2px;
		width: 36%;
		text-align: right;
	}

	h6 {
		width: 50%;
	}

	display: flex;

	img {
		height: 30px;
		width: 30px;
		margin-left: 3%;
	}
`;

export const MatchProfileCircle = styled.div`
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	color: white;
	font-weight: bold;
`;

export const MatchCollegeBox = styled.div`
	hr {
		width: 97%;
		margin-top: 10px;
		margin-bottom: 0;
		border: 1px gray solid;
	}

	position: relative;
	width: 95%;
	margin-left: auto;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		background-color: #f0f0f0;
		border-radius: 10px;
		padding-left: 10px;
		margin-bottom: 20px;
		overflow: visible;

		h6 {
			padding-top: 6px;
		}

		h4 {
			margin-top: 15px;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
			cursor: pointer;
			width: 85%;

			/* Tooltip styling */
			&::before {
				content: attr(data-fulltext);
				visibility: hidden;
				opacity: 0;
				width: 100%;
				background-color: #f0f0f0;
				color: black;
				text-align: center;
				border-radius: 6px;
				position: absolute;
				height: 40px;
				white-space: normal;
				font-size: 15px;
				padding-right: 5px;
			}

			&:hover::before {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	@media only screen and ${device.tablet} {
		height: 280px;
		background-color: #f0f0f0;
		border-radius: 10px;
		padding-left: 10px;
		margin-bottom: 20px;
		overflow: visible;

		h6 {
			padding-top: 6px;
		}

		h4 {
			margin-top: 15px;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			position: relative;
			cursor: pointer;
			width: 85%;

			/* Tooltip styling */
			&::before {
				content: attr(data-fulltext);
				visibility: hidden;
				opacity: 0;
				width: 100%;
				background-color: #f0f0f0;
				color: black;
				text-align: center;
				border-radius: 6px;
				position: absolute;
				height: 40px;
				white-space: normal;
				font-size: 15px;
				padding-right: 5px;
			}

			&:hover::before {
				visibility: visible;
				opacity: 1;
			}
		}
	}
`;

export const PricingInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SpecificPricing = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: row;
		text-align: right;
		justify-content: right;
		padding-right: 20px;

		b {
			padding-left: 5px;
		}
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		text-align: right;
		justify-content: right;
		padding-right: 20px;

		b {
			padding-left: 5px;
		}
	}
`;

export const WindowLeftSide = styled.div`
	@media only screen and ${device.mobileM} {
		border-right: none;
		text-align: center;
		width: 100%;

		p {
			display: block;
			flex-direction: column;

			i {
				margin-right: 0;
				font-size: 12px;
			}

			select {
				width: 100%;
			}
		}
	}

	@media only screen and ${device.tablet} {
		border-right: var(--croi-green) solid 5px;
		text-align: right;
		width: 50%;

		p {
			display: flex;
			flex-direction: row;

			i {
				margin-right: 15px;
				font-size: 20px;
			}

			select {
				width: 50%;
			}
		}
	}
`;

export const WindowRightSide = styled.div`
	@media only screen and ${device.mobileM} {
		i,
		b {
			font-size: 15px;
		}
		width: 100%;
		border-left: none;
	}

	@media only screen and ${device.tablet} {
		i,
		b {
			font-size: 20px;
		}
		width: 50%;
		border-left: var(--croi-green) solid 5px;
	}

	text-align: center;

	b {
		display: flex;
		justify-content: center;
	}
`;

export const CheckboxDiv = styled.div`
	width: 50px;
	height: 50px;
	padding: 0;
	margin: -10px;

	input {
		accent-color: var(--croi-green);
		outline-color: white;
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
	}
`;

export const FinancialRightSide = styled.div`
	display: flex;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;

	@media only screen and ${device.mobileM} {
		flex-direction: column;
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;

		width: 85%;
	}
`;

export const TopSection = styled.div`
	background-color: #eeeeee;
	width: 95%;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 15px;
	display: flex;

	p {
		margin-right: auto;
		margin-left: auto;
		width: 80%;
		display: flex;
		justify-content: space-evenly;
	}

	h3 {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
	}

	input {
		border: 1px black solid;
		padding-left: 10px;
		border-radius: 5px;
		width: 40%;
	}

	select {
		width: 50%;
		font-size: 15px;
		padding-left: 5px;
		border-radius: 5px;
		background-color: white;
		border: 1px black solid;
		height: 30px;
	}

	span {
		display: flex;
	}

	@media only screen and ${device.mobileM} {
		flex-direction: column;

		.select-container {
			width: 100%;
			margin-top: -4px;
			font-size: 15px;
			border-radius: 5px;
		}

		p {
			i {
				font-size: 20px;
				text-align: center;
				width: 100%;
			}
		}

		span {
			margin-right: auto;
			margin-left: auto;
		}
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;

		.select-container {
			width: 50%;
			margin-top: -4px;
			font-size: 15px;
			border-radius: 5px;
		}

		p {
			i {
				font-size: 20px;
				text-align: right;
				width: 40%;
			}
		}

		span {
			margin-left: 0;
			margin-right: 0;
		}
	}
`;

export const LockCollege = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: lightgray;
	top: 0;
	left: 0;
	border-radius: 10px;
	z-index: 10;
	opacity: 95%;
	cursor: pointer;
`;
