import styled from "styled-components";
import { device } from "../../util/device";

export const FilterSection = styled.div`
	background-color: #eeeeee;
	width: 95%;
	padding-bottom: 10px;
	margin-left: auto;
	margin-right: auto;

	display: block;

	@media only screen and ${device.mobileM} {
		border-radius: 0 0 10px 10px;
	}

	@media only screen and ${device.tablet} {
		border-radius: 10px;
	}
`;

export const FilterInputs = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

export const FilterCheckboxes = styled.div`
	width: 500px;
	margin-top: 30px;
	margin-right: 30px;
`;

export const AllFilters = styled.div`
	display: flex;

	@media only screen and ${device.mobileM} {
		flex-wrap: wrap;
	}

	@media only screen and ${device.tablet} {
		flex-wrap: nowrap;
	}
`;

export const FilterBox = styled.div`
	width: 300px;
	margin-top: 10px;

	h6 {
		font-style: italic;
		text-align: center;
		font-size: 22px;
	}

	select {
		width: 90%;
		margin-left: 5%;
		border: 1px black solid;
		border-radius: 5px;
		height: 30px;
		padding-left: 5px;
	}
`;

export const CheckboxBox = styled.div`
	display: flex;
	margin-top: 10px;

	h6 {
		margin-right: 10px;
		width: 100%;
		font-style: italic;
		text-align: right;
		font-size: 22px;
	}
`;

export const ScholarshipTable = styled.table`
	background-color: #eeeeee;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	table-layout: fixed;
	margin-bottom: 10px;

	thead {
		background-color: #d9d9d9;
	}

	th {
		font-weight: bold;
		width: 15%;
	}

	td {
		text-overflow: ellipsis;
		overflow: clip;
		text-wrap: nowrap;
		height: 30px;
		padding: 5px;
		width: auto;
	}

	.tableMessage {
		text-align: center;
		padding: 10px;
	}

	@media only screen and ${device.mobileM} {
		border-radius: 0 0 10px 10px;
	}

	@media only screen and ${device.tablet} {
		border-radius: 10px;
	}
`;

export const MatchMeButton = styled.button`
	margin-right: auto;
	margin-left: auto;
	display: block;
	font-weight: bold;
	font-size: 23px;
	color: white;
	border-radius: 10px;
	margin-top: 15px;
	background-color: var(--croi-green);

	@media only screen and ${device.mobileM} {
		width: 85%;
		height: 30px;
	}

	@media only screen and ${device.tablet} {
		width: 220px;
		height: 50px;
	}
`;

export const ScholarshipEditButton = styled.button`
	background-color: var(--croi-green);
	width: 50px;
	height: 20px;
	font-size: 10px;
	color: white;
	font-weight: bold;
	border-radius: 10px;
	margin-right: auto;
	margin-left: auto;
`;

export const ScholarshipHeader = styled.header`
	@media only screen and ${device.mobileM} {
		background-color: #d9d9d9;
		border-radius: 10px 10px 0 0;
		width: 95%;
		margin-left: auto;
		margin-right: auto;

		h4 {
			margin-bottom: 0;
		}
	}

	@media only screen and ${device.tablet} {
		background-color: transparent;
		width: 100%;

		h4 {
			margin-bottom: 15px;
		}
	}

	margin-top: 15px;
	text-align: center;

	h4 {
		padding-top: 2.5%;
		height: 50px;
		font-weight: bold;
		color: black;
	}

	span {
		cursor: pointer;
		color: var(--croi-green);
	}
`;

export const ScholarshipMessage = styled.div`
	font-style: italic;
	text-align: center;
	width: 95%;
	margin-right: auto;
	margin-left: auto;

	@media only screen and ${device.mobileM} {
		background-color: #eeeeee;
	}

	@media only screen and ${device.tablet} {
		background-color: transparent;
	}
`;
