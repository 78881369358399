import { fetcher } from "./Fetcher.services";
import { LearnWorldsUserProgressResponse, LWData } from "../types/LearnWorlds.types";
import { fetcherBody } from "./FetcherBody.services";

export function fetchStudentProgress(uid: string) {
	return fetcher<LearnWorldsUserProgressResponse>(`/learn/student/${uid}`, "GET");
}

export function fetchLWAccess(body: any) {
	return fetcherBody<string>("learn/access", "POST", body);
}

export function fetchLWProgress() {
	return fetcher<LearnWorldsUserProgressResponse>("/learn/progress", "GET");
}

export function fetchLWData() {
	return fetcher<LWData[]>("learn", "POST");
}
