import ReactCardFlip from "react-card-flip";
import { useState } from "react";

export default function FlipCard(front: JSX.Element, back: JSX.Element) {
	const [flip, setFlip] = useState(false);

	return (
		<ReactCardFlip isFlipped={flip} flipDirection={"horizontal"}>
			<div onClick={() => setFlip(!flip)}>{front}</div>
			<div onClick={() => setFlip(!flip)}>{back}</div>
		</ReactCardFlip>
	);
}
