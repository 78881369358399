import styled from "styled-components";
import { device } from "../../util/device";

export const SalaryMaster = styled.div`
	width: 90%;
	margin-left: 2vw;
	margin-top: 10px;
`;

export const SchoolInfo = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
		margin-right: 5%;
	}

	h6 {
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
	}

	button {
		padding: 5px;
		background-color: var(--croi-green);
		color: white;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const SalarySearch = styled.div`
	background-color: #f0f0f0;
	width: 100%;
	margin-bottom: 25px;
	height: 40px;
	padding-left: 20px;
	border-radius: 10px;

	input {
		background: transparent;
		outline: none;
	}
`;

export const SalarySearchBox = styled.div`
	position: absolute;
	background-color: white;
	border-radius: 10px;
	width: 85%;
	text-align: center;
`;

export const SalarySearchBar = styled.div`
	margin-bottom: 15px;
	width: 82%;
	max-height: 200px;
	overflow: hidden;
	overflow-y: auto;
	margin-left: 2%;
	padding-right: 2%;
`;

export const SalarySearchItem = styled.div`
	line-height: 50px;
	margin-top: 5px;
	border-radius: 5px;
	padding: 5px;
	cursor: pointer;
	min-height: 50px;

	:hover {
		background: #cccccc;
	}
`;

export const CurrentCollege = styled.div`
	background-color: #f0f0f0;
	padding: 15px;
	display: flex;
	border-radius: 10px;
	overflow-x: clip;

	h3 {
		font-weight: bolder;
		text-align: center;
	}

	h5 {
		color: gray;
		font-weight: bold;
		text-align: center;
	}

	h6 {
		font-size: 18px;
		text-align: center;
	}

	table {
		height: auto;
		font-size: 22px;
		overflow-x: auto;

		@media only screen and ${device.mobileM} {
			width: 100%;
			display: block;
		}

		@media only screen and ${device.tablet} {
			width: 60%;
			display: table;
		}

		td {
			text-align: center;
			justify-content: center;
			background-clip: padding-box;
			border-bottom: 15px solid #f0f0f0;
			padding: 5px;
			text-wrap: nowrap;
		}

		th {
			text-wrap: nowrap;
			text-align: center;
		}

		tr {
			background-color: #d9d9d9;
			height: 30px;
			justify-content: center;
			text-align: center;
			margin-bottom: 10px;
		}
	}

	@media only screen and ${device.mobileM} {
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
		min-height: 470px;
	}
`;

export const CardSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	@media only screen and ${device.mobileM} {
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
	}
`;

export const Card = styled.div`
	height: 390px;
	background-color: #f1f1f1;
	border-radius: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 45px;
	text-align: center;
	position: relative;

	p {
		padding-top: 15px;
	}

	@media only screen and ${device.mobileM} {
		width: 95%;
	}

	@media only screen and ${device.tablet} {
		width: 22%;
	}
`;

export const CardHeader = styled.div`
	height: 100px;
	width: 100%;
	background-color: var(--croi-green);
	color: black;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-align: center;
	align-items: center;
	justify-content: center;
	display: flex;
	font-weight: bold;
	font-size: 18px;
`;

export const CardButton = styled.button`
	height: 40px;
	width: 80%;
	color: black;
	background-color: var(--croi-green);
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 10px;
`;

export const DeleteCollegeButton = styled.button`
	border-radius: 50%;
	border: 3px solid white;
	background-color: var(--croi-green);
	color: white;
	height: 30px;
	width: 30px;
	position: absolute;
	top: 5px;
	right: 10px;
`;

export const SelectButton = styled.button`
	height: 35px;
	padding: 5px;
	color: black;
	background-color: var(--croi-green);
	border-radius: 10px;
	font-size: 18px;
`;
