import styled from "styled-components";
import { device } from "../../util/device";

export const TitleSection = styled.div`
	justify-content: center;
	text-align: center;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 100px;

	@media only screen and ${device.mobileM} {
	}

	@media only screen and ${device.tablet} {
	}
`;

export const Title = styled.h2`
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 3em;
	}

	@media only screen and ${device.tablet} {
		font-size: 4.7em;
	}
`;

export const TitleWrapper = styled.div`
	@media only screen and ${device.mobileM} {
		background-size: cover;
		height: 65vh;
	}

	@media only screen and ${device.tablet} {
		background-size: 100%;
		height: 100vh;
	}
`;

export const TitleImage = styled.div`
	@media only screen and ${device.mobileM} {
		display: none;
	}

	@media only screen and ${device.tablet} {
		display: flow;
	}
`;

export const PageText = styled.div`
	color: black;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 15px;
		text-indent: 0;
	}

	@media only screen and ${device.tablet} {
		font-size: 24px;
		text-indent: 50px;
	}
`;

export const StorySection = styled.div`
	margin-bottom: 100px;
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const PageTitle = styled.h1`
	font-size: 10vh;
	color: black;
	text-align: center;
	padding-bottom: 10px;
`;

export const CircleImageBigger = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 225px;
	height: 225px;
	border-radius: 50%;
	padding-bottom: 10px;
`;

export const LinkedInimage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	position: absolute;
	width: 60px;
	height: 60px;
	margin-top: -60px;
	margin-left: 20px;
`;

interface ImageProps {
	imageUrl: string;
}

export const NameCardHeader = styled.h3`
	text-align: center;
	padding-top: 10px;
`;

export const NameCardTitle = styled.h4`
	text-align: center;
	width: 300px;
`;

export const PictureSection = styled.div`
	margin-bottom: 70px;
	display: flex; 
	flex-direction: column;
`;

export const PictureRows = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex; 
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	@media only screen and ${device.tablet} {
		display: flex; 
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		text-align: center;
	}
`;

export const PersonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 30%;
	margin-bottom: 20px;
`;