import styled from "styled-components";
import { device } from "../../util/device";

export const TopWrapper = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
	}
`;

export const LeftSide = styled.div`
	@media only screen and ${device.mobileM} {
	}

	@media only screen and ${device.tablet} {
		height: 700px;
	}
`;

export const PrefList = styled.div`
	height: 500px;
`;
