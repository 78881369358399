import React, { useState } from "react";
import {
	CollegeBox,
	CollegeListBox,
	LargeCard,
	SearchBar,
	SearchBox,
	SearchInputs,
	SearchItem
} from "./CollegeList.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createCollege, fetchCollegeList } from "../../services/College.services";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import { queryClient } from "../../services/QueryClient.services";
import { CollegeEntry } from "../../atoms/CollegeEntry";
import { College } from "../../types/College.types";
import { auth } from "../../firebase";
import { Snack } from "../../atoms/SnackBarAlert";

export default function CollegeList() {
	const { searchResults, setSearchResults } = useFetch();
	const [snackMsg, setMsg] = useState("");

	const getColleges = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList(),
		refetchInterval: false
	});

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createCollege(body),
		onSuccess: (res) => {
			setMsg(res);
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	if (getColleges.isLoading || getColleges.isFetching) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<ScaleLoader color="black" loading={getColleges.isLoading || getColleges.isFetching} />
			</LargeCard>
		);
	}

	if (getColleges.isError) {
		return (
			<LargeCard className="text-center">
				<h2>My College List</h2>
				<span>
					Error: {getColleges.error.message} {getColleges.data}
				</span>
			</LargeCard>
		);
	}

	const listRenderer = getColleges.data.map((p) => {
		let totalAmount = 0;
		p.awards.map((financialAward) => (totalAmount += financialAward.amount));
		return (
			<CollegeBox key={`{College ${p.id}}`}>
				<CollegeEntry key={`{Entry ${p.id}}`} college={p} setMsg={setMsg}></CollegeEntry>
			</CollegeBox>
		);
	});

	const handleItemClick = (collegeId: string) => {
		collegeCreation.mutate({ uid: auth.currentUser?.uid, iped: collegeId });
		setSearchResults({ ...searchResults, slug: "" });
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<LargeCard>
				<h2>My College List</h2>
				<SearchBar>
					<SearchInputs>
						<input
							className="p-2 w-100"
							style={{ border: "1px solid black", borderRadius: "5px" }}
							type="text"
							placeholder={"Search Colleges"}
							value={searchResults.slug}
							onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
						/>
					</SearchInputs>
					{searchResults.slug !== "" && (
						<SearchBox>
							{searchResults.loading && (
								<SearchItem>
									<div style={{ margin: "50px auto 50px auto" }}>
										<ScaleLoader color="black" loading={searchResults.loading} />
									</div>
								</SearchItem>
							)}
							{searchResults.results && (
								<SearchBar>
									{searchResults.results.map((value) => {
										return (
											<SearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
												{value["school.name"]}
											</SearchItem>
										);
									})}
								</SearchBar>
							)}
						</SearchBox>
					)}
				</SearchBar>

				<CollegeListBox>{listRenderer}</CollegeListBox>
			</LargeCard>
		</>
	);
}
