import React, { useState } from "react";
import NavBar from "../../components/Navbar/Navbar";
import { ArticleSection, BackButton, Card, CardButton, CardSection, PageText, SquareImage, SquareImageBigger, Title, TitleSection } from "./Blog.styles";

type articleCard = {
    Title: string;
    Picture: string;
    Key: string;
}

export default function Blog() {
    const articleCards: articleCard[] = [
        {Title: "Preparing for Senior Year: A Parent’s Guide to Deadlines and Decisions", Picture: "images/Preparing.jpg", Key: "preparing"}, 
        {Title: "Understanding 529 Plans: A Valuable Tool for Saving for College", Picture: "images/Plans.jpg", Key: "plans"},
        {Title: "Essential Life Skills Your Teen Should Master Before Heading to College", Picture: "images/Skills.jpg", Key: "skills"}
    ];
    const [selected, setSelected] = useState<string>("");

    const handleRead = (key: string) => {
		setSelected(key);
	};

    const handleBack = () => {
		setSelected("");
	};

    const displayArticle = (key: string) => {
        switch(key) {
            case "preparing":
                return (
                    <ArticleSection>
                        <h1>Preparing for Senior Year: A Parent’s Guide to Deadlines and Decisions</h1>
                        <SquareImageBigger imageUrl={"images/Preparing.jpg"} />
                        <p>
                            Senior year is a pivotal time for high school students and their parents. 
                            It’s filled with significant milestones and decisions that can impact a 
                            student's future. As a parent, your role in helping your child navigate this
                             busy year is crucial. Here's a guide to help you and your teen manage the senior 
                             year effectively, ensuring all important deadlines are met and decisions are well-informed.
                        </p>
                        <h2>Start with a Calendar</h2>
                        <p>Begin by setting up a comprehensive calendar for the year. Include:</p>
                        <ul>
                            <li><strong>College Application Deadlines: </strong>Note both regular decision and early action/decision deadlines.</li>
                            <li><strong>Scholarship Deadlines: </strong>Many scholarships have deadlines in the fall and early winter.
                                Missing these can mean missing out on free money for college.</li>
                            <li><strong>Standardized Tests: </strong>If your teen hasn’t completed their SAT or ACT , or if they plan to
                                retake them, make sure you know the registration and test dates.</li>
                            <li><strong>Financial Aid: </strong>The FAFSA opens on October 1st, and it’s crucial to submit it as early as
                                possible to maximize aid eligibility.</li>
                        </ul>
                        <h2>College Visits and Applications</h2>
                        <p>
                            If not already done during junior year, arrange for college visits early in the senior year. 
                            This can be vital for your child to get a feel for the campus and determine if it’s a good fit. 
                            As for applications:
                        </p>
                        <ul>
                            <li><strong>Make a List: </strong>Help your teen make a final list of colleges to which they will apply. Include
                                a mix of reach, match, and safety schools.</li>
                            <li><strong>Gather Materials: </strong>Organize transcripts, letters of recommendation, essays, and test
                                scores. Each application may have different requirements.</li>
                            <li><strong>Application Portals: </strong>Familiarize yourselves with the Common Application or other
                                application systems used by the chosen colleges.</li>
                        </ul>

                        <h2>Essays and Interviews</h2>
                        <p>College essays and interviews can be intimidating for students. You can support your child by:</p>
                        <ul>
                            <li><strong>Brainstorming Ideas: </strong>Help them brainstorm and choose compelling topics for their college essays.</li>
                            <li><strong>Reviewing Drafts: </strong>Provide feedback on their drafts or encourage them to seek feedback from teachers.</li>
                            <li><strong>Mock Interviews: </strong>Conduct mock interviews at home to prepare them for college interviews, if required.</li>
                        </ul>
                        <h2>Financial Planning</h2>
                        <p>Understanding the financial aspect of college education is critical during this time.</p>
                        <ul>
                            <li><strong>Budgeting: </strong>Discuss and plan the financial implications of attending different colleges, considering tuition, room, board, travel, and other expenses.</li>
                            <li><strong>Financial Aid: </strong>Besides the FAFSA, look into other financial aid forms required by some colleges, like the CSS Profile.</li>
                            <li><strong>Scholarships: </strong>Continuously search for and apply for scholarships. Local scholarships can be less competitive and just as valuable.</li>
                        </ul>
                        <h2>Emotional Support</h2>
                        <p>Senior year can be stressful, and emotional ups and downs are common. Be there to support
                            your child through the stresses of senior year by:</p>
                        <ul>
                            <li><strong>Checking In: </strong>Have regular check-ins to discuss how they’re feeling about school and
                            the application process.</li>
                            <li><strong>Staying Positive: </strong>Keep the morale high by celebrating small victories throughout the
                            year.</li>
                            <li><strong>Encouraging Balance: </strong>Encourage your child to balance academics with time for
                            friends, hobbies, and relaxation.</li>
                        </ul>
                        <h2>Looking Ahead</h2>
                        <p>As acceptance letters start coming in:</p>
                        <ul>
                            <li><strong>Decision Making: </strong>Help your child evaluate their acceptance offers, considering factors
                                like financial aid packages, campus facilities, academic programs, and career
                                opportunities.</li>
                            <li><strong>Graduation Requirements: </strong>Ensure they are on track to meet all graduation
                                requirements and are prepared for final exams.</li>
                        </ul>
                        <p>
                            Senior year is a marathon, not a sprint. By staying organized, proactive, and supportive, you
                            can help make your child’s senior year a successful and memorable experience. Remember,
                            this is a significant year for your child, so while it's important to stay on top of deadlines and
                            requirements, it's equally important to enjoy this special time together.</p>
                        <h2>More Resources</h2>
                        <p>
                            Looking to simplify the college financial planning process? <strong>Croi</strong> is here to help. Our platform
                            provides students and parents with powerful tools to navigate the complexities of college
                            finances. From personalized college cost estimators to detailed financial insights, Croi equips
                            you with the resources you need to make informed decisions. <strong>Discover how Croi can
                            streamline your college planning—visit us today at <a href="www.croi.io">www.croi.io</a> and take the first step
                            towards a brighter educational future.</strong>
                        </p>
                    </ArticleSection>
                )
            case "skills":
                return (
                    <ArticleSection>
                        <h1>Essential Life Skills Your Teen Should Master Before Heading to
                            College</h1>
                        <SquareImageBigger imageUrl={"images/Skills.jpg"} />
                        <p>
                            As your teen prepares to embark on their college journey, it’s not just academic readiness that
                            matters—equipping them with essential life skills is equally crucial for their success and
                            well-being. From managing money to doing laundry, these skills will help your child navigate the
                            new responsibilities and challenges they’ll face while living on their own for the first time. Here’s
                            list of vital life skills that every teen should master before heading off to college.
                        </p>
                        <h2>Financial Literacy</h2>
                        <p><strong>Budgeting: </strong>T each your teen how to manage a budget, including tracking income from part-time
                            jobs or financial aid, and balancing it against expenses such as books, food, and entertainment.</p>
                        <p><strong>Using Credit Wisely: </strong>Explain how credit cards work, the importance of paying balances on
                            time, and the consequences of debt.</p>
                        <p><strong>Saving: </strong>Encourage habits of saving a portion of any money they receive or earn, which can
                            help in emergencies or for larger purchases.</p>
                        <h2>Cooking and Nutrition</h2>
                        <p><strong>Basic Cooking Skills: </strong>Knowing how to prepare simple, healthy meals can save money and
                            help your teen maintain a balanced diet. T each them a few easy recipes that don’t require
                            extensive ingredients or tools.</p>
                        <p><strong>Grocery Shopping: </strong>Show them how to shop economically and nutritiously, including reading
                            labels, comparing prices, and sticking to a shopping list.</p>
                        <h2>Laundry and Clothing Maintenance</h2>
                        <p><strong>Doing Laundry: </strong>Teach your teen how to sort laundry, select proper wash settings, and treat
                            stains. Also, explain the importance of regular laundering to maintain clothing quality.</p>
                        <p><strong>Basic Sewing: </strong>Handling minor repairs like sewing a button or fixing a torn seam can extend the
                            life of their clothing and save money.</p>
                        <h2>Time Management</h2>
                        <p><strong>Prioritizing Tasks: </strong>Help your teen learn to prioritize tasks by urgency and importance, a skill
                            that will be critical in managing coursework and other commitments.</p>
                        <p><strong>Using Planners or Digital Tools: </strong>Introduce them to tools that can help manage their
                            schedules, such as planners, calendars, or smartphone apps.</p>
                        <h2>Cleaning and Organizing</h2>
                        <p><strong>First Aid and Health Care: </strong>Basic knowledge of first aid, along with understanding how to
                            access healthcare services on campus, is important for any emergencies that might arise.</p>
                        <p><strong>Personal Safety: </strong>Discuss ways to stay safe on campus, including understanding safety
                            services available at the college, safe practices when out at night, and the importance of being
                            aware of their surroundings.</p>
                        <h2>Cleaning and Organizing</h2>
                        <p><strong>Keeping Spaces Clean: </strong>Teach your teen the basics of keeping living spaces clean and
                            organized, which is crucial for both hygiene and mental well-being.</p>
                        <p><strong>Routine Maintenance: </strong>Show them how to perform simple maintenance tasks like changing a
                            lightbulb or unclogging a drain.</p>
                        <h2>Communication and Interpersonal Skills</h2>
                        <p><strong>Effective Communication: </strong>Encourage your teen to express their needs and concerns clearly,
                            and to listen actively and empathetically in conversations.</p>
                        <p><strong>Conflict Resolution: </strong>Help them understand the importance of resolving disagreements
                            constructively, without escalating tensions.</p>
                        <p>Equipping your teen with these life skills can boost their confidence and independence as they
                            step into a more autonomous phase of their life. College is not just a test of their academic
                            abilities but a broader challenge that requires practical, everyday skills to navigate effectively. By
                            preparing them with these essentials, you’re not just helping them survive their college years but
                            thrive during and beyond.</p>
                        <h2>More Resources</h2>
                        <p>
                            To further support your teen's transition to college, <strong>Croi</strong> offers comprehensive tools and
                            resources designed to streamline the college financial planning process. Visit www.croi.io to
                            learn more about how we can help simplify the complexities of college costs and financial aid,
                            empowering your teen to focus on what matters most - growing into a successful, independent
                            adult.</p>
                    </ArticleSection>
                )
            case "plans":
                return (
                    <ArticleSection>
                        <h1>Understanding 529 Plans: A Valuable Tool for Saving for College</h1>
                        <SquareImageBigger imageUrl={"images/Plans.jpg"} />
                        <p>
                            Saving for college can be daunting, but 529 plans offer a straightforward and beneficial way to
                            prepare for future education costs. Here's a simple guide to understanding how 529 plans can
                            help you manage the financial challenge of funding education.
                        </p>
                        <h2>What are 529 Plans?</h2>
                        <p>529 plans are special savings accounts that you can use to pay for education. They come in two
                        types:</p>
                        <ul>
                            <li><strong>Savings Plans: </strong>These work a bit like retirement accounts, where you put money in,
                                choose how to invest it, and watch it grow over time. You can use the money later to pay
                                for college expenses.</li>
                            <li><strong>Prepaid Tuition Plans: </strong>With these, you can pay for college years in advance, locking in
                                today's tuition rates, which can save you money as college costs rise.</li>
                        </ul>
                        <h2>Tax Benefits</h2>
                        <p>One of the big perks of 529 plans is how they handle taxes. The money you put in grows without
                            being taxed, and when you take it out to pay for school costs, you don’t pay taxes on it. Many
                            states also give you a bonus, like a tax break, when you put money into a 529 plan.</p>
                        <h2>How Much Can You Put In?</h2>
                        <p>You can start small, with as little as $25 a month, and you can save a lot over time—up to
                            $300,000 in some plans. This makes it easy for anyone to start saving, whether you can put
                            away a little or a lot.</p>
                        <h2>What Can You Use the Money For?</h2>
                        <p>Originally, 529 plans were just for paying for college tuition. Now, you can use them for other
                            college costs like room and board, books, and other school supplies. They're also not just for
                            college—you can use up to $10,000 per year for elementary and high school tuition, too.</p>
                        <h2>Choosing Investments</h2>
                        <p>Most 529 plans offer a range of ways to invest your money, from safe choices that don’t change
                            much to riskier ones that can grow faster. Some plans automatically adjust your investments to
                            become safer as the time for college gets closer.</p>
                        <h2>Things to Think About</h2>
                        <ul>
                            <li><strong>Investment Risk: </strong>The value of your investments can go up or down, depending on how
                            the markets do.</li>
                            <li><strong>Control: </strong>You don’t pick the exact investments—that’s done by the plan managers based
                            on your choice of an investment option.</li>
                            <li><strong>Fees for Non-education Use: </strong>If you take out money for things other than education,
                            you’ll have to pay taxes and a 10% fee on the earnings.</li>
                        </ul>
                        <h2>Is a 529 Plan Right for You?</h2>
                        <p>A 529 plan can be a great choice if you're looking for a tax-friendly way to save for education.
                            They’re flexible, offer good tax benefits, and you can use the money for a wide range of
                            educational expenses. Before you decide, think about your budget, how much you can save,
                            and talk to a financial advisor if you need more guidance.
                            By starting early and planning wisely with a 529 plan, you can make a big difference in
                            managing the cost of education and reduce financial stress later on.</p>
                        <h2>More Resources</h2>
                        <p>Looking to simplify the college financial planning process? <strong>Croi</strong> is here to help. Our platform
                            provides students and parents with powerful tools to navigate the complexities of college
                            finances. From personalized college cost estimators to detailed financial insights, Croi equips
                            you with the resources you need to make informed decisions. <strong>Discover how Croi can
                            streamline your college planning—visit us today at <a href="www.croi.io">www.croi.io</a> and take the first step
                            towards a brighter educational future.</strong></p>
                    </ArticleSection>
                )
        }
    };

    return (
        selected == "" ? (
            <>
                <NavBar />
                <TitleSection>
                    <Title>Blog Library</Title>
                    <PageText style={{textAlign: "center"}}>
                        Informative articles for students and parents selecting colleges.
                    </PageText>
                </TitleSection>
                <CardSection>
                    {articleCards.map((doc) => {
                        return (
                            <Card key={doc.Key}>
                                <p>{doc.Title}</p>
                                <SquareImage imageUrl={doc.Picture} />
                                <CardButton onClick={() => handleRead(doc.Key)}>READ</CardButton>
                            </Card>
                        );
                    })}
                </CardSection>
            </>
        ) : (
            <>
                <NavBar />
                <BackButton onClick={() => handleBack()}>{"\u2190 Back"}</BackButton>
                {displayArticle(selected)}
            </>
        )
    );
}