import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../services/QueryClient.services";
import { FinancialBackdrop, FinancialWindow, TitleWButton } from "./FinancialProfileWindow.styles";
import { User } from "../../types/User.types";
import { changeFinances } from "../../services/User.services";

export default function FinancialProfileWindow(props: {
	user: User;
	state: "Profile" | "None";
	setState: any;
	setMsg: any;
}) {
	const [user] = useState<User>(props.user);
	const year = new Date().getFullYear() - 2;

	const editProfile = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => changeFinances(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
			props.setState("None");
		}
	});

	const save = () => {
		const body = {
			uid: props.user.id,
			parentIncome: user.parentIncome > -1 ? user.parentIncome : 0,
			taxPaid: user.taxPaid > -1 ? user.taxPaid : 0,
			untaxedIncome: user.untaxedIncome > -1 ? user.untaxedIncome : 0,
			parentAssets: user.parentAssets > -1 ? user.parentAssets : 0,
			studentIncome: user.studentIncome > -1 ? user.studentIncome : 0,
			numParents: user.numParents > -1 ? user.numParents : 2,
			familySize: user.familySize > -1 ? user.familySize : 3,
			oldestParent: user.oldestParent > -1 ? user.oldestParent : 34,
			studentAssets: user.studentAssets > -1 ? user.studentAssets : 0
		};

		editProfile.mutate(body);
	};

	const closeWindow = () => {
		props.setState("None");
	};

	if (props.state === "Profile") {
		return (
			<>
				<FinancialBackdrop />
				<FinancialWindow>
					<TitleWButton>
						<button onClick={closeWindow}>X</button>
						<h1>Financial Aid Profile</h1>
					</TitleWButton>
					<h2>croi</h2>
					<h3>My Family Finances</h3>
					<p>
						<i>Parent Combined Income ({year}):</i>
						<input
							defaultValue={user.parentIncome > -1 ? user.parentIncome : 0}
							type={"number"}
							onChange={(e) => {
								user.parentIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Federal/State Taxes Paid ({year}):</i>
						<input
							defaultValue={user.taxPaid > -1 ? user.taxPaid : 0}
							type={"number"}
							onChange={(e) => {
								user.taxPaid = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Untaxed Income ({year}):</i>
						<input
							defaultValue={props.user.untaxedIncome > -1 ? props.user.untaxedIncome : 0}
							type={"number"}
							onChange={(e) => {
								props.user.untaxedIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Current Value of Parent's Assets:</i>
						<input
							defaultValue={user.parentAssets > -1 ? props.user.parentAssets : 0}
							type={"number"}
							onChange={(e) => {
								user.parentAssets = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Current Value of Student's Assets:</i>
						<input
							defaultValue={user.studentAssets > -1 ? user.studentAssets : 0}
							type={"number"}
							onChange={(e) => {
								user.studentAssets = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<p>
						<i>Student Income ({year}):</i>
						<input
							defaultValue={user.studentIncome > -1 ? user.studentIncome : 0}
							type={"number"}
							onChange={(e) => {
								user.studentIncome = e.target.valueAsNumber;
							}}
							min={0}
							step={0.01}
						/>
					</p>
					<br />
					<p>
						<i>Number of Parents:</i>
						<input
							defaultValue={user.numParents > -1 ? user.numParents : 2}
							type={"number"}
							onChange={(e) => {
								user.numParents = e.target.valueAsNumber;
							}}
							min={0}
							max={10}
							step={1}
						/>
					</p>
					<p>
						<i>Family Size:</i>
						<input
							defaultValue={user.familySize > -1 ? user.familySize : 3}
							type={"number"}
							onChange={(e) => {
								user.familySize = e.target.valueAsNumber;
							}}
							min={1}
							max={30}
							step={1}
						/>
					</p>
					<p>
						<i>Oldest Parent's Age:</i>
						<input
							defaultValue={user.oldestParent > -1 ? user.oldestParent : 34}
							type={"number"}
							onChange={(e) => {
								user.oldestParent = e.target.valueAsNumber;
							}}
							min={30}
							max={120}
							step={1}
						/>
					</p>
					<button onClick={() => save()} disabled={editProfile.isLoading}>
						Save and Continue
					</button>
				</FinancialWindow>
			</>
		);
	} else {
		return <></>;
	}
}
