import styled from "styled-components";
import React from "react";

export default function CroiCheckbox(props: { setter: any; value: boolean; size: number }) {
	const CustomCheckbox = styled.label`
		/* Customize the label (the container) */
		display: block;
		position: relative;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		height: ${props.size + 2}px;
		width: ${props.size + 2}px;
		z-index: 0;

		/* Hide the browser's default checkbox */

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		/* Create a custom checkbox */

		.checkmark {
			position: absolute;
			top: 0;
			right: 0;
			height: ${props.size}px;
			width: ${props.size}px;
			background-color: white;
			border: black solid 1px;
			border-radius: 5px;
		}

		/* On mouse-over */

		:hover input ~ .checkmark {
			background-color: white;
		}

		/* When the checkbox is checked */

		input:checked ~ .checkmark {
			background-color: white;
		}

		/* Create the checkmark/indicator (hidden when not checked) */

		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}

		/* Show the checkmark when checked */

		input:checked ~ .checkmark:after {
			display: block;
		}

		/* Style the checkmark/indicator */

		.checkmark:after {
			left: ${props.size / 3}px;
			top: ${props.size / 7}px;
			width: ${props.size / 3}px;
			height: ${props.size / 2 + 3}px;
			border: solid var(--croi-green);
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	`;

	return (
		<CustomCheckbox>
			<input checked={props.value} type={"checkbox"} onChange={() => props.setter(!props.value)} />
			<span className={"checkmark"}></span>
		</CustomCheckbox>
	);
}
