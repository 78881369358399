import { auth } from "../../firebase";
import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchSponsor, fetchUser, upgradeUser } from "../../services/User.services";
import {
	CustomerService,
	DropButton,
	FreeMessage,
	Hamburger,
	Logo,
	MobileMessage,
	NavBarContainer,
	Option,
	ProfileButton,
	ProfileOptions,
	ProgressBar,
	ProgressButton,
	SidebarContainer,
	Sponsorship,
	UserToolsMaster
} from "./UserTools.styles";
import { sidebarOptions } from "../../util/SidebarOptions";
import Dashboard from "../Dashboard/Dashboard";
import CollegeDecisionTool from "../CollegeDecisionTool/CollegeDecisionTool";
import SalaryInsights from "../SalaryInsights/SalaryInsights";
import StudentLoan from "../StudentLoan/StudentLoan";
import { LearnWorldsUserProgressResponse } from "../../types/LearnWorlds.types";
import { fetchLWProgress } from "../../services/Learn.services";
import FinancialAidMatch from "../FinancialAidMatch/FinancialAidMatch";
import dashLogo from "../../atoms/sidebarLogos/DashboardLogo.svg";
import learnLogo from "../../atoms/sidebarLogos/Learn.svg";
import matchLogo from "../../atoms/sidebarLogos/Match.svg";
import analyzeLogo from "../../atoms/sidebarLogos/Analyze.svg";
import decideLogo from "../../atoms/sidebarLogos/CollegeLogo.svg";
import financeLogo from "../../atoms/sidebarLogos/Finance.svg";
import { fetchCollegeList } from "../../services/College.services";
import LearnNav from "../LearnNav/LearnNav";
import UserSettings from "../UserSettings/UserSettings";
import { AdminPage } from "../AdminPage/AdminPage";
import { College } from "../../types/College.types";
import ScholarshipMatch from "../ScholarshipMatch/ScholarshipMatch";
import { Scholarship } from "../../types/Scholarship.types";
import { fetchScholarshipList } from "../../services/Scholarship.service";
import { Sponsor } from "../../types/Sponsor.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Snack } from "../../atoms/SnackBarAlert";
import { queryClient } from "../../services/QueryClient.services";
import SettingsWindow from "../../components/SettingsWindow/SettingsWindow";
import CostNav from "../CostNav/CostNav";
import HowToButton from "../../atoms/HowToButton";
import { loan } from "../../types/Loan.types";
import { fetchLoanList } from "../../services/Loan.services";
import AdminView from "../AdminPage/AdminView";
import { supabase } from "../../supabase";

export default function UserTools() {
	const [tool, setTool] = useState("Dashboard");
	const [showDropDown, setShowDropDown] = useState(false);
	const [snackMsg, setMsg] = useState("");
	const [upgradeWindow, setWindow] = useState(false);
	const [studentID, setUid] = useState("");

	const howToVideoLinks: Record<string, string> = {
		Croi: "https://www.youtube.com/embed/GKycBov926A?si=gCkSyRJbhYLXuOkI",
		Decide: "https://youtu.be/-WlcSpPEQKM?si=tIDrAzpHMzrGeY0v",
		Match: "https://www.youtube.com/watch?v=XAI3lFjqDwY",
		Learn: "https://www.youtube.com/watch?v=JeTw0ChvTFs&list=PLbQ3MWBGtj7t3Y0VYxxjuTWzS2lQnRCSk&index=1",
		Finance: "https://youtu.be/2KPNXdk8i_E?si=Ho-ZgnkjSsIN_ZXf",
		Analyze: "https://youtu.be/qVmsRepLvJc?si=z9383SiEb4bbvNoV"
	};

	function getLink(linkKey: string): string {
		if (linkKey.includes("-")) {
			const normalizedKey = linkKey.split("-")[0];
			return howToVideoLinks[normalizedKey];
		} else if (howToVideoLinks[linkKey] == undefined) {
			return howToVideoLinks["Croi"];
		}
		return howToVideoLinks[linkKey];
	}

	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(),
		refetchInterval: false
	});

	const getLWData = useQuery<LearnWorldsUserProgressResponse, Error>({
		queryKey: ["LearnWorlds"],
		queryFn: () => fetchLWProgress(),
		refetchInterval: false,
		enabled: getUser.data ? getUser.data.lwUID.length > 0 : false
	});

	const getColleges = useQuery<College[], Error>({
		queryKey: ["colleges"],
		queryFn: () => fetchCollegeList(),
		refetchInterval: false
	});

	const getScholarships = useQuery<Scholarship[], Error>({
		queryKey: ["scholarships"],
		queryFn: () => fetchScholarshipList(),
		refetchInterval: false
	});

	const getSponsor = useQuery<Sponsor, Error>({
		queryKey: ["sponsor"],
		queryFn: () => fetchSponsor(),
		enabled: getUser.isSuccess && getUser.data.sponsorID.length > 0
	});

	const getLoans = useQuery<loan[], Error>({
		queryKey: ["loans"],
		queryFn: () => fetchLoanList(),
		refetchInterval: false
	});

	const [analyzeDropDown, setAnalyzeDropdown] = useState(false);
	const [financeDropDown, setFinanceDropdown] = useState(false);
	const [isOpen, setIsOpen] = useState(true);

	const freeToPaid = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => upgradeUser(body),
		onSuccess: () => {
			setMsg("Successfully upgraded plan!");
			queryClient.invalidateQueries(["users"]);
		}
	});

	const SideBar = () => {
		return (
			<>
				<Hamburger onClick={() => setIsOpen(!isOpen)}>
					<div></div>
					<div></div>
					<div></div>
				</Hamburger>
				<SidebarContainer style={{ display: isOpen ? "block" : "none" }}>
					<Logo onClick={() => setTool("Dashboard")}>
						<img alt={"croi logo"} src={"images/logo_green.svg"}></img>
						croi
					</Logo>

					<Option className={tool === "Dashboard" ? "selected" : ""} onClick={() => setTool("Dashboard")}>
						<img alt={"Dash Logo"} src={dashLogo} />
						Dashboard
					</Option>
					<Option className={tool === "Learn" ? "selected" : ""} onClick={() => setTool("Learn")}>
						<img alt={"Learn Logo"} src={learnLogo} />
						Learn
					</Option>
					<Option
						className={tool === "Match" ? "selected" : ""}
						onClick={() => {
							setTool("Match");
						}}>
						<img alt={"Match Logo"} src={matchLogo} />
						Match
						<b>BETA</b>
					</Option>
					<Option className={analyzeDropDown ? "dropdown" : ""}>
						<div
							onClick={() => {
								setAnalyzeDropdown(!analyzeDropDown);
							}}>
							<img alt={"Analyze Logo"} src={analyzeLogo} />
							Analyze
							{analyzeDropDown ? (
								<DropButton
									alt={"expand"}
									src={"images/expand_more.svg"}
									style={{
										rotate: "180deg"
									}}
								/>
							) : (
								<DropButton alt={"expand"} src={"images/expand_more.svg"} />
							)}
						</div>
						{analyzeDropDown ? (
							<ul>
								<li className={tool === "Analyze-Cost" ? "selected" : ""} onClick={() => setTool("Analyze-Cost")}>
									Cost Navigator
								</li>
								<li className={tool === "Analyze-Salary" ? "selected" : ""} onClick={() => setTool("Analyze-Salary")}>
									Salary Insights
								</li>
							</ul>
						) : (
							<></>
						)}
					</Option>
					<Option
						className={tool === "Decide" ? "selected" : ""}
						onClick={() => {
							setTool("Decide");
						}}>
						<img alt={"Decide Logo"} src={decideLogo} />
						Decide
					</Option>
					<Option className={financeDropDown ? "dropdown" : ""}>
						<div
							onClick={() => {
								setFinanceDropdown(!financeDropDown);
							}}>
							<img alt={"Finance Logo"} src={financeLogo} />
							Finance
							{financeDropDown ? (
								<DropButton
									alt={"expand"}
									src={"images/expand_more.svg"}
									style={{
										rotate: "180deg"
									}}
								/>
							) : (
								<DropButton alt={"expand"} src={"images/expand_more.svg"} />
							)}
						</div>
						{financeDropDown ? (
							<ul>
								<li className={tool === "Finance-Loan" ? "selected" : ""} onClick={() => setTool("Finance-Loan")}>
									Student Loan
								</li>
								<li
									className={tool === "Finance-Scholarship" ? "selected" : ""}
									onClick={() => setTool("Finance-Scholarship")}>
									Scholarship
								</li>
							</ul>
						) : (
							<></>
						)}
					</Option>

					{getUser.data!.sponsorID === "Cornerstone Bank" ? (
						<Sponsorship href={"https://cornerstonebank.com/student/"}>
							This account is sponsored by:
							<img alt={"Cornerstone"} src={"/images/CornerStone.png"}></img>
						</Sponsorship>
					) : getUser.data!.paymentType === "Free" ? (
						<FreeMessage>
							FREE ACCOUNT
							<br />
							<button onClick={() => setWindow(true)}>
								Upgrade to Premium&nbsp;
								<img
									src={"/images/CroiPremium.svg"}
									alt={"Croi Premium Logo"}
									style={{
										width: "10%"
									}}
								/>
							</button>
						</FreeMessage>
					) : (
						<></>
					)}
				</SidebarContainer>
			</>
		);
	};

	const signOutButton = () => {
		supabase.auth
			.signOut()
			.then(() => {
				window.location.replace(window.location.origin);
			})
			.catch((error) => {
				setMsg(error.message);
			});
	};

	const options = ["Settings", getUser.data?.isAdmin ? "Admin" : "", "Logout"];

	const optionSelection = (option: string): void => {
		if (option === "Logout") {
			signOutButton();
		}

		setTool(option);
	};

	const optionMapper = options.map((option: string) =>
		option.length > 0 ? (
			<ProfileOptions
				onClick={(): void => {
					optionSelection(option);
				}}>
				{option}
			</ProfileOptions>
		) : (
			<></>
		)
	);

	const NavBar = () => {
		return (
			<NavBarContainer style={{ width: isOpen ? "83vw" : "97vw", marginLeft: isOpen ? "17vw" : "3vw" }}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex" }}>
						<h2>{sidebarOptions.find((option) => option.sideTitle === tool)?.navTitle}</h2>
						<HowToButton text={"How To"} link={getLink(tool)} />
					</div>
					<div style={{ display: "flex" }}>
						<CustomerService>
							<b>Customer Service</b>
							<address>
								<FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "5px" }} />
								<a href="mailto: help@croi.io">help@croi.io</a>
							</address>
						</CustomerService>
						<ProfileButton
							onClick={() => setShowDropDown(!showDropDown)}
							onBlur={() => setShowDropDown(false)}
							style={{
								backgroundImage: auth.currentUser?.photoURL ? `url(${auth.currentUser.photoURL})` : ""
							}}>
							{showDropDown ? optionMapper : <></>}
						</ProfileButton>
					</div>
				</div>
				<ProgressBar>
					<ProgressButton onClick={() => setTool("Learn")} className={tool === "Learn" ? "selected" : ""}>
						<p>1</p>
						Learn
					</ProgressButton>
					<FontAwesomeIcon icon={faArrowRight} className={"arrow"} />
					<ProgressButton onClick={() => setTool("Match")} className={tool === "Match" ? "selected" : ""}>
						<p>2</p>
						Match
					</ProgressButton>
					<FontAwesomeIcon icon={faArrowRight} className={"arrow"} />
					<ProgressButton
						onClick={() => setTool("Analyze-Cost")}
						className={tool.includes("Analyze") ? "selected" : ""}>
						<p>3</p>
						Analyze
					</ProgressButton>
					<FontAwesomeIcon icon={faArrowRight} className={"arrow"} />
					<ProgressButton onClick={() => setTool("Decide")} className={tool === "Decide" ? "selected" : ""}>
						<p>4</p>
						Decide
					</ProgressButton>
					<FontAwesomeIcon icon={faArrowRight} className={"arrow"} />
					<ProgressButton
						onClick={() => setTool("Finance-Loan")}
						className={tool.includes("Finance") ? "selected" : ""}>
						<p>5</p>
						Finance
					</ProgressButton>
				</ProgressBar>
			</NavBarContainer>
		);
	};

	const selectedTool = () => {
		switch (tool) {
			case "Dashboard":
				return <Dashboard setTool={setTool} learn={getLWData.data} />;
			case "Learn":
				return <LearnNav data={getLWData.data!} />;
			case "Analyze-Cost":
				return <CostNav colleges={getColleges.data!} user={getUser.data!} />;
			case "Analyze-Salary":
				return <SalaryInsights colleges={getColleges.data!} />;
			case "Decide":
				return <CollegeDecisionTool colleges={getColleges.data!} user={getUser.data!} />;
			case "Finance-Loan":
				return <StudentLoan colleges={getColleges.data!} loans={getLoans.data!} />;
			case "Match":
				return <FinancialAidMatch colleges={getColleges.data!} user={getUser.data!} />;
			case "Settings":
				return <UserSettings user={getUser.data!} sponsor={getSponsor.data} />;
			case "Admin":
				return <AdminPage admin={getUser.data!} setTool={setTool} setUid={setUid} />;
			case "Admin-Student":
				return <AdminView uid={studentID} setTool={setTool} />;
			case "Finance-Scholarship":
				return <ScholarshipMatch scholarships={getScholarships.data!} user={getUser.data!} />;
		}
	};

	const loadingData = () => {
		return (
			getUser.isInitialLoading ||
			getLWData.isInitialLoading ||
			getColleges.isInitialLoading ||
			getLoans.isInitialLoading
		);
	};

	const [mobileMessage, setMobileMessage] = useState(window.outerWidth <= 412);
	const initialMobileMessage = () => {
		return (
			<MobileMessage>
				<p>
					<b>
						<i>Welcome to Croi!</i>
					</b>
					<br />
					For the best experience, we recommend using a laptop or desktop browser.
				</p>
				<button onClick={() => setMobileMessage(false)}>Continue to Croi</button>
				<p>Croi will be available as an app soon!</p>
			</MobileMessage>
		);
	};

	return !loadingData() ? (
		<>
			{mobileMessage ? initialMobileMessage() : <></>}
			<SideBar />
			<NavBar />
			<Snack msg={snackMsg} setMsg={setMsg} />
			<UserToolsMaster style={{ width: isOpen ? "83vw" : "97vw", marginLeft: isOpen ? "17vw" : "3vw" }}>
				{selectedTool()}
			</UserToolsMaster>
			{upgradeWindow ? (
				<SettingsWindow type={"UPGRADE"} setWindow={setWindow} user={getUser.data!} setMsg={setMsg} />
			) : (
				<></>
			)}
		</>
	) : (
		<div
			style={{ textAlign: "center", marginTop: "25vh", fontSize: "larger", fontWeight: "bold" }}
			onLoad={() => {
				if (window.location.href.includes("success=true")) {
					freeToPaid.mutate({
						sessionID: window.location.search.slice(25)
					});
				} else if (window.location.search.includes("cancelled=true")) {
					setMsg("Payment cancelled");
				}
			}}>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<img src={"/images/LoadingAnimation.gif"} alt={"loading"} width={"180px"} />
			<br />
			Loading User Data...
		</div>
	);
}
