import styled from "styled-components";
import { device } from "../../util/device";

export const SettingsWrapper = styled.div`
	h1 {
		font-weight: bold;
		text-align: center;
		margin-top: 25px;
		margin-bottom: 25px;
	}
`;

export const AreaOfChange = styled.div`
	background-color: #eeeeee;
	border-radius: 15px;
	width: 90%;
	margin-left: 5%;
	margin-bottom: 15px;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 25px;

	h2,
	h4 {
		font-weight: bold;
	}

	h4 {
		margin-top: 10px;
	}

	p {
		font-weight: bold;
		color: #5c636a;
		margin-bottom: 4px;
	}

	button,
	a {
		text-decoration: underline;
		background: none;
		margin-bottom: 4px;
		color: black;
	}

	@media only screen and ${device.mobileM} {
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		flex-direction: row;
	}
`;

export const ChangePictureLabel = styled.label`
	color: black;
	text-decoration: underline;
	cursor: pointer;
	text-align: center;
	width: 100%;

	input {
		display: none;
	}
`;

export const LeftSide = styled.div`
	@media only screen and ${device.mobileM} {
		width: 100%;
	}

	@media only screen and ${device.tablet} {
		width: 400px;
	}
`;

export const ProfileCircle = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	border-radius: 50%;
	margin-bottom: 10px;
	border: 5px solid var(--croi-green);
	margin-right: auto;
	margin-left: auto;

	@media only screen and ${device.mobileM} {
		width: 200px;
		height: 200px;
	}

	@media only screen and ${device.tablet} {
		width: 250px;
		height: 250px;
	}
`;

interface ImageProps {
	imageUrl: string;
}
