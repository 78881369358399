import React from "react";
import { CheckSave, FinancialBackdrop, FinancialWindow, TitleWButton } from "./TermsOfService.styles";

export default function TermsOfService(props: {state: "Confirm" | "None"; setState: any; checked: boolean; setChecked: any; setMsg: any;}) {

	const closeWindow = () => {
		props.setState("None");
	};

	const handleCheckboxChange = () => {
        props.setChecked(!props.checked)
    };

	if (props.state === "Confirm") {
		return (
			<>
				<FinancialBackdrop />
				<FinancialWindow>
					<TitleWButton>
						<button onClick={closeWindow}>X</button>
						<h1>Financial Aid Profile</h1>
					</TitleWButton>
					<iframe 
						src="images/2024.11.19. Terms of Service - CROI Inc..pdf" 
						width="100%" 
						height="500px" 
						title="PDF Viewer"
						style={{ border: '1px solid #ccc' }}>
					</iframe>
					<CheckSave>
						<label>
							<input 
								type="checkbox" 
								checked={props.checked} 
								onChange={handleCheckboxChange}/>
						</label>
						<button hidden={!props.checked} onClick={closeWindow}>
							Save and Continue
						</button>
					</CheckSave>
				</FinancialWindow>
			</>
		);
	} else {
		return <></>;
	}
}
