import React, { useEffect, useState } from "react";
import { Criteria, CriteriaBox, CriteriaContainer } from "./MyCriteria.styles";
import { useMutation } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { changePreferences } from "../../services/User.services";
import Select, { MultiValue } from "react-select";
import { queryClient } from "../../services/QueryClient.services";

interface valueLabel {
	value: string;
	label: string;
}

const lifeChoices: valueLabel[] = [
	{
		value: "",
		label: "---"
	},
	{
		value: "Band",
		label: "Band"
	},
	{
		value: "Campus Ministries",
		label: "Campus Ministries"
	},
	{
		value: "Campus Newspaper",
		label: "Campus Newspaper"
	},
	{
		value: "Chorus Group",
		label: "Chorus Group"
	},
	{
		value: "Concert Band",
		label: "Concert Band"
	},
	{
		value: "Dance",
		label: "Dance"
	},
	{
		value: "Drama/Theater Group",
		label: "Drama/Theater Group"
	},
	{
		value: "Film Society",
		label: "Film Society"
	},
	{
		value: "Greek Life",
		label: "Greek Life"
	},
	{
		value: "International Student Organization",
		label: "International Student Organization"
	},
	{
		value: "Jazz Band",
		label: "Jazz Band"
	},
	{
		value: "Literary Magazine",
		label: "Literary Magazine"
	},
	{
		value: "Model UN",
		label: "Model UN"
	},
	{
		value: "Musical Theater",
		label: "Musical Theater"
	},
	{
		value: "Music Ensemble",
		label: "Music Ensemble"
	},
	{
		value: "Opera",
		label: "Opera"
	},
	{
		value: "Pep Band",
		label: "Pep Band"
	},
	{
		value: "Student Government",
		label: "Student Government"
	},
	{
		value: "Student Radio",
		label: "Student Radio"
	},
	{
		value: "Student TV",
		label: "Student TV"
	},
	{
		value: "Symphony Orchestra",
		label: "Symphony Orchestra"
	},
	{
		value: "Yearbook Club",
		label: "Yearbook Club"
	}
];

const typeChoices: valueLabel[] = [
	{
		value: "Public",
		label: "Public"
	},
	{
		value: "Private",
		label: "Private"
	},
	{
		value: "Catholic",
		label: "Catholic"
	},
	{
		value: "Jewish",
		label: "Jewish"
	},
	{
		value: "Protestant",
		label: "Protestant"
	}
];

export default function MyCriteria(props: { user: User }) {
	const [environment, setEnv] = useState(props.user.envPref);
	const [size, setSize] = useState(props.user.sizePref);
	const [schoolType, setSchoolType] = useState<string[]>([props.user.typePref, props.user.religionPref]);
	const [type, setType] = useState(props.user.typePref);
	const [religion, setReligion] = useState(props.user.religionPref);
	const [life, setLife] = useState<string[]>(props.user.lifePref ? props.user.lifePref : []);

	const setPreferences = useMutation({
		mutationKey: ["preferences"],
		mutationFn: (body: any) => changePreferences(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});

	const envSetter = (val: string) => {
		setEnv(val);
		// updatePreferences();
	};

	const sizeSetter = (val: string) => {
		setSize(val);
		// updatePreferences();
	};

	const typeSetter = (vals: MultiValue<valueLabel>) => {
		let religion = "";
		let colType = "";
		console.log(vals);

		for (const value of vals) {
			if (value.value == "Private" || value.value == "Public") {
				colType = value.value;
			} else {
				religion = value.value;
			}
		}

		console.log(colType);
		setReligion(religion);
		setType(colType);
		setSchoolType([colType, religion]);
	};

	const lifeSetter = (vals: MultiValue<valueLabel>) => {
		const temp: string[] = [];

		for (const value of vals) {
			temp.push(value.value);
		}

		setLife(temp);
	};

	useEffect(() => {
		const body = {
			uid: props.user.id,
			envPref: environment,
			sizePref: size,
			typePref: type,
			lifePref: life,
			religionPref: religion
		};

		setPreferences.mutate(body);
	}, [environment, size, type, life, religion]);

	const getLifeList = () => {
		const list: valueLabel[] = [];

		for (const item of life) {
			list.push({
				value: item,
				label: item
			});
		}

		return list;
	};

	const getTypeList = () => {
		const list: valueLabel[] = [];

		for (const item of schoolType) {
			if (item.length > 0) {
				list.push({
					value: item,
					label: item
				});
			}
		}

		return list;
	};

	return (
		<CriteriaContainer>
			<h2>My Criteria</h2>
			<p>
				<em>Enter your criteria to tailor the calculations to your ideal college experience.</em>
			</p>
			<CriteriaBox>
				<Criteria>
					<p>Environment:</p>
					<select onChange={(e) => envSetter(e.target.value)} value={environment}>
						<option></option>
						<option>Rural</option>
						<option>Online</option>
						<option>Suburban</option>
						<option>Urban</option>
					</select>
				</Criteria>
				<Criteria>
					<p>Size:</p>
					<select onChange={(e) => sizeSetter(e.target.value)} value={size}>
						<option></option>
						<option>Small (&lt;5,000)</option>
						<option>Medium (5,000-15,000)</option>
						<option>Large (&gt;15,000)</option>
					</select>
				</Criteria>
				<Criteria>
					<p>School Type:</p>
					<Select
						value={getTypeList()}
						onChange={(val) => typeSetter(val)}
						getOptionLabel={(val: valueLabel) => val.label}
						getOptionValue={(val: valueLabel) => val.value}
						options={typeChoices}
						isClearable={true}
						backspaceRemovesValue={true}
						isMulti={true}
						className={"select-container"}
						placeholder={"Select all that apply..."}
					/>
				</Criteria>
				<Criteria>
					<p>Campus Life:</p>
					<Select
						value={getLifeList()}
						onChange={(val) => lifeSetter(val)}
						getOptionLabel={(val: valueLabel) => val.label}
						getOptionValue={(val: valueLabel) => val.value}
						options={lifeChoices}
						isClearable={true}
						backspaceRemovesValue={true}
						isMulti={true}
						className={"select-container"}
						placeholder={"Select all that apply..."}
					/>
				</Criteria>
			</CriteriaBox>
		</CriteriaContainer>
	);
}
