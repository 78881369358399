import React from "react";
import {
	CircleImageBigger,
	LinkedInimage,
	NameCardHeader,
	NameCardTitle,
	PageText,
	PageTitle,
	PersonContainer,
	PictureRows,
	PictureSection,
	Title,
	TitleSection
} from "./About.styles";
// import logo2 from "../../atoms/logo_green.svg";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";

export default function About() {
	return (
		<>
			<NavBar />
			<TitleSection>
				<Title>Mission</Title>
				<PageText>
					Croi empowers high school students and their families to navigate the complexities of college decisions with
					confidence. Through a blend of personalized data analytics, financial education, and tailored resources, we
					provide the tools necessary to make informed choices about higher education. Our mission is to simplify the
					college selection process, ensuring students can achieve their educational goals with financial wisdom and
					clarity.
				</PageText>
			</TitleSection>

			<PictureSection>
				<PageTitle>Team</PageTitle>
				<PictureRows>
					<PersonContainer>
						<CircleImageBigger imageUrl={"images/nick_gronda.png"} />
						<a href={"https://www.linkedin.com/in/nick-gronda-6b03631a5"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Nick Gronda</NameCardHeader>
						<NameCardTitle>CEO/Co-Founder</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/caden-crist.jpg"} />
						<a href={"https://www.linkedin.com/in/caden-crist/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Caden Crist</NameCardHeader>
						<NameCardTitle>Backend Developer / Co-Founder</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/lucas_sicard.jpg"} />
						<a href={"https://www.linkedin.com/in/lucas-sicard-a736a4187/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Lucas Sicard</NameCardHeader>
						<NameCardTitle>Lead Developer</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/anthony_lopez.png"} />
						<a href={"https://www.linkedin.com/in/anthonyglopez1/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Anthony Lopez</NameCardHeader>
						<NameCardTitle>UI Designer</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/yama_profile.jpeg"} />
						<a href={"https://www.linkedin.com/in/habibzai/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Yama Habibzai</NameCardHeader>
						<NameCardTitle>Investor/Advisor</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/zach_rioux.jpg"} />
						<a href={"https://www.linkedin.com/in/zachary-rioux-514916255/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Zach Rioux</NameCardHeader>
						<NameCardTitle>Quality Engineer</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/evan_macgregor.png"} />
						<a href={"https://www.linkedin.com/in/evan-macgregor/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Evan Macgregor</NameCardHeader>
						<NameCardTitle>Account Manager</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger
							style={{ backgroundPosition: "center 10%" }}
							imageUrl={"images/julia_eagan_headshot.png"}
						/>
						<a href={"https://www.linkedin.com/in/julia-eagan-2aa260254/"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Julia Eagan</NameCardHeader>
						<NameCardTitle>Account Manager</NameCardTitle>
					</PersonContainer>

					<PersonContainer>
						<CircleImageBigger imageUrl={"images/anthony_maldonado_headshot.png"} />
						<a href={"http://linkedin.com/in/anthony-maldonado-922366147"}>
							<LinkedInimage imageUrl={"images/linkedin-logo.png"} />
						</a>
						<NameCardHeader>Anthony Maldonado</NameCardHeader>
						<NameCardTitle>Account Manager</NameCardTitle>
					</PersonContainer>
				</PictureRows>
			</PictureSection>

			{/* <StorySection>
				<Title>Our Story</Title>
				<PageText>
					Nick Gronda faced a difficult decision in high school when deciding which college to attend, between Bucknell,
					WPI, or Fairfield. After weighing the scholarship awards and costs of each school with his parents, he
					realized that Bucknell would require him to take out $40k per year in loans, while WPI and Fairfield would
					only require $15k and $5k per year, respectively. Using data-driven decision making, Nick created an algorithm
					that balanced most important factors in his college decision and weighted them according to his wants and
					needs.
				</PageText>
				<img alt="CROI Logo" src={"/images/logo_green.svg"} style={{ width: "200px" }}></img>
				<PageText>
					In 2022, Nick Gronda approached his friend and skilled web developer, Caden Crist, with the idea for a
					web-based College Decision Tool. After developing and testing the tool, they expanded the website's
					functionality and began creating additional resources to assist students in navigating the financial aspects
					of higher education and beyond, leading to the birth of Croi.
				</PageText>
			</StorySection> */}

			<Footer />
		</>
	);
}
