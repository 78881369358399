import styled from "styled-components";

export const ImportStudentButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 1%;
	width: 89%;
	line-height: 42px;
`;

export const AddStudent = styled.button`
	background-color: #3ad698;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	height: 40px;
	margin-left: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

export const AddBulk = styled.button`
	background-color: #d6533a;
	color: #fff;
	border-radius: 10px;
	width: 75px;
	height: 40px;
	margin-left: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;

export const ModalStyle = styled.div`
	/* Chrome, Safari, Edge, Opera */

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	input[type="number"] {
		-moz-appearance: textfield;
	}

	input {
		width: 350px;
		height: 40px;
		margin-top: 10px;
		border: 1px solid black;
		border-radius: 5px;
		padding-left: 10px;
	}

	div {
		display: flex;
	}

	p {
		width: 90px;
		padding-top: 15px;
		margin-left: 15px;
	}
`;

export const ResetButton = styled.button`
	border-radius: 20px;
	background: var(--croi-green);
	width: 100px;
	height: 40px;
`;

export const SortButton = styled.button`
	background-color: black;
	color: #fff;
	border-radius: 10px;
	width: 130px;
	height: 40px;
	margin-left: 10px;

	&.selected {
		background-color: white;
		color: black;
		border: 2px solid black;
	}
`;

export const StudentTable = styled.table`
	width: 95%;

	thead {
		background-color: var(--croi-green-color);
		color: black;
		text-align: center;
		font-weight: bold;
	}

	td {
		text-align: center;
		padding: 10px;
	}

	tr {
		text-align: center;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}
`;

export const EditButton = styled.button`
	color: #fff;
	border-radius: 10px;
	width: 75px;
	margin: 10px;

	:hover {
		background-color: #000;
		color: #fff;
	}
`;
