import {
	DataEntry,
	DataLargeInfo,
	DataRemaining,
	DataScore,
	GradeCircle,
	LeftSide,
	LeftSideLeft,
	LeftSideRight,
	ResultCircle,
	ResultsTableStyle
} from "./Results.styles";
import { currencyFormatter } from "../../util/currencyFormatter";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { User } from "../../types/User.types";
import { fetchUser } from "../../services/User.services";
import { Award, College } from "../../types/College.types";
import ToolTip from "../../atoms/ToolTip";

export default function ResultsTable(props: { colleges: College[] }) {
	const getUser = useQuery<User, Error>({
		queryKey: ["users"],
		queryFn: () => fetchUser(),
		refetchInterval: false
	});

	const awardsCalc = (awards: Award[]) => {
		let total = 0;

		for (const a of awards) {
			total += a.amount;
		}

		return total;
	};

	const grader = (score: number) => {
		if (score >= 80) {
			return "A";
		} else if (score >= 60) {
			return "B";
		} else if (score >= 40) {
			return "C";
		} else if (score >= 20) {
			return "D";
		} else {
			return "F";
		}
	};

	const colorChange = (score: number) => {
		if (score >= 80) {
			return "#3AD698";
		} else if (score >= 60) {
			return "#3ABAD6";
		} else if (score >= 40) {
			return "#EFD301";
		} else if (score >= 20) {
			return "#EB9C26";
		} else {
			return "#F50000";
		}
	};

	const envChecker = (env: string) => {
		if (getUser.data) {
			if (getUser.data.envPref.toLowerCase() == env) {
				return (
					<>
						{env[0].toUpperCase()}
						<div>{env.slice(1)}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						{env[0].toUpperCase()}
						<div>{env.slice(1)}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	const sizeChecker = (size: string) => {
		if (getUser.data) {
			if (getUser.data.sizePref == size) {
				return (
					<>
						<div>{size}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						<div>{size}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	const typeChecker = (type: string) => {
		if (getUser.data) {
			if (getUser.data.typePref == type || getUser.data.typePref.length == 0) {
				return (
					<>
						<div>{type}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
					</>
				);
			} else {
				return (
					<>
						<div>{type}&nbsp;</div>
						<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
					</>
				);
			}
		}
	};

	const relChecker = (religion: string) => {
		if (getUser.data) {
			if (getUser.data.religionPref == religion || religion == null) {
				return (
					<>
						{religion ? (
							<>
								<div>{religion}&nbsp;</div>
								<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
							</>
						) : (
							<div>N/A</div>
						)}
					</>
				);
			} else {
				return (
					<>
						{religion ? (
							<>
								<div>{religion}</div>
								<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
							</>
						) : (
							<div>N/A</div>
						)}
					</>
				);
			}
		}
	};

	const lifeList = (list: string[]) => {
		const max = list.length > 3 ? 3 : list.length;
		let correct = true;

		if (getUser.data) {
			const lifePreferences = getUser.data.lifePref;
			let line = "";
			let i: number;

			for (i = 0; i < lifePreferences.length && i < max; i++) {
				if (i < lifePreferences.length) {
					if (!list.includes(lifePreferences[i])) {
						correct = false;
					} else {
						line += lifePreferences[i] + ", ";
						list = list
							.slice(0, list.indexOf(lifePreferences[i]))
							.concat(list.slice(list.indexOf(lifePreferences[i]) + 1, list.length - 1));
					}
				}
			}

			if (correct) {
				return (
					<>
						{line ? (
							<>
								<div>{line}&nbsp;</div>
								<ResultCircle style={{ backgroundColor: "var(--croi-green)" }}>&#10003;</ResultCircle>
							</>
						) : (
							<div>N/A</div>
						)}
					</>
				);
			} else {
				return (
					<>
						{line ? (
							<>
								<div>{line}&nbsp;</div>
								<ResultCircle style={{ backgroundColor: "red" }}>x</ResultCircle>
							</>
						) : (
							<div>N/A</div>
						)}
					</>
				);
			}
		}
	};

	return (
		<ResultsTableStyle>
			{props.colleges
				.sort((a, b) => (a.score > b.score ? -1 : 1))
				.map((ranking, index) => (
					<DataEntry key={ranking.id + ranking.score.toString()}>
						<DataScore>
							<h3>{index + 1}</h3>
							<GradeCircle style={{ backgroundColor: colorChange(ranking.score) }}>{grader(ranking.score)}</GradeCircle>
							<h5>{ranking.score.toFixed(1)}%</h5>
						</DataScore>
						<DataLargeInfo>
							<h3>{ranking.name}</h3>
							<LeftSide>
								<LeftSideLeft>
									<b>4 Year Cost</b>
									<p>{currencyFormatter.format(ranking.cost)}</p>
									<b>Expected Salary</b>
									<p>{ranking.earlyPay ? currencyFormatter.format(ranking.earlyPay) : "N/A"}</p>
									<div style={{ display: "flex" }}>
										<b>20-Year ROI</b>&nbsp;
										<ToolTip
											text={
												"Calculated using the total cost of attendance and the sum of projected" +
												" salaries over 7 years at an early career salary, plus 13 years at a mid-career salary"
											}
										/>
									</div>

									<p>{currencyFormatter.format(ranking.roi)} </p>
								</LeftSideLeft>
								<LeftSideRight>
									<p>
										<b>Environment:&nbsp;</b> <a>{envChecker(ranking.environment)}</a>
									</p>
									<p>
										<b>Size:&nbsp;</b> <a>{sizeChecker(ranking.size)}</a>
									</p>
									<p>
										<b>School Type:&nbsp;</b> <a>{typeChecker(ranking.type)}</a>
									</p>
									<p>
										<b>Religious Affiliation:&nbsp;</b> <a>{relChecker(ranking.religiousAffiliation)}</a>
									</p>
									<p>
										<b>Campus Life:&nbsp;</b> <a>{lifeList(ranking.life)}</a>
									</p>
								</LeftSideRight>
							</LeftSide>
						</DataLargeInfo>
						<DataRemaining>
							<li>
								<b>Cost Per Year</b> ={" "}
								{ranking.costOverTime ? currencyFormatter.format(ranking.costOverTime[0]) : "N/A"}
							</li>
							<li>
								<b>Financial Award</b> = {ranking.awards ? currencyFormatter.format(awardsCalc(ranking.awards)) : "N/A"}
							</li>
							<li>
								<b>Field of Study</b> = {ranking.fos ? ranking.fos : "Undecided"}
							</li>
							<li>
								<b>Student:Teacher Ratio</b> = {ranking.studentTeacherRatio}
							</li>
							<li>
								<b>Male:Female Ratio</b> = {ranking.male}%:{ranking.female}%
							</li>
							<li>
								<b>Acceptance Rate</b> = {ranking.acceptanceRate}%
							</li>
							<li>
								<b>Freshman Retention Rate</b> = {ranking.retentionRate}%
							</li>
							<li>
								<b>4-year Graduation Rate</b> = {ranking.graduationRate}%
							</li>
						</DataRemaining>
					</DataEntry>
				))}
		</ResultsTableStyle>
	);
}
