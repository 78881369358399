import { fetcher } from "./Fetcher.services";
import { mutator } from "./Mutator.services";
import { Scholarship } from "../types/Scholarship.types";
import { fetcherBody } from "./FetcherBody.services";

export function fetchScholarshipList() {
	return fetcher<Scholarship[]>("/scholarships", "GET");
}

export function updateScholarship(body: any) {
	return mutator("/scholarships", "PUT", body);
}

export function fetchMatchData(body: any) {
	return fetcherBody<Scholarship[]>("/scholarships", "POST", body);
}
