import styled from "styled-components";
import { device } from "../../util/device";

export const CardSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

export const Card = styled.div`
	height: 390px;
	width: 25%;
	background-color: #f1f1f1;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 45px;
	text-align: center;
	align-items: center;
	justify-content: center;

	p {
		padding-top: 15px;
	}
`;

export const CardButton = styled.button`
	height: 40px;
	width: 60%;
	color: white;
	background-color: var(--croi-green);
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 10px;
`;

export const TitleSection = styled.div`
	justify-content: center;
	text-align: center;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 50px;
`;

export const Title = styled.h2`
	text-align: center;

	@media only screen and ${device.mobileM} {
		font-size: 3em;
	}

	@media only screen and ${device.tablet} {
		font-size: 4.7em;
	}
`;

export const PageText = styled.div`
	color: black;
	padding-top: 20px;
	text-align: left;
    padding-bottom: 30px;

	@media only screen and ${device.mobileM} {
		font-size: 15px;
		text-indent: 0;
	}

	@media only screen and ${device.tablet} {
		font-size: 24px;
	}
`;

export const SquareImage = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 225px;
	height: 225px;
	border-radius: 10px;
	padding-bottom: 10px;
`;

interface ImageProps {
	imageUrl: string;
}

export const BackButton = styled.button`
	width: 100px;
	color: var(--croi-green);
	background-color: white;
	margin-top: 15px;
	margin-bottom: 15px;
    margin-left: 5%;
	font-size: 20px;
`;

export const ArticleSection = styled.div`
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    h1 {
    
    }

    h2 {
        text-align: left;
    }

    ul {
        text-align: left;
    }

    p {
        text-align: left;
    }
`;

export const SquareImageBigger = styled.div<ImageProps>`
	background: transparent url(${(props) => props.imageUrl}) scroll center;
	background-size: cover;
	width: 600px;
	height: 300px;
	border-radius: 10px;
	padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
`;