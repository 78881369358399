import React from "react";
import {
	AccountBanner,
	AMCards,
	AMImage,
	AMSection,
	CroiCheckSection,
	CroiCheckSectionHeading,
	DescriptionSection,
	DescriptionSectionHeading,
	FiveStepCard,
	FiveStepNumber,
	FiveStepSection,
	GraphSection,
	HeaderGraphSection,
	ImageBackground,
	RowGraphSection,
	ScheduleMeetingButton,
	StatCard,
	StatSection,
	TopLanding,
	TopTextLanding,
	VideoEmbed,
	WithoutCroiSection,
	WithoutCroiSectionHeading
} from "./Landing.styles";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";
import FlipCard from "../../atoms/FlipCard";

export default function NewLanding() {
	return (
		<>
			<NavBar />
			<AccountBanner>
				<h1>START USING CROI FOR FREE TODAY!</h1>
				<button onClick={() => (window.location.pathname = "/signup")}>GET STARTED</button>
			</AccountBanner>
			<TopLanding>
				<TopTextLanding>
					<h2>
						Anxious about college costs?{" "}
						<span>
							<i>We got you.</i>
						</span>
					</h2>
					<ul>
						<li>
							<div>&#10003;</div>
							Match with your best fit school
						</li>
						<li>
							<div>&#10003;</div>
							Compare true costs and salaries
						</li>
						<li>
							<div>&#10003;</div>
							Perform a cost-benefit analysis
						</li>
						<li>
							<div>&#10003;</div>
							Discover 11,000+ Scholarships
						</li>
					</ul>
					<button onClick={() => (window.location.pathname = "/signup")}>Create My Free Account</button>
				</TopTextLanding>

				<VideoEmbed>
					<iframe
						src={"https://www.youtube.com/embed/YtigAD57Nto?si=4BijtUhQjuDVRGPb"}
						allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
						allowFullScreen
						title="Embedded youtube"
					/>
				</VideoEmbed>
			</TopLanding>
			<AMSection>
				<AMCards>
					Students/Parents
					<AMImage imageUrl="images/StudentParent.png" />
					<a href="/signup">
						<ScheduleMeetingButton>Create My Free Account</ScheduleMeetingButton>
					</a>
				</AMCards>
				<AMCards>
					Counselors
					<AMImage imageUrl="images/Counselor.png" />
					<a href={"https://calendly.com/nickgronda-croi"}>
						<ScheduleMeetingButton> Schedule 15-minute meeting </ScheduleMeetingButton>
					</a>
				</AMCards>
			</AMSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Make the right decision with the&nbsp;<span style={{ color: "var(--croi-green)" }}>croi</span>&nbsp;5-step
				college process:
			</h2>
			<FiveStepSection>
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>1</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "6px" }} src="/images/book.png" alt="book" />
						</ImageBackground>
						<p>Learn</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Gain education on managing your finances, exploring financial aid options, and mastering the FAFSA/CSS
							forms. Empower yourself with knowledge about funding your education effectively.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>2</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/Vector.png" alt="Vector" />
						</ImageBackground>
						<p>Match</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Find your ideal colleges tailored to your needs. Croi matches you with schools based on financial aid
							opportunities, your chosen field of study, academic strengths, and more – ensuring the perfect fit for
							your future.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>3</FiveStepNumber>
						<ImageBackground>
							<img src="/images/brain.png" alt="analyze" style={{ marginTop: "8px" }} />
						</ImageBackground>
						<p>Analyze</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Find out the real cost of your education and see what you'll get in return. Our tool calculates the total
							expenses, predicts your chances of landing a job after graduation, and estimates the salaries you can
							expect.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>4</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/school.png" alt="school" />
						</ImageBackground>
						<p>Decide</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Conduct a personalized cost-benefit analysis of your college choices, aligning them with your individual
							preferences and priorities. Make informed decisions that suit your wants and needs.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>5</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/account_balance.png" alt="accBalance" />
						</ImageBackground>
						<p>Finance</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Determine how to fund your college expenses, calculate your future student loan payments, find the right
							private lenders, and discover personalized scholarship recommendations tailored just for you.
						</p>
					</FiveStepCard>
				)}
			</FiveStepSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Here's what students are saying about&nbsp;<span style={{ color: "var(--croi-green)" }}>croi...</span>
			</h2>

			<StatSection>
				<StatCard>
					<h1>92%</h1>
					<p>Found that Croi helped alleviate their anxiety regarding their college decision.</p>
				</StatCard>
				<StatCard>
					<h1>73%</h1>
					<p>Found Croi helpful in choosing the right college based on their wants and needs.</p>
				</StatCard>
			</StatSection>

			<GraphSection>
				<HeaderGraphSection>
					<DescriptionSectionHeading></DescriptionSectionHeading>
					<CroiCheckSectionHeading style={{ borderTop: "var(--croi-green) solid 4px" }}>
						<img src="/images/croiLogo.png" alt="croiLogo" />
					</CroiCheckSectionHeading>
					<WithoutCroiSectionHeading>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>Without Croi</a>
					</WithoutCroiSectionHeading>
				</HeaderGraphSection>
				<RowGraphSection>
					<DescriptionSection>Find the right school that is the best fit for you</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>Possibly make a life-long incorrect decision</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Built in financial aid tutorials</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>College may cost you too much.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Shop for schools based on financial aid awards</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may leave free money behind.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Break down the true cost of your education</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may be hit with surprise expenses.</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Compare your schools based on cost-benefit</DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may select the more expensive college for the same job after college</a>
					</WithoutCroiSection>
				</RowGraphSection>
				<RowGraphSection>
					<DescriptionSection>Compare the most relevant loan & scholarship options </DescriptionSection>
					<CroiCheckSection>
						<img src="/images/checkCircle.png" alt="checkCircle" />
					</CroiCheckSection>
					<WithoutCroiSection>
						<img src="/images/xCircle.png" alt="xCircle" />
						<a>You may miss out on cheaper loans and free scholarship money</a>
					</WithoutCroiSection>
				</RowGraphSection>
			</GraphSection>
			<Footer />
		</>
	);
}
