import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { supabase } from "../supabase";

const AuthRoute = (props: { children: ReactElement }) => {
	const { children } = props;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const navigateCallback = useCallback(navigate, [navigate]);

	useEffect(() => {
		supabase.auth.getSession().then((session) => {
			if (session.data.session === null) {
				console.log("unauthorized");
				navigateCallback("/login?unauthorized");
			} else {
				setLoading(false);
			}
		});
	}, [navigateCallback]);

	if (loading)
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					textAlign: "center"
				}}>
				<ScaleLoader color="white" loading={loading} />
			</div>
		);
	return <div>{children}</div>;
};

export default AuthRoute;
