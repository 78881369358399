import styled from "styled-components";
import { device } from "../../util/device";

export const PricingSection = styled.div`
	width: 90%;
	margin-left: 5%;
	padding-top: 2%;
	padding-bottom: 2%;
	text-align: center;
	justify-content: center;
`;

export const PricingBox = styled.div`
	height: auto;
	background-color: white;
	text-align: center;

	h1 {
		padding-top: 1%;
		color: black;
	}

	@media only screen and ${device.mobileM} {
		h1 {
			font-size: 8vw;
		}
	}

	@media only screen and ${device.tablet} {
		h1 {
			font-size: 4vw;
		}
	}
`;

export const PricingRow = styled.div`
	justify-content: center;

	@media only screen and ${device.mobileM} {
		width: 100%;
		margin-left: 0;
		display: flex;
		flex-direction: column;
	}

	@media only screen and ${device.tablet} {
		width: 50%;
		margin-left: 25%;
		margin-top: 2%;
		display: flex;
		flex-direction: row;
	}
`;

export const PricingCard = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;

	@media only screen and ${device.mobileM} {
		width: 80%;
	}

	@media only screen and ${device.tablet} {
		width: 40%;
	}
`;

export const PricingBoxLarge = styled.div`
	background-color: #e9fdf5;
	border-radius: 10px;
	text-align: center;

	h3 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		h3 {
			color: black;
			padding-top: 2%;
			font-size: 30px;
			margin-bottom: 10%;
		}

		h4 {
			color: #6d7471;
			padding-top: 5%;
			font-size: 20px;
		}

		p {
			color: black;
			font-size: 20px;
		}
	}

	@media only screen and ${device.tablet} {
		h3 {
			color: black;
			padding-top: 2%;
			font-size: 2.25vw;
			margin-bottom: 10%;
		}

		h4 {
			color: #6d7471;
			padding-top: 5%;
			font-size: 1.75vw;
		}

		p {
			color: black;
			font-size: 1.3vw;
		}
	}
`;

export const ContactUsButton = styled.button`
	text-align: center;
	background-color: var(--croi-green);
	color: white;
	margin-bottom: 5%;
	margin-top: 10%;
	border-radius: 8px;
	width: 80%;

	@media only screen and ${device.mobileM} {
		height: 40px;
		font-size: 20px;

		a {
			color: white;
		}
	}

	@media only screen and ${device.tablet} {
		height: 40px;
		font-size: 20px;

		a {
			color: white;
		}
	}
`;

export const DemoBox = styled.div`
	background-color: #e9fdf5;
	border-radius: 10px;
	text-align: center;
	display: flex;
	flex-direction: column;
	color: black;
	margin-bottom: 5%;
	margin-top: 15px;

	@media only screen and ${device.mobileM} {
		padding-top: 20px;
		height: 160px;
		width: 80%;
		margin-left: 10%;
	}

	@media only screen and ${device.tablet} {
		padding-top: 30px;
		width: 60%;
		margin-left: 20%;
	}
`;

export const ScheduleDemoButton = styled.button`
	text-align: center;
	background-color: var(--croi-green);
	font-size: 20px;
	margin-bottom: 5%;
	border-radius: 8px;
	font-weight: 700;
	color: white;

	@media only screen and ${device.mobileM} {
		margin-top: 5%;
		height: 40px;
		width: 50%;
	}

	@media only screen and ${device.tablet} {
		margin-top: 3%;
		height: 40px;
		width: 40%;
	}
`;

export const SmallBanner = styled.div`
	background-color: var(--croi-green);
	color: white;
	height: 35px;
	text-align: center;
	border-radius: 10px;
	font-size: 25px;
	padding-top: 1px;
`;
