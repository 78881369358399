import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Card, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { LoginCardWrapper, LoginWrapper } from "./Login.styles";
import { SubmitButton } from "../../styles/SubmitButton.styles";
import NavBar from "../Navbar/Navbar";

export default function LoginFromLW() {
	const [emailRef, setEmail] = useState<string>("");
	const [passwordRef, setPassword] = useState<string>("");
	// const navigate = useNavigate();
	const [authing, setAuthing] = useState(false);
	const [loginError, setLoginError] = useState("");
	const [show, setShow] = useState(false);
	const openResetModal = () => setShow(true);
	const handleClose = () => setShow(false);
	const [errMessage, setErrMessage] = useState("");
	const [resetEmail, setResetEmail] = useState("");

	const logIn = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setAuthing(true);
		signInWithEmailAndPassword(auth, emailRef, passwordRef)
			.then((userCredential) => {
				const user = userCredential.user;
				console.log(user.toJSON);
				setAuthing(false);
				// window.location.replace(waiter());
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode + errorMessage);
				setLoginError("We had trouble logging you in, please provide valid credentials");
				setAuthing(false);
			});
	};

	const resetPass = async (email: string) => {
		sendPasswordResetEmail(auth, email)
			.then(() => {
				handleClose();
				alert("Password Email Sent Successfully");
			})
			.catch((error) => {
				const errorMessage = error.message;
				setErrMessage(errorMessage);
			});
	};

	return (
		<>
			<NavBar />
			<LoginWrapper>
				<Container style={{ minHeight: "100vh" }} fluid>
					<Row className="w-100 d-flex align-items-center justify-content-center">
						<div
							style={{
								maxWidth: "400px",
								transform: "translateY(25%)"
							}}>
							<LoginCardWrapper>
								<Card>
									<Card.Img variant="top" src="images/croi logo no words.png" />
									<Card.Body>
										<h4
											className="text-center mb-4"
											style={{
												borderBottom: "1px solid #000",
												lineHeight: "0.1em"
											}}>
											<span
												style={{
													background: "var(--white)",
													padding: "0 10px",
													fontFamily: "Montserrat"
												}}>
												Login
											</span>
										</h4>
										<Form>
											<Form.Group>
												<Form.Label>Email </Form.Label>
												<Form.Control
													type="email"
													onChange={(event) => setEmail(event.target.value)}
													value={emailRef}
													required
												/>
											</Form.Group>
											<Form.Group>
												<Form.Label>Password </Form.Label>
												<Form.Control
													type="password"
													onChange={(event) => setPassword(event.target.value)}
													value={passwordRef}
													required
												/>
											</Form.Group>
											<div
												style={{
													display: "flex",
													justifyContent: "end",
													marginTop: "10px"
												}}>
												<p
													onClick={() => openResetModal()}
													style={{
														textDecorationLine: "underline",
														fontSize: "12px",
														alignSelf: "end",
														cursor: "pointer"
													}}>
													Forgot Password?
												</p>
											</div>
											{loginError && <p style={{ color: "red" }}>{loginError}</p>}
											<SubmitButton disabled={authing} onClick={(e) => logIn(e)}>
												Login
											</SubmitButton>
										</Form>
									</Card.Body>
									<div className="w-100 text-center mb-2">
										Don't have an account? <Link to="/signup">Sign-Up</Link>
									</div>
								</Card>
							</LoginCardWrapper>
						</div>
					</Row>
					<Modal style={{ fontFamily: "sitka-small, serif" }} show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Reset Password</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<input
								style={{ width: "100%", border: "1px solid black" }}
								placeholder="Enter Email"
								type="email"
								onChange={(e) => {
									setResetEmail(e.target.value);
								}}
							/>
						</Modal.Body>
						<Modal.Footer>
							{errMessage && errMessage}
							<SubmitButton onClick={() => resetPass(resetEmail)}>Submit</SubmitButton>
						</Modal.Footer>
					</Modal>
				</Container>
			</LoginWrapper>
		</>
	);
}
