import { loan } from "../../types/Loan.types";
import { useState } from "react";
import { MobileLoanTabStyle } from "./MobileLoanTab.styles";
import { currencyFormatter } from "../../util/currencyFormatter";

export default function MobileLoanTab(props: { loan: loan; showModal: any; setOption: any; setData: any }) {
	const [show, setShow] = useState(false);

	return (
		<MobileLoanTabStyle>
			<h2 onClick={() => setShow(!show)}>{props.loan.name}</h2>{" "}
			{show ? (
				<div style={{ backgroundColor: "#eeeeee", paddingBottom: "10px" }}>
					<p>
						<b>Class Year:</b>&nbsp;{props.loan.classYear}
					</p>
					<p>
						<b>Amount:</b>&nbsp;{currencyFormatter.format(props.loan.amount)}
					</p>
					<p>
						<b>Interest:</b>&nbsp;{props.loan.interest}%
					</p>
					<p>
						<b>Term:</b>&nbsp;{props.loan.term} Year
					</p>
					<p>
						<b>Private/Federal:</b>&nbsp;{props.loan.federal ? "Federal" : "Private"}
					</p>
					<p>
						<b>Subsidized/Unsubsidized:</b>&nbsp;{props.loan.subsidized ? "Subsidized" : "Unsubsidized"}
					</p>
					<p>
						<b>Monthly Pre-Pay:</b>&nbsp;{currencyFormatter.format(props.loan.pay)}
					</p>
					<button
						onClick={() => {
							props.showModal(true);
							props.setOption("Edit");
							props.setData(props.loan);
						}}>
						Edit
					</button>
				</div>
			) : (
				<></>
			)}
		</MobileLoanTabStyle>
	);
}
