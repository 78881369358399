import styled from "styled-components";

export default function ToolTip(props: { text: string }) {
	return (
		<ToolStyle>
			?<span className={"toolTipText"}>{props.text}</span>
		</ToolStyle>
	);
}

const ToolStyle = styled.div`
	border-radius: 50%;
	text-align: center;
	border: black 1px solid;
	width: 20px;
	height: 20px;
	font-size: 14px;

	.toolTipText {
		visibility: hidden;
		background-color: var(--croi-green);
		color: white;
		text-align: left;
		border-radius: 6px;
		padding: 5px;
		position: absolute;
		z-index: 1;
		max-width: 500px;
		font-size: 18px;
	}

	:hover .toolTipText {
		visibility: visible;
	}
`;
