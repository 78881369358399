import styled from "styled-components";

export const MobileLoanTabStyle = styled.div`
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;

	h2 {
		background-color: var(--croi-green);
		color: white;
		font-weight: bold;
		height: 50px;
		box-shadow: 0 5px 5px grey;
		text-align: center;
		padding-top: 5px;
	}

	p {
		display: flex;
		justify-content: center;
	}

	button {
		background-color: var(--croi-green);
		color: white;
		width: 100px;
		height: 30px;
		border-radius: 15px;
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
`;
