import {
	AddAwardButton,
	AwardStyle,
	Card,
	CardButton,
	CardHeader,
	CardSection,
	CostDataBox,
	CostDataBoxDiv,
	CostNavMaster,
	CostNavMid,
	CostNavTop,
	CostSearchBar,
	CostSearchBox,
	CostSearchItem,
	DeleteCollegeButton,
	EstimatedTotal,
	MinimizeButton,
	YearlyTotal
} from "./CostNav.styles";
import { ScaleLoader } from "react-spinners";
import useFetch from "../../hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../services/QueryClient.services";
import { createCollege, deleteCollege, fetchUniqueCollege, updateCollege } from "../../services/College.services";
import { currencyFormatter } from "../../util/currencyFormatter";
import { Award, College, fetchUniqueReturn } from "../../types/College.types";
import { Snack } from "../../atoms/SnackBarAlert";
import { User } from "../../types/User.types";
import CroiCheckbox from "../../atoms/CroiCheckbox";
import { Modal } from "react-bootstrap";
import { SubmitButtonCollegeCard } from "../../components/CollegeDecisionModules/CollegeList.styles";
import { createFinancialAward, deleteFinancialAward, updateFinancialAward } from "../../services/Award.services";
import { updateUser } from "../../services/User.services";

type option = {
	onCampus: boolean;
	mealPlan: boolean;
};

export default function CostNav(props: { colleges: College[]; user: User }) {
	const [currentSchool, setCurrentSchool] = useState<string>("");
	const [multiplyValue, setMultiplyValue] = useState(props.user.calcAnnualIncrease);
	const [snackMsg, setMsg] = useState("");
	const [newCollegeEnabler, setEnabler] = useState(false);
	const [fetchID, setFetchID] = useState("");

	const { searchResults, setSearchResults } = useFetch();

	const updateAnnualIncrease = useMutation({
		mutationKey: ["users"],
		mutationFn: (body: any) => updateUser(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]).then(() => queryClient.invalidateQueries(["colleges"]));
		}
	});

	useEffect(() => {
		if (multiplyValue != props.user.calcAnnualIncrease) {
			setMsg("Calculating...");
			updateAnnualIncrease.mutate({
				calcAnnualIncrease: multiplyValue
			});
		}
	}, [multiplyValue]);

	const collegeCreation = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createCollege(body),
		onSuccess: () => {
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const collegeUpdate = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateCollege(body),
		onSuccess: () => {
			queryClient.invalidateQueries(["colleges"]);
		}
	});

	const deleteQuery = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteCollege(body),
		onSuccess: () => {
			setMsg("Deleted College Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const selectedCollege = () => {
		return props.colleges.find((c) => c.id == currentSchool)!;
	};

	const updateVariables = () => {
		if (currentSchool) {
			collegeUpdate.mutate({
				id: currentSchool,
				mealPlan: selectedCollege().mealPlan,
				onCampus: selectedCollege().onCampus
			});
		}
	};

	const handleItemClick = (collegeId: string) => {
		setCurrentSchool("");
		setFetchID(collegeId);
		setEnabler(true);
		setSearchResults({ ...searchResults, slug: "" });
	};

	const [options, setOptions] = useState<option[]>([
		{ onCampus: true, mealPlan: true },
		{ onCampus: true, mealPlan: true },
		{ onCampus: false, mealPlan: false },
		{ onCampus: false, mealPlan: false }
	]);

	const getCostInfo = useQuery<fetchUniqueReturn, Error>({
		queryKey: ["CostNav", fetchID],
		queryFn: () => fetchUniqueCollege(fetchID),
		refetchInterval: false,
		enabled: newCollegeEnabler,
		retry: false
	});

	const optionSetter = (campus: boolean, value: boolean, index: number) => {
		const temp = options;
		if (campus) {
			temp[index].onCampus = value;
		} else {
			temp[index].mealPlan = value;
		}
		setOptions(temp);
	};

	const costMapper = () => {
		const data = [];

		if (currentSchool !== "") {
			for (let i = 0; i < selectedCollege().costOverTime.length; i++) {
				data.push(
					<CostDataBoxDiv>
						<CostDataBox>
							<h5>Year {i + 1}</h5>
							<div>
								<h6>Housing</h6>
								<select
									defaultValue={`${selectedCollege().onCampus[i]}`}
									onChange={(value) => {
										selectedCollege().onCampus[i] = value.target.value === "true";
										updateVariables();
									}}>
									<option value="true">On-Campus</option>
									<option value="false">Off-Campus</option>
								</select>
							</div>
							<div>
								<h6>Food</h6>
								<select
									defaultValue={`${selectedCollege().mealPlan[i]}`}
									onChange={(value) => {
										selectedCollege().mealPlan[i] = value.target.value === "true";
										updateVariables();
									}}>
									<option value="true">Meal Plan</option>
									<option value="false">Groceries</option>
								</select>
							</div>
							<div>
								<h6>Tuition & Fees</h6>
								<p>
									{currencyFormatter.format(
										(props.user.state == selectedCollege().state && selectedCollege().inStateTuition > 0
											? selectedCollege().inStateTuition
											: selectedCollege().tuition) *
											(multiplyValue ? i * 0.03 + 1 : 1) +
											selectedCollege().otherFees +
											(i == 0 ? selectedCollege().initialFees : 0)
									)}
								</p>
							</div>
							<div>
								<h6>{`${selectedCollege().onCampus[i] ? "Room &" : "Off-Campus &"} ${
									selectedCollege().mealPlan[i] ? "Board" : "Groceries"
								}`}</h6>
								<p>
									{currencyFormatter.format(
										(selectedCollege().onCampus[i] ? selectedCollege().onCampusLiving : 8400) +
											(selectedCollege().mealPlan[i] ? selectedCollege().mealPlanCost : 3802)
									)}
								</p>
							</div>
							<div style={{ paddingBottom: "1px" }}>
								<h6>Books & Supplies</h6>
								<p>{currencyFormatter.format(selectedCollege().booksNSupplies)}</p>
							</div>
						</CostDataBox>
						<YearlyTotal>
							<h6>Estimated Cost</h6>
							<p>{currencyFormatter.format(selectedCollege().costOverTime[i])}</p>
						</YearlyTotal>
					</CostDataBoxDiv>
				);
			}
		} else if (newCollegeEnabler && getCostInfo.data) {
			for (let i = 0; i < (getCostInfo.data.uniqueCollege.isTwoYear ? 2 : 4); i++) {
				data.push(
					<CostDataBoxDiv id={`${getCostInfo.data.uniqueCollege.iped} Year ${i + 1}`}>
						<CostDataBox>
							<h5>Year {i + 1}</h5>
							<div>
								<h6>Housing</h6>
								<select
									defaultValue={`${options[i].onCampus}`}
									onChange={(value) => optionSetter(true, value.target.value == "true", i)}>
									<option value="true">On-Campus</option>
									<option value="false">Off-Campus</option>
								</select>
							</div>
							<div>
								<h6>Food</h6>
								<select
									defaultValue={`${options[i].mealPlan}`}
									onChange={(value) => optionSetter(false, value.target.value == "true", i)}>
									<option value="true">Meal Plan</option>
									<option value="false">Groceries</option>
								</select>
							</div>
							<div>
								<h6>Tuition & Fees</h6>
								<p>
									{currencyFormatter.format(
										(props.user.state == getCostInfo.data.uniqueCollege.state &&
										getCostInfo.data.uniqueCollege.inStateTuition > 0
											? getCostInfo.data.uniqueCollege.inStateTuition
											: getCostInfo.data.uniqueCollege.tuition) *
											(multiplyValue ? i * 0.03 + 1 : 1) +
											getCostInfo.data.uniqueCollege.otherFees +
											(i == 0 ? getCostInfo.data.uniqueCollege.initialFees : 0)
									)}
								</p>
							</div>
							<div>
								<h6>{`${options[i].onCampus ? "Room &" : "Off-Campus &"} ${
									options[i].mealPlan ? "Board" : "Groceries"
								}`}</h6>
								<p>
									{currencyFormatter.format(
										(options[i].onCampus ? getCostInfo.data.uniqueCollege.onCampusLiving : 8400) +
											(options[i].mealPlan ? getCostInfo.data.uniqueCollege.mealPlanCost : 3802)
									)}
								</p>
							</div>
							<div style={{ paddingBottom: "1px" }}>
								<h6>Books & Supplies</h6>
								<p>{currencyFormatter.format(getCostInfo.data.uniqueCollege.booksNSupplies)}</p>
							</div>
						</CostDataBox>
						<YearlyTotal>
							<h6>Estimated Cost</h6>
							<p>
								{currencyFormatter.format(
									getCostInfo.data.uniqueCollege.booksNSupplies +
										(options[i].onCampus ? getCostInfo.data.uniqueCollege.onCampusLiving : 8400) +
										(options[i].mealPlan ? getCostInfo.data.uniqueCollege.mealPlanCost : 3802) +
										getCostInfo.data.uniqueCollege.otherFees +
										(i == 0 ? getCostInfo.data.uniqueCollege.initialFees : 0) +
										(props.user.state == getCostInfo.data.uniqueCollege.state &&
										getCostInfo.data.uniqueCollege.inStateTuition > 0
											? getCostInfo.data.uniqueCollege.inStateTuition
											: getCostInfo.data.uniqueCollege.tuition) *
											(multiplyValue ? i * 0.03 + 1 : 1)
								)}
							</p>
						</YearlyTotal>
					</CostDataBoxDiv>
				);
			}
		}

		return data;
	};

	const costCalcTotal = () => {
		let sum = 0;

		if (getCostInfo.data) {
			for (let i = 0; i < (getCostInfo.data.uniqueCollege.isTwoYear ? 2 : 4); i++) {
				sum +=
					(props.user.state == getCostInfo.data.uniqueCollege.state && getCostInfo.data.uniqueCollege.inStateTuition > 0
						? getCostInfo.data.uniqueCollege.inStateTuition
						: getCostInfo.data.uniqueCollege.tuition) *
						(multiplyValue ? i * 0.03 + 1 : 1) +
					getCostInfo.data.uniqueCollege.booksNSupplies +
					getCostInfo.data.uniqueCollege.otherFees +
					(i === 0 ? getCostInfo.data.uniqueCollege.initialFees : 0);

				if (options[i].onCampus) {
					sum += getCostInfo.data.uniqueCollege.onCampusLiving;
				} else {
					sum += 8400;
				}

				if (options[i].mealPlan) {
					sum += getCostInfo.data.uniqueCollege.mealPlanCost;
				} else {
					sum += 3802;
				}
			}
		} else if (currentSchool) {
			sum = selectedCollege().cost;
		}

		return sum;
	};

	/****** Financial Award ******/
	const [showFAEdit, setShowFAEdit] = useState(false);
	const [FAID, setFAID] = useState("");
	const [choice, setChoice] = useState("create");
	const [name, setName] = useState("");
	const [awardValue, setAwardValue] = useState(0);
	const [fourYear, setFourYear] = useState(false);

	const createFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => createFinancialAward(body),
		onSuccess: () => {
			setMsg("Created Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const updateFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => updateFinancialAward(body),
		onSuccess: () => {
			setMsg("Updated Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const deleteFA = useMutation({
		mutationKey: ["colleges"],
		mutationFn: (body: any) => deleteFinancialAward(body),
		onSuccess: () => {
			setMsg("Deleted Financial Award Successfully!");
			return queryClient.invalidateQueries(["colleges"]);
		}
	});

	const handleCreateFA = () => {
		if (selectedCollege().cost - awardValue > 0) {
			const body = {
				cid: currentSchool,
				name: name,
				amount: awardValue,
				perYear: fourYear
			};
			createFA.mutate(body);
			setShowFAEdit(false);
		} else {
			setMsg("Financial award cannot be more than total cost");
		}
	};

	const handleUpdateFA = () => {
		if (selectedCollege().cost - awardValue > 0) {
			const body = {
				id: FAID,
				name: name,
				amount: awardValue,
				perYear: fourYear
			};
			updateFA.mutate(body);
			setShowFAEdit(false);
		} else {
			setMsg("Financial award cannot be more than total cost");
		}
	};

	const handleDeleteFA = (f: string) => {
		setFAID(f);
		const body = {
			id: f
		};

		deleteFA.mutate(body);
	};

	const handleEditFA = (c: string, f?: Award) => {
		setChoice(c);

		if (f) {
			setFAID(f.id);
			setName(f.name);
			setAwardValue(f.amount);
			setFourYear(f.perYear);
		} else {
			setName("");
			setAwardValue(0);
			setFourYear(false);
		}

		setShowFAEdit(true);
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			<CostNavMaster>
				<h2>Search By College</h2>
				<i style={{ textAlign: "center", display: "block" }}>
					Search any college to view a detailed four-year cost breakdown and decide if you want to add it to your
					personalized college list.
				</i>
				<CostNavTop>
					<CostSearchBar>
						<input
							className="p-2 w-100"
							type="text"
							placeholder={"Search Colleges"}
							value={searchResults.slug}
							onChange={(e) => setSearchResults({ ...searchResults, slug: e.target.value })}
						/>
						{searchResults.slug !== "" && (
							<CostSearchBox>
								{searchResults.loading && (
									<CostSearchItem>
										<div style={{ margin: "50px auto 50px auto" }}>
											<ScaleLoader color="black" loading={searchResults.loading} />
										</div>
									</CostSearchItem>
								)}
								{searchResults.results && (
									<>
										{searchResults.results.map((value) => {
											return (
												<CostSearchItem key={value.id} onClick={() => handleItemClick(value.id)}>
													{value["school.name"]}
												</CostSearchItem>
											);
										})}
									</>
								)}
							</CostSearchBox>
						)}
					</CostSearchBar>
				</CostNavTop>
				{currentSchool ? (
					<>
						<h1>
							{selectedCollege().name}&nbsp;
							<MinimizeButton onClick={() => setCurrentSchool("")}>CLOSE</MinimizeButton>
						</h1>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<AddAwardButton onClick={() => handleEditFA("create")}>+ Add an award</AddAwardButton>
								{selectedCollege().awards.map((award) => {
									if (award.cid === currentSchool) {
										return (
											<AwardStyle>
												<p onClick={() => handleEditFA("update", award)}>{currencyFormatter.format(award.amount)}</p>
												<button onClick={() => handleDeleteFA(award.id)}>X</button>
											</AwardStyle>
										);
									}
								})}
							</div>
							<div style={{ display: "flex" }}>
								<CroiCheckbox setter={setMultiplyValue} value={multiplyValue} size={20} />
								<p style={{ color: "var(--croi-green)", marginLeft: "10px" }}>
									Include Predicted Annual Tuition Increase (3%)
								</p>
							</div>
						</div>
						<CostNavMid style={{ justifyContent: selectedCollege().isTwoYear ? "space-evenly" : "space-between" }}>
							{costMapper()}
						</CostNavMid>
						<p>
							<em>
								Select your school and customize yearly housing arrangements and meal options to see a detailed,
								personalized estimate of your total educational costs over four years.
							</em>
						</p>
						<EstimatedTotal>
							<h6>Estimated 4-Year Cost</h6>
							<p>{currencyFormatter.format(costCalcTotal())}</p>
						</EstimatedTotal>
					</>
				) : newCollegeEnabler ? (
					<>
						<h1>
							{getCostInfo.data ? getCostInfo.data.uniqueCollege.name : ""}&nbsp;
							<MinimizeButton
								onClick={() => {
									setEnabler(false);
								}}>
								CLOSE
							</MinimizeButton>
						</h1>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex" }}>
								<AddAwardButton
									onClick={() => {
										collegeCreation.mutate({ iped: getCostInfo.data ? getCostInfo.data.uniqueCollege.iped : "" });
										setEnabler(false);
									}}>
									+ Add to College List
								</AddAwardButton>
							</div>
							<div style={{ display: "flex" }}>
								<CroiCheckbox setter={setMultiplyValue} value={multiplyValue} size={20} />
								<p style={{ color: "var(--croi-green)", marginLeft: "10px" }}>
									Include Predicted Annual Tuition Increase (3%)
								</p>
							</div>
						</div>
						<CostNavMid
							style={{
								justifyContent: (getCostInfo.data ? getCostInfo.data.uniqueCollege.isTwoYear : false)
									? "space-evenly"
									: "space-between"
							}}>
							{costMapper()}
						</CostNavMid>
						<p>
							<em>
								Select your school and customize yearly housing arrangements and meal options to see a detailed,
								personalized estimate of your total educational costs over four years.
							</em>
						</p>
						<EstimatedTotal>
							<h6>Estimated 4-Year Cost</h6>
							<p>{currencyFormatter.format(costCalcTotal())}</p>
						</EstimatedTotal>
					</>
				) : (
					<></>
				)}
				<h2 style={{ marginTop: "15px" }}>My College List</h2>
				<CardSection>
					{props.colleges.map((school) => (
						<Card>
							<CardHeader>
								{school.name}
								<DeleteCollegeButton
									onClick={() => {
										if (currentSchool === school.id) {
											setCurrentSchool("");
										}
										deleteQuery.mutate({ id: school.id });
									}}>
									X
								</DeleteCollegeButton>
							</CardHeader>
							<p>First Year Cost Estimate</p>
							<h1>{currencyFormatter.format(school.costOverTime[0])}</h1>
							<p>Four Year Cost Estimate</p>
							<h1>{currencyFormatter.format(school.cost)}</h1>
							<CardButton onClick={() => setCurrentSchool(school.id)}>See Details</CardButton>
						</Card>
					))}
				</CardSection>
			</CostNavMaster>

			{/*  ADD/UPDATE FINANCIAL AWARD MODAL  */}
			<Modal
				show={showFAEdit}
				onHide={() => {
					setShowFAEdit(false);
				}}>
				<Modal.Header closeButton>
					<Modal.Title>Input Financial Award</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Name:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="text"
						placeholder={"Name"}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					Amount:
					<input
						className="p-2 w-100"
						style={{ border: "1px solid black", borderRadius: "5px" }}
						type="number"
						placeholder={"Amount"}
						value={awardValue}
						onChange={(e) => {
							setAwardValue(e.target.valueAsNumber);
						}}
					/>
					Is this award received just once or every year?
					<select
						onChange={(e) => setFourYear(e.target.value === "true")}
						value={fourYear ? "true" : "false"}
						className={"p-2 w-100"}
						style={{ border: "1px solid black", borderRadius: "5px" }}>
						<option value={"false"}>One Time Award</option>
						<option value={"true"}>Recurring Award</option>
					</select>
				</Modal.Body>
				<Modal.Footer>
					<SubmitButtonCollegeCard
						onClick={() => {
							if (choice === "create") {
								handleCreateFA();
							} else {
								handleUpdateFA();
							}
						}}>
						Submit
					</SubmitButtonCollegeCard>
				</Modal.Footer>
			</Modal>
		</>
	);
}
