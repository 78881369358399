import { fetcher } from "./Fetcher.services";
import { User } from "../types/User.types";
import { mutator } from "./Mutator.services";
import { fileMutator } from "./FileMutator.services";
import { Sponsor } from "../types/Sponsor.types";

export function fetchUser() {
	return fetcher<User>("/users", "GET");
}

export function fetchUsers() {
	return fetcher<User[]>("/users/admin", "GET");
}

export function deleteUser(body: any) {
	return mutator("/users", "DELETE", body);
}

export function updateUser(body?: any | null) {
	return mutator("/users", "PUT", body);
}

export function addUser(body?: any | null) {
	return mutator("/users/admin", "POST", body);
}

export function addUsers(body?: any | null) {
	return fileMutator("/users/admin/bulk", "POST", body);
}

export function fetchStudent(uid: string) {
	return fetcher<User>(`/users/student/${uid}`, "GET");
}

export function changePreferences(body: any) {
	return mutator("/users/preferences", "PUT", body);
}

export function changeFinances(body: any) {
	return mutator("/users/finances", "PUT", body);
}

export function fetchSponsor() {
	return fetcher<Sponsor>("/users/sponsor", "GET");
}

export function upgradeUser(body: any) {
	return mutator("/users/upgrade", "POST", body);
}
