import React from "react";
import {
	BenefitCard,
	BenefitCardsSection,
	BenefitSection,
	CreateAccountButton,
	CreateAccountSection,
	FiveStepCard,
	FiveStepNumber,
	FiveStepSection,
	ImageBackground,
	ScheduleMeetingButton,
	StatCard,
	StatSection,
	TopLanding,
	TopTextLanding,
	VideoEmbed
} from "./Counselors.styles";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";
import FlipCard from "../../atoms/FlipCard";

export default function Counselors() {
	return (
		<>
			<NavBar />
			<TopLanding>
				<TopTextLanding>
					<h2>Strengthen the effectiveness of your college counseling.</h2>
					<a href={"https://calendly.com/nickgronda-croi"}>
						<ScheduleMeetingButton>Schedule 15-Minute Meeting</ScheduleMeetingButton>
					</a>
				</TopTextLanding>

				<VideoEmbed>
					<iframe
						src={"https://www.youtube.com/embed/GKycBov926A?si=gCkSyRJbhYLXuOkI"}
						allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
						allowFullScreen
						title="Embedded youtube"
					/>
				</VideoEmbed>
			</TopLanding>

			<BenefitSection>
				<h2>Benefits For Your Counseling Department</h2>
				<BenefitCardsSection>
					<BenefitCard>
						<img
							style={{ height: "125px", width: "125px", marginBottom: "0px" }}
							src="/images/Demand.png"
							alt="Demand"
						/>
						<a>Address the individual wants and needs of EVERY one of your students.</a>
					</BenefitCard>
					<BenefitCard>
						<img src="/images/Clock.png" alt="Clock" />
						<a>Save time by eliminating the need to gather information from different online sources.</a>
					</BenefitCard>
					<BenefitCard>
						<img src="/images/familyCroi.png" alt="family" />
						<a>Manage parent expectations through true-cost analysis and value-based calculations.</a>
					</BenefitCard>
				</BenefitCardsSection>
			</BenefitSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Students make the right decision with the&nbsp;<span style={{ color: "var(--croi-green)" }}>croi</span>
				&nbsp;5-step college process:
			</h2>
			<FiveStepSection>
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>1</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/book.png" alt="book" />
						</ImageBackground>
						<p>Learn</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Gain education on managing your finances, exploring financial aid options, and mastering the FAFSA/CSS
							forms. Empower yourself with knowledge about funding your education effectively.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>2</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/Vector.png" alt="Vector" />
						</ImageBackground>
						<p>Match</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Find your ideal colleges tailored to your needs. Croi matches you with schools based on financial aid
							opportunities, your chosen field of study, academic strengths, and more – ensuring the perfect fit for
							your future.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>3</FiveStepNumber>
						<ImageBackground>
							<img src="/images/status-up.svg" alt="statusUp" />
						</ImageBackground>
						<p>Analyze</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Find out the real cost of your education and see what you'll get in return. Our tool calculates the total
							expenses, predicts your chances of landing a job after graduation, and estimates the salaries you can
							expect.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>4</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/school.png" alt="school" />
						</ImageBackground>
						<p>Decide</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Conduct a personalized cost-benefit analysis of your college choices, aligning them with your individual
							preferences and priorities. Make informed decisions that suit your wants and needs.
						</p>
					</FiveStepCard>
				)}
				{FlipCard(
					<FiveStepCard>
						<FiveStepNumber>5</FiveStepNumber>
						<ImageBackground>
							<img style={{ marginTop: "10px" }} src="/images/account_balance.png" alt="accBalance" />
						</ImageBackground>
						<p>Finance</p>
					</FiveStepCard>,
					<FiveStepCard>
						<p>
							Determine how to fund your college expenses, calculate your future student loan payments, find the right
							private lenders, and discover personalized scholarship recommendations tailored just for you.
						</p>
					</FiveStepCard>
				)}
			</FiveStepSection>

			<h2 style={{ textAlign: "center", fontWeight: "900", fontSize: "40px", marginTop: "80px" }}>
				Here's what students are saying about&nbsp;<span style={{ color: "var(--croi-green)" }}>croi...</span>
			</h2>

			<StatSection>
				<StatCard>
					<h1>92%</h1>
					<p>Found that Croi helped alleviate their anxiety regarding their college decision.</p>
				</StatCard>
				<StatCard>
					<h1>73%</h1>
					<p>Found Croi helpful in choosing the right college based on their wants and needs.</p>
				</StatCard>
			</StatSection>

			<CreateAccountSection>
				<h1>Unlock your student’s potential with data-driven guidance</h1>
				<a href={"https://calendly.com/nickgronda-croi"}>
					<CreateAccountButton>Schedule 15-Minute Meeting</CreateAccountButton>
				</a>
			</CreateAccountSection>
			<Footer />
		</>
	);
}
