import styled from "styled-components";
import { device } from "../../util/device";

export const FinancialWindow = styled.div`
	@media only screen and ${device.mobileM} {
		width: 80%;
		background-color: white;
		position: absolute;
		z-index: 100;
		left: 10%;
		border: var(--croi-green) 10px solid;
		border-radius: 10px;

		h1 {
			text-align: center;
			font-weight: bold;
			font-size: 35px;
			margin-bottom: 0;
		}

		h2 {
			color: var(--croi-green);
			font-weight: bold;
			font-size: 32px;
			text-align: center;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			border-bottom: 10px solid var(--croi-green);
			margin-bottom: 25px;
		}

		h3 {
			text-align: center;
			font-weight: bold;
			font-size: 24px;
		}

		p {
			margin-right: auto;
			margin-left: auto;
			width: 80%;
			display: flex;
			justify-content: space-evenly;
			font-size: 20px;

			i {
				text-align: right;
				width: 40%;
			}
		}

		/* Chrome, Safari, Edge, Opera */

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */

		input[type="number"] {
			-moz-appearance: textfield;
		}

		input {
			border: 1px black solid;
			padding-left: 10px;
			border-radius: 5px;
			width: 40%;
		}

		button {
			display: block;
			margin: 40px auto;
			height: 50px;
			width: 240px;
			background-color: var(--croi-green);
			color: white;
			font-size: 24px;
			font-weight: bold;
			border-radius: 10px;
		}

		select {
			width: 50%;
			font-size: 15px;
			padding-left: 5px;
			border-radius: 5px;
			background-color: white;
			border: 1px black solid;
			height: 30px;
		}

		.select-container {
			width: 80%;
			font-size: 15px;
			padding-left: 5px;
			border-radius: 5px;
			background-color: white;
			height: 30px;
		}
	}

	@media only screen and ${device.tablet} {
		width: 50vw;
		background-color: white;
		position: absolute;
		z-index: 100;
		left: 25%;
		border: var(--croi-green) 10px solid;
		border-radius: 10px;

		h1 {
			text-align: center;
			font-weight: bold;
			font-size: 35px;
			margin-bottom: 0;
		}

		h2 {
			color: var(--croi-green);
			font-weight: bold;
			font-size: 32px;
			text-align: center;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			border-bottom: 10px solid var(--croi-green);
			margin-bottom: 25px;
		}

		h3 {
			text-align: center;
			font-weight: bold;
			font-size: 24px;
		}

		p {
			margin-right: auto;
			margin-left: auto;
			width: 80%;
			display: flex;
			justify-content: space-evenly;
			font-size: 20px;

			i {
				text-align: right;
				width: 40%;
			}
		}

		/* Chrome, Safari, Edge, Opera */

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */

		input[type="number"] {
			-moz-appearance: textfield;
		}

		input {
			border: 1px black solid;
			padding-left: 10px;
			border-radius: 5px;
			width: 40%;
		}

		button {
			display: block;
			margin: 30px auto;
			height: 50px;
			width: 300px;
			background-color: var(--croi-green);
			color: white;
			font-size: 24px;
			font-weight: bold;
			border-radius: 10px;
		}

		select {
			width: 50%;
			font-size: 15px;
			padding-left: 5px;
			border-radius: 5px;
			background-color: white;
			border: 1px black solid;
			height: 30px;
		}

		.select-container {
			width: 50%;
			font-size: 15px;
			padding-left: 5px;
			border-radius: 5px;
			background-color: white;
			height: 30px;
		}
	}
`;

export const FinancialBackdrop = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: lightgrey;
	position: absolute;
	z-index: 99;
	left: 0;
	top: 0;
	opacity: 50%;
`;

export const TitleWButton = styled.div`
	display: flex;
	margin-top: 5px;

	h1 {
		@media only screen and ${device.mobileM} {
			width: 80%;
		}

		@media only screen and ${device.tablet} {
			width: 100%;
		}
	}

	height: auto;

	button {
		position: absolute;
		right: 5px;
		margin-top: 2px;
		height: 40px;
		width: 40px;
		background-color: var(--croi-green);
		color: white;
		font-size: 15px;
		font-weight: bold;
		border-radius: 10px;
	}
`;
