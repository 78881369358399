import styled from "styled-components";
import { device } from "../../util/device";

export const CardSection = styled.div`
	h2 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 20px;
		flex-wrap: wrap;
		width: 100vw;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 20px;
		flex-wrap: wrap;
		width: 82vw;
	}
`;

export const LearnCard = styled.div`
	p {
		font-style: italic;
	}

	@media only screen and ${device.mobileM} {
		border-radius: 10px;
		background: #f2f2f2;
		width: 80%;
		text-align: center;
		align-items: center;
		margin-left: 30px;
		padding-top: 15px;
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;

		h2 {
			height: 40px;
			margin-top: 10px;
		}
	}

	@media only screen and ${device.tablet} {
		border-radius: 10px;
		background: #f2f2f2;
		width: 350px;
		text-align: center;
		align-items: center;
		height: 450px;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 15px;
		display: flex;
		flex-direction: column;

		h2 {
			height: 40px;
			margin-top: 10px;
		}
	}
`;

export const LiteracyButton = styled.button`
	height: 40px;
	width: 80%;
	background: var(--croi-green);
	color: white;
	border-radius: 8px;
	margin-bottom: 10px;
	font-weight: bold;
`;

export const AidButton = styled.button`
	@media only screen and ${device.mobileM} {
		background: var(--croi-green);
		color: white;
		border-radius: 8px;
		height: 40px;
		width: 200px;
		margin-bottom: 10px;
	}

	@media only screen and ${device.tablet} {
		height: 40px;
		width: 275px;
		background: var(--croi-green);
		color: white;
		border-radius: 8px;
	}
`;

export const ProgressBar = styled.div`
	@media only screen and ${device.mobileM} {
		height: 20px;
		width: 80%;
		background-color: #f2f2f2;
		border-radius: 50px;
		margin: 20px 0;
		border: var(--croi-green) solid 2px;
	}

	@media only screen and ${device.tablet} {
		height: 20px;
		width: 80%;
		background-color: #f2f2f2;
		border-radius: 50px;
		margin: 20px 0;
		border: var(--croi-green) solid 2px;
	}
`;

export const Filler = styled.div`
	@media only screen and ${device.mobileM} {
		height: 100%;
		width: 0;
		background-color: var(--croi-green);
		border-radius: inherit;
		text-align: center;
		transition: width 0.2s ease-in;
	}

	@media only screen and ${device.tablet} {
		height: 100%;
		width: 0;
		background-color: var(--croi-green);
		border-radius: inherit;
		text-align: center;
		transition: width 0.2s ease-in;
	}
`;

export const LearnNavImage = styled.div<ImageProps>`
	@media only screen and ${device.mobileM} {
		background: transparent url(${(props) => props.imageUrl}) scroll center;
		background-size: cover;
		height: 150px;
		width: 150px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20%;
		margin-bottom: 7px;
		margin-top: 5px;
		background-size: contain;
	}

	@media only screen and ${device.tablet} {
		background: transparent url(${(props) => props.imageUrl}) scroll center;
		background-size: cover;
		height: 150px;
		width: 150px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20%;
		margin-bottom: 7px;
		margin-top: 5px;
		background-size: contain;
	}
`;

export const SmallLearnNavImage = styled.div<ImageProps>`
	height: 100px;
	width: 100px;
	border-radius: 20%;
	margin: 15px auto 7px;
	background: transparent url(${(props) => props.imageUrl}) scroll center no-repeat;
	background-size: contain;
`;

interface ImageProps {
	imageUrl: string;
}

export const BannerSection = styled.div`
	@media only screen and ${device.mobileM} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 20px;
		flex-wrap: wrap;
		width: 97vw;
	}

	@media only screen and ${device.tablet} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 20px;
		margin-top: 20px;
		flex-wrap: wrap;
		width: 82vw;
	}
`;

export const LearnBanner = styled.div`
	@media only screen and ${device.mobileM} {
		width: 80%;
		background: #f2f2f2;
		margin-top: 30px;
		margin-bottom: 20px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	@media only screen and ${device.tablet} {
		width: 740px;
		height: 150px;
		background: #f2f2f2;
		margin-top: 30px;
		margin-bottom: 20px;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
`;

export const ImageSection = styled.div``;

export const TitleSection = styled.div`
	h2 {
		font-weight: bold;
	}

	@media only screen and ${device.mobileM} {
		margin-top: 10px;
	}

	@media only screen and ${device.tablet} {
		margin-top: 10px;
	}
`;

export const CroiHowToButton = styled.button`
	height: 40px;
	width: 200px;
	background: var(--croi-green);
	color: white;
	border-radius: 8px;
	border: var(--croi-green) solid 2px;
	margin-bottom: 15px;
	font-weight: bold;
`;
