export const ScholarshipFosList = [
	"Business and Management",
	"STEM",
	"Health and Medical Sciences",
	"Humanities and Social Sciences",
	"Creative Arts and Design",
	"Law and Public Services",
	"Environmental and Natural Sciences",
	"Education and Teaching",
	"Trades and Technical Professions"
];

// export const ScholarshipFosList = [
// 	"Academic Advising",
// 	"Accounting",
// 	"Advertising/Public Relations",
// 	"African Studies",
// 	"Agribusiness",
// 	"Agriculture",
// 	"American Studies",
// 	"Animal/Veterinary Sciences",
// 	"Anthropology",
// 	"Applied Sciences",
// 	"Archaeology",
// 	"Architecture",
// 	"Art History",
// 	"Arts",
// 	"Asian Studies",
// 	"Audiology",
// 	"Aviation/Aerospace",
// 	"Behavioral Science",
// 	"Biology",
// 	"Business/Consumer Services",
// 	"Chemical Engineering",
// 	"Child and Family Studies",
// 	"Civil Engineering",
// 	"Classics",
// 	"Communications",
// 	"Computer Science/Data Processing",
// 	"Construction Engineering/Management",
// 	"Cosmetology",
// 	"Criminal Justice/Criminology",
// 	"Culinary Arts",
// 	"Dental Health/Services",
// 	"Drafting",
// 	"Earth Science",
// 	"Economics",
// 	"Education",
// 	"Electrical Engineering/Electronics",
// 	"Energy and Power Engineering",
// 	"Engineering-Related Technologies",
// 	"Engineering/Technology",
// 	"Entomology",
// 	"Environmental Health",
// 	"Environmental Science",
// 	"Fashion Design",
// 	"Filmmaking/Video",
// 	"Finance",
// 	"Fire Sciences",
// 	"Food Science/Hospitality",
// 	"Food Science/Nutrition",
// 	"Foreign Language",
// 	"Funeral Services/Mortuary Science",
// 	"Geography",
// 	"Graphics/Graphic Arts/Printing",
// 	"Health Administration",
// 	"Health Information Management/Technology",
// 	"Health and Medical Sciences",
// 	"Heating, Air-Conditioning, and Refrigeration Mechanics",
// 	"History",
// 	"Horticulture/Floriculture",
// 	"Hospitality Management",
// 	"Human Resources",
// 	"Humanities",
// 	"Hydrology",
// 	"Industrial Design",
// 	"Insurance and Actuarial Science",
// 	"Interior Design",
// 	"International Studies",
// 	"Journalism",
// 	"Landscape Architecture",
// 	"Law Enforcement/Police Administration",
// 	"Law/Legal Services",
// 	"Library and Information Sciences",
// 	"Literature/English/Writing",
// 	"Marine Biology",
// 	"Marine/Ocean Engineering",
// 	"Marketing",
// 	"Materials Science, Engineering, and Metallurgy",
// 	"Mathematics",
// 	"Mechanical Engineering",
// 	"Meteorology/Atmospheric Science",
// 	"Military and Defense Studies",
// 	"Museum Studies",
// 	"Music",
// 	"Natural Resources",
// 	"Natural Sciences",
// 	"Nursing",
// 	"Occupational Safety and Health",
// 	"Oceanography",
// 	"Optometry",
// 	"Osteopathy",
// 	"Paper and Pulp Engineering",
// 	"Peace and Conflict Studies",
// 	"Performing Arts",
// 	"Pharmacy",
// 	"Philosophy",
// 	"Photojournalism/Photography",
// 	"Physical Science",
// 	"Political Science",
// 	"Psychology",
// 	"Public Health",
// 	"Public Policy and Administration",
// 	"Radiology",
// 	"Real Estate",
// 	"Recreation, Parks, Leisure Studies",
// 	"Religion/Theology",
// 	"Science, Technology, and Society",
// 	"Social Services",
// 	"Special Education",
// 	"Sports-Related/Exercise Science",
// 	"Statistics",
// 	"Surveying, Surveying Technology, Cartography, or Geographic Information Science",
// 	"TV/Radio Broadcasting",
// 	"Therapy/Rehabilitation",
// 	"Trade/Technical Specialties",
// 	"Transportation",
// 	"Travel/Tourism",
// 	"Urban and Regional Planning",
// 	"Women's Studies"
// ];
