import Footer from "../../components/Footer/Footer";
import React from "react";
// import { row, pricingSection } from "./Pricing.styles";
import {
	ContactUsButton,
	DemoBox,
	PricingBox,
	PricingBoxLarge,
	PricingCard,
	PricingRow,
	ScheduleDemoButton,
	SmallBanner
} from "./Pricing.styles";
import NavBar from "../../components/Navbar/Navbar";

export default function Pricing() {
	return (
		<>
			<NavBar />
			<PricingBox>
				<h1>Pricing</h1>
			</PricingBox>
			<PricingRow>
				<PricingCard>
					<PricingBoxLarge style={{ marginTop: "35px" }}>
						<h4>1 Month</h4>
						<h3>$35 / Month</h3>
						<br></br>
						<p>Billed Monthly.</p>
						<p>Cancel Anytime.</p>
						<br></br>
						<a href={"/signup"}>
							<ContactUsButton>Sign Up</ContactUsButton>
						</a>
					</PricingBoxLarge>
				</PricingCard>
				<PricingCard>
					<SmallBanner>Best Value</SmallBanner>
					<PricingBoxLarge>
						<h4>1 Year</h4>
						<h3>$19 / Month</h3>
						<br></br>
						<p>Lowest Monthly Rate.</p>
						<p>Billed Annually.</p>
						<br></br>
						<a href={"/signup"}>
							<ContactUsButton>Sign Up</ContactUsButton>
						</a>
					</PricingBoxLarge>
				</PricingCard>
			</PricingRow>
			<DemoBox>
				<h2>College Counselor, non-profit, or IEC?</h2>
				<a href={"https://calendly.com/nickgronda-croi"}>
					<ScheduleDemoButton>Schedule Demo</ScheduleDemoButton>
				</a>
			</DemoBox>
			<Footer />
		</>
	);
}
