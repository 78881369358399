import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import { Slide } from "@material-ui/core";
import styled from "styled-components";

export function Snack(props: { msg: string; setMsg: any }) {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.msg.length > 0) {
			setOpen(true);
		}
	}, [props.msg]);

	return (
		<SnackStyle>
			<Snackbar
				onClose={() => {
					setOpen(false);
					props.setMsg("");
				}}
				open={open}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				autoHideDuration={3000}
				message={props.msg}
				TransitionComponent={Slide}
			/>
		</SnackStyle>
	);
}

const SnackStyle = styled.div`
	.MuiSnackbarContent-root {
		max-width: 90vw;
		min-width: 600px;
		background-color: var(--croi-green);
		font-weight: bold;
	}
`;
