import React, { useState } from "react";
import {
	AddBulk,
	AddStudent,
	EditButton,
	ImportStudentButtons,
	ModalStyle,
	ResetButton,
	SortButton,
	StudentTable
} from "./AdminPage.styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addUser, addUsers, deleteUser, fetchUsers } from "../../services/User.services";
import { User } from "../../types/User.types";
import { Modal } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import { Snack } from "../../atoms/SnackBarAlert";
import { queryClient } from "../../services/QueryClient.services";

type SingleUser = {
	adminID: string;
	firstName: string;
	lastName: string;
	email: string;
	password?: string;
};

const singleUser: SingleUser = {
	adminID: "",
	firstName: "",
	lastName: "",
	email: ""
};

export function AdminPage(props: { admin: User; setUid: any; setTool: any }) {
	const [data, setData] = useState(singleUser);
	const [showAdd, setShowAdd] = useState(false);
	const [showBulk, setShowBulk] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [deleteID, setDelete] = useState("");
	const [csvFile, setCsvFile] = useState<File | null>();
	const [snackMsg, setMsg] = useState("");
	const [sortBy, setSort] = useState("Last");

	const getUsers = useQuery<User[], Error>({
		queryKey: ["usersList"],
		queryFn: () => fetchUsers(),
		refetchInterval: 15000
	});

	const createUser = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => addUser(body),
		onSuccess: () => {
			setMsg("Created User Successfully!");
			queryClient.invalidateQueries(["usersList"]);
		}
	});

	const createUsers = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => addUsers(body),
		onSuccess: () => {
			setMsg("Success! It may take a minute or more for all users to appear.");
			queryClient.invalidateQueries(["usersList"]);
		}
	});

	const delUser = useMutation({
		mutationKey: ["usersList"],
		mutationFn: (body: any) => deleteUser(body),
		onSuccess: () => {
			setMsg("User Successfully Deleted!");
			return queryClient.invalidateQueries(["usersList"]);
		}
	});

	const sorter = (a: User, b: User) => {
		if (sortBy === "Last") {
			return a.lastName > b.lastName ? 1 : -1;
		}
		if (sortBy === "First") {
			return a.firstName > b.firstName ? 1 : -1;
		}
		if (sortBy === "Email") {
			return a.email > b.email ? 1 : -1;
		}
		return 0;
	};

	const deleter = (id: string) => {
		const body = {
			id: id
		};
		setShowDelete(false);
		delUser.mutate(body);
	};

	const createSingleUser = () => {
		setShowAdd(false);

		const body = {
			adminID: props.admin.id,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			password: data.password && data.password.length > 0 ? data.password : null
		};

		createUser.mutate(body);
	};

	const createBulkUser = async () => {
		createUsers.mutate(csvFile);
		setShowBulk(false);
	};

	const addFunc = () => {
		setData(singleUser);
		setShowAdd(true);
	};

	const modalView = () => {
		return (
			<Modal show={showAdd} onHide={() => setShowAdd(!showAdd)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>Add User</Modal.Title>
					<div>
						<p>firstName: </p>
						<input
							type="text"
							placeholder={"firstName"}
							value={data.firstName}
							onChange={(e) => setData({ ...data, firstName: e.target.value })}
						/>
					</div>

					<div>
						<p>lastName: </p>
						<input
							type="text"
							placeholder={"lastName"}
							value={data.lastName}
							onChange={(e) => setData({ ...data, lastName: e.target.value })}
						/>
					</div>

					<div>
						<p>Email: </p>
						<input
							type="text"
							placeholder={"email"}
							value={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
					</div>

					<div>
						<p>Password: </p>
						<input
							type="text"
							placeholder={"optional password"}
							value={data.password}
							onChange={(e) => setData({ ...data, password: e.target.value })}
						/>
					</div>

					<ResetButton onClick={() => createSingleUser()} style={{ margin: "10px" }}>
						Done
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	const modalViewFile = () => {
		return (
			<Modal show={showBulk} onHide={() => setShowBulk(!showBulk)}>
				<ModalStyle>
					<Modal.Title style={{ margin: "10px" }}>Bulk Upload</Modal.Title>
					<div style={{ width: "95%", textAlign: "center" }}>
						You must add a file with the columns named (without quotations) "firstName", "lastName", & "email".
					</div>
					<br />
					<div style={{ width: "95%", textAlign: "center" }}>
						You may also add an optional column "password" to manually set the password.
					</div>
					<br />
					<div style={{ width: "95%", textAlign: "center" }}>
						After successfully uploading the csv, it may take a few minutes for your students to appear
					</div>
					<br />
					<div>
						<p>CSV File:</p>
						<input type="file" accept=".csv" onChange={(e) => setCsvFile(e.target.files ? e.target.files[0] : null)} />
					</div>
					<div>{csvFile && <p>Selected CSV File: {csvFile.name}</p>}</div>
					<ResetButton onClick={() => createBulkUser()} style={{ margin: "10px" }}>
						Bulk Upload
					</ResetButton>
				</ModalStyle>
			</Modal>
		);
	};

	const modalDeleteView = () => {
		return (
			<Modal show={showDelete} onHide={() => setShowDelete(!showDelete)}>
				<Modal.Title style={{ textAlign: "center" }}>ARE YOU SURE?</Modal.Title>
				<div style={{ display: "flex", justifyContent: "space-evenly", height: "100px" }}>
					<EditButton onClick={() => deleter(deleteID)} style={{ width: "40%", backgroundColor: "#d6533a" }}>
						Yes, delete user!
					</EditButton>
					<EditButton
						onClick={() => setShowDelete(false)}
						style={{ width: "40%", backgroundColor: "var(--croi-green)" }}>
						No, take me back!
					</EditButton>
				</div>
			</Modal>
		);
	};

	const renderContent = () => {
		if (getUsers.isLoading || !getUsers.data) {
			return (
				<>
					<div>Retrieving Users...</div>
					<ScaleLoader color={"black"} loading={getUsers.isLoading} />
				</>
			);
		} else {
			return (
				<StudentTable>
					<thead>
						<tr>
							<td>First Name</td>
							<td>Last Name</td>
							<td>Student ID</td>
							<td>Email</td>
							<td>Actions</td>
						</tr>
					</thead>
					<tbody>
						{getUsers.data
							.sort((a, b) => sorter(a, b))
							.map((student) => (
								<tr id={student.id}>
									<td>{student.firstName}</td>
									<td>{student.lastName}</td>
									<td>{student.id}</td>
									<td>{student.email}</td>
									<td>
										<EditButton
											style={{ backgroundColor: "#d6533a" }}
											onClick={() => {
												setDelete(student.id);
												setShowDelete(true);
											}}>
											Delete
										</EditButton>
										<EditButton
											style={{ backgroundColor: "black" }}
											onClick={() => {
												props.setUid(student.id);
												props.setTool("Admin-Student");
											}}>
											View
										</EditButton>
									</td>
								</tr>
							))}
					</tbody>
				</StudentTable>
			);
		}
	};

	return (
		<>
			<Snack msg={snackMsg} setMsg={setMsg} />
			{modalView()}
			{modalViewFile()}
			{modalDeleteView()}
			<ImportStudentButtons>
				<div style={{ display: "flex" }}>
					<p>Sort By:</p>
					<SortButton
						onClick={() => {
							setSort("First");
						}}
						disabled={getUsers.isFetching}
						className={sortBy === "First" ? "selected" : ""}>
						First Name
					</SortButton>
					<SortButton
						onClick={() => setSort("Last")}
						disabled={getUsers.isFetching}
						className={sortBy === "Last" ? "selected" : ""}>
						Last Name
					</SortButton>
					<SortButton
						onClick={() => setSort("Email")}
						disabled={getUsers.isFetching}
						className={sortBy === "Email" ? "selected" : ""}>
						Email
					</SortButton>
				</div>
				<div style={{ display: "flex" }}>
					<p>Add Users:</p>
					<AddStudent onClick={() => addFunc()} disabled={getUsers.isFetching}>
						Single
					</AddStudent>
					<AddBulk onClick={() => setShowBulk(true)} disabled={getUsers.isFetching}>
						Bulk
					</AddBulk>
				</div>
			</ImportStudentButtons>
			{renderContent()}
		</>
	);
}
