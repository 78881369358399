import styled from "styled-components";

export const DetailSection = styled.div`
	display: flex;
`;

export const DetailColumn = styled.div`
	//width: 4%;
	height: auto;
	flex-direction: column;
`;

export const ActionButton = styled.button`
	background-color: white;
	border: var(--croi-green) 2px solid;
	color: black;
	font-weight: bold;
	padding: 2px;
	width: 50%;
	margin-top: 4px;
	border-radius: 10px;
`;

export const ViewButton = styled.button`
	background-color: transparent;
	color: var(--croi-green);
	font-size: 20px;
`;
