import React, { ReactNode, useState } from "react";
import {
	BusinessColumn,
	ContactBox,
	ContactColumn,
	CroiContactColumn,
	HeadquarterColumn,
	InfoColumn,
	LocationColumn,
	ModalClose,
	ModalContent,
	ModalOverlay,
	PicWordRow,
	SubmitButton,
	TitleSection
} from "./Contact.styles";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/Navbar/Navbar";
import emailjs from "emailjs-com";

interface ModalProps {
	isVisible: boolean;
	onClose: () => void;
	children: ReactNode;
}

export default function Contact() {
	const [emailResult, setEmailResult] = useState("");
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		companySchool: "",
		message: ""
	});
	const [isModalVisible, setIsModalVisible] = useState(false);

	const openModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	const handleSubmit = () => {
		emailjs
			.send(
				"service_p2dwpts", // Replace with your EmailJS service ID
				"template_ruiu01m", // Replace with your EmailJS template ID
				formData,
				"T_EWLhs1ZA8A49cuO" // Replace with your EmailJS user ID
			)
			.then(
				(result) => {
					setEmailResult("Email Sent");
					console.log(result.text);
					openModal();
				},
				(error) => {
					setEmailResult("Email Failed to Send");
					console.log(error.text);
					openModal();
				}
			);

		setFormData({ ...formData, ["fullName"]: "" });
		setFormData({ ...formData, ["email"]: "" });
		setFormData({ ...formData, ["companySchool"]: "" });
		setFormData({ ...formData, ["message"]: "" });
	};

	const Modal: React.FC<ModalProps> = ({ isVisible, onClose, children }) => {
		if (!isVisible) return null;

		return (
			<ModalOverlay>
				<ModalContent>
					<ModalClose onClick={onClose}>&times;</ModalClose>
					{children}
				</ModalContent>
			</ModalOverlay>
		);
	};

	return (
		<>
			<NavBar />
			<TitleSection>
				<h1>Contact Us</h1>
				<h3>Please enter your contact details and message.</h3>
				<h3>We will get back to you as soon as possible!</h3>
			</TitleSection>
			<ContactBox>
				<InfoColumn>
					<h2>Headquarters</h2>
					<HeadquarterColumn>
						<LocationColumn>
							<PicWordRow>
								<img src="/images/Location.png" alt="Location" />
								<a>CROI, Inc.</a>
							</PicWordRow>
							<p>50 Prescott St. Worcester, MA 01605</p>
						</LocationColumn>
						<CroiContactColumn>
							<PicWordRow>
								<img src="/images/Email.png" alt="Email" />
								<a>help@croi.io</a>
							</PicWordRow>
						</CroiContactColumn>
					</HeadquarterColumn>
					<h2 style={{ paddingTop: "40px" }}>Business Hours</h2>
					<BusinessColumn>
						<PicWordRow>
							<img src="/images/TimeClock.png" alt="TimeClock" />
							<a>Weekdays: 8am - 6pm EST</a>
						</PicWordRow>
					</BusinessColumn>
				</InfoColumn>
				<ContactColumn>
					<h4>Full Name*</h4>
					<input
						type="text"
						onChange={(e) => {
							setFormData({ ...formData, ["fullName"]: e.target.value });
						}}
						required={true}
					/>
					<h4>Email Address*</h4>
					<input
						type="text"
						onChange={(e) => {
							setFormData({ ...formData, ["email"]: e.target.value });
						}}
						required={true}
					/>
					<h4>School/Company*</h4>
					<input
						type="text"
						onChange={(e) => {
							setFormData({ ...formData, ["companySchool"]: e.target.value });
						}}
						required={true}
					/>
					<h4>Phone Number</h4>
					<input
						type="text"
						onChange={(e) => {
							setFormData({ ...formData, ["message"]: e.target.value });
						}}
					/>
					<SubmitButton
						disabled={formData.fullName == "" || formData.email == "" || formData.companySchool == ""}
						onClick={handleSubmit}>
						Submit
					</SubmitButton>
					<div style={{ textAlign: "center", width: "90%", paddingTop: "5px", marginBottom: "20px" }}>
						* Required Field
					</div>
					<div>
						<Modal isVisible={isModalVisible} onClose={closeModal}>
							<h2>{emailResult}</h2>
						</Modal>
					</div>
				</ContactColumn>
			</ContactBox>
			<Footer />
		</>
	);
}

{
	/* <a href={"https://calendly.com/nickgronda-croi"}>Contact Us</a> */
}
